import { z } from 'zod'
import { Maybe } from '../../../../universal'
import { React, _ } from '../../../lib'
import { UploadFileContainer } from '../../component-upload'
import { FormField, FormFieldJSX } from './types'

/** File upload - single */
export const FormTypeFileSingle = (
	settings: FormField<Maybe<string>> & {
		height: number
	},
): FormFieldJSX<Maybe<string>> =>
	({
		...settings,
		valueDefaults: {
			def: () => null,
			validators: [],
		},
		height: _.max([36, (settings.height ?? 0) + 8]) ?? 36,
		typeMap: {
			schemaRaw: z.string().nullable(),
			schemaPublic: z.string().nullable(),
			toPublic: x => x,
			toInternal: x => x,
		},
		llmInfo: null,
		jsx: props => (
			<UploadFileFormFieldSingle
				value={props.value}
				onUpdate={props.onUpdate}
				className={props.className ?? ''}
				height={settings.height}
			/>
		),
	}) as FormFieldJSX<Maybe<string>, Maybe<string>>

/** File upload - multiple */
export const FormTypeFileMultiple = (
	settings: FormField<string[]> & {
		height: number
	},
): FormFieldJSX<string[]> =>
	({
		...settings,
		valueDefaults: {
			def: () => [],
			validators: [],
		},
		height: _.max([36, (settings.height ?? 0) + 8]) ?? 36,
		typeMap: {
			schemaRaw: z.string().array(),
			schemaPublic: z.string().array(),
			toPublic: x => x,
			toInternal: x => x,
		},
		llmInfo: null,
		jsx: props => (
			<UploadFileFormFieldMultiple
				value={props.value}
				onUpdate={props.onUpdate}
				className={props.className ?? ''}
				height={settings.height}
			/>
		),
	}) as FormFieldJSX<string[], string[]>

const UploadFileFormFieldSingle = (props: {
	className?: string
	height?: number
	value: Maybe<string>
	onUpdate: (v: Maybe<string>) => void
}) => {
	const [, setValue] = React.useState<Maybe<string>>(props.value)
	return (
		<UploadFileContainer
			cl={props.className}
			height={props.height}
			onUpload={(files, callback) => {
				const checksum = _.first(files.map(x => x.checksum))
				setValue(checksum)
				props.onUpdate(checksum)
				callback(true)
			}}
		/>
	)
}

const UploadFileFormFieldMultiple = (props: {
	className?: string
	height?: number
	value: string[]
	onUpdate: (v: string[]) => void
}) => {
	const init = React.useRef(false)

	// Track the value state - call `onUpdate` when it changes
	const [value, setValue] = React.useState<string[]>(props.value)
	React.useEffect(() => {
		if (init.current) {
			console.log('Updating', value)
			props.onUpdate(value)
		}
	}, [value])

	// Track whether a first render effect has happened
	// This is to ensure we don't immediately call `onUpdate` above
	React.useEffect(() => {
		init.current = true
	}, [])

	// Render
	return (
		<UploadFileContainer
			cl={props.className}
			height={props.height}
			onUpload={(files, callback) => {
				const checksums = files.map(x => x.checksum)
				setValue(s => [...s, ...checksums])
				callback(true)
			}}
		/>
	)
}
