import { BuildClass, Do, Maybe, fsmData, timer } from '../../universal'
import { $, React, ReactDOM, ReactDOMClient, _ } from '../lib'
import { J2rButton } from './component-buttons'
import { J2rComboBox } from './component-combobox'
import { validateTime } from './component-main'
import { J2rListComponent } from './component-react-list'
import { NewSystemFlyoutInstance, flyoutArgs, newSystemFlyout } from './flyouts'
import { Bindings, ConditionalObject } from './ui5'

declare let tippy: any

// Globals
let debug_no_key = false
export const toggleDebugNoKey = () => {
	debug_no_key = !debug_no_key
	return debug_no_key
}
window.reactComponents = []

/**
 * Input object for `j2r`
 * @deprecated Use JSX
 */
export type J2rObject<T extends React.ComponentClass = any> =
	| undefined
	| null
	| string
	| J2rObject[]
	| JSX.Element
	| (() => J2rObject<T>)
	| {
			tag?: string | T | React.FunctionComponent<any>
			key?: string | number
			text?: string | number
			cl?: string
			children?: J2rObject[]
			[key: string]: any
	  }

type J2rObjectConcrete<T extends React.ComponentClass> = {
	tag: string | T | React.FunctionComponent<any>
	key?: string | number
	text?: string | number
	cl?: string
	children?: J2rObject[]
	[key: string]: any
}

/**
 * Helper function to translate J2H-like syntax into non-JSX React syntax
 * @deprecated Use JSX instead
 */
export const j2r = <T extends React.ComponentClass>(
	objRaw: J2rObject<T>,
	isGridCell?: boolean,
): JSX.Element => {
	let objConcrete: J2rObjectConcrete<T>

	// If the object is already converted to a React element, return it as is
	// It is assume all children will also be pre-converted at this point
	if (isJSX(objRaw)) {
		return objRaw
	}

	// Return undefined to undefined
	if (objRaw === undefined || objRaw === null) {
		return undefined
	}

	// If a number was passed, this is not good - a problem
	if (typeof objRaw === 'number') {
		console.error(`Number passed: ${objRaw}`)
		objConcrete = {
			tag: 'span',
			text: 'ERROR! NUMBER GIVEN!',
		}
	}

	// If a function was passed, execute now and use the result as the object
	if (typeof objRaw === 'function') {
		return j2r(objRaw(), isGridCell)
	}

	// If this is a text node, wrap it in a span
	if (typeof objRaw === 'string') {
		return j2r(
			{
				tag: 'span',
				key: objRaw,
				text: objRaw,
			},
			isGridCell,
		)
	}

	// If it's an array, return a react fragment with all items contained
	if (objRaw.constructor === Array) {
		return <React.Fragment>{objRaw.map(x => j2r(x, isGridCell))}</React.Fragment>
	}

	// Normalise the tag - default to div
	objConcrete = !('tag' in objRaw)
		? { tag: 'div', ...objRaw }
		: { tag: objRaw.tag, ...objRaw }

	// Get the tag name - default to a div
	const is_sub_cmp = typeof objConcrete.tag !== 'string'

	// Check if this object is a `gridRow` - their children will automatically
	// have a `gridCell` class attached
	const has_gridRow = objConcrete.cl != null && objConcrete.cl.indexOf('gridRow') !== -1
	if (isGridCell) {
		objConcrete.cl = (objConcrete.cl ?? '').replace('gridCell', '')
		objConcrete.cl += ' gridCell'
		objConcrete.cl = objConcrete.cl.replace(/\s+/g, ' ')
		objConcrete.cl = objConcrete.cl.trim()
	}

	// Get the child node - either a text node or an array of child objects
	// Each child object needs to be parsed by J2R as well
	const children =
		objConcrete.text ??
		Do(() => {
			if (objConcrete.children != null) {
				let C = _.map(objConcrete.children, child => j2r(child, has_gridRow))
				C = C.filter(x => x !== undefined)
				return C
			}
			return null
		})

	// Banned fields in the attributes depends on if it's a sub-component
	const banned_fields = ['tag', 'children', 'text']

	// Remove the reserved attribute fields that were used above to get a flat
	// tree of the attribute/properties to go on the element
	// Update the `cl` property to `className`
	let attr: { [key: string]: any } = {}
	_.forEach(objConcrete, (v, k) => {
		if (k === 'cl') {
			attr.className = v
			if (is_sub_cmp) {
				attr.cl = v
			}
		} else if (!_.includes(banned_fields, k)) {
			attr[k] = v
		}
	})

	// Debug a missing key warning if the flag is set
	if (debug_no_key && attr.key === undefined) {
		console.log(objRaw, objConcrete)
	}

	// Convert the attributes to null if nothing found
	if (_.size(attr) === 0) {
		attr = null
	}

	// Return the element using React's non-JSX API
	if (!children) {
		return React.createElement(objConcrete.tag, attr)
	}
	return React.createElement(objConcrete.tag, attr, children)
}

/** Runtime type predicate */
export const isJSX = (obj: J2rObject): obj is JSX.Element => {
	if (_.isFunction(obj)) {
		return false
	}
	if (typeof obj == 'string') {
		return false
	}
	if (obj == null) {
		return false
	}
	return obj['$$typeof'] != null
}

type customReact18Props<T> = {
	children: JSX.Element
	root: ReactDOMClient.Root
	onMount: (obj: T) => void
}
class CustomReact18Wrapper<T> extends React.Component<customReact18Props<T>> {
	ref: React.RefObject<T>
	constructor(props: customReact18Props<T>) {
		super(props)
		this.ref = React.createRef()
	}
	override componentDidMount() {
		this.props.onMount(this.ref.current)
	}
	override render() {
		return React.cloneElement(this.props.children, {
			ref: this.ref,
		})
	}
	unmount() {
		this.props.root.unmount()
	}
}

/** Renders a react component into a pane and registers to global index */
type reactEl = Maybe<Element | React.Component<any, any, any>>
export const reactRender = async <T extends reactEl>(
	container: ReactDOM.Container,
	cmpt: JSX.Element,
): Promise<T> =>
	new Promise(resolve => {
		const root = ReactDOMClient.createRoot(container)
		const component = (
			<CustomReact18Wrapper<T>
				root={root}
				onMount={x => {
					if (!x) {
						return null
					}
					window.reactComponents.push({
						root: root,
						component: x,
						container: container,
					})
					resolve(x)
				}}
			>
				{cmpt}
			</CustomReact18Wrapper>
		)
		root.render(component)
	})

/** Forces all known registered react components to update (usually after a DM change) */
export const reactForceAllUpdate = () => {
	window.lastForcedReactUpdate = _.now()
	window.reactComponents = _.filter(window.reactComponents, x => {
		try {
			;(x.component as any).forceUpdate()
			return true
		} catch (error) {
			return false
		}
	})
}

export const unmountReactOnElement = (el: Element) => {
	window.reactComponents = window.reactComponents.filter(x => {
		if (x.container === el) {
			x.root?.unmount()
			return false
		}
		return true
	})
}

/** Function to get the React component present at a DOM node */
/** @deprecated This uses a weird hack - prefer a different way (references?) */
export const getReactComponent = elOrQuery => {
	const el = Do(() => {
		if (typeof elOrQuery === 'string') {
			return document.querySelector(elOrQuery)
		}
		return elOrQuery
	})
	if (el == null) {
		return null
	}
	const key = Object.keys(el)?.find(
		x => x.startsWith('__reactFiber$') || x.startsWith('__reactContainer$'),
	) as any
	return el[key]?.return?.stateNode ?? el[key]?.stateNode ?? null
}

/**
 * Function to easily bind functions to the object all at once
 * @deprecated Use `Bindings` instead
 */
export const bindMulti = (obj, keys) => {
	// Normalise a string of values into an array (saves some space in definition)
	if (typeof keys === 'string') {
		keys = _.compact(_.map(keys.split(' '), x => x.trim()))
	}
	// Loop over each on the object and re-save a bound version
	keys.forEach(k => {
		if (obj[k] == null) {
			throw Error(`Cannot find method \`${k}\``)
		}
		obj[k] = obj[k].bind(obj)
	})
}

// Replaces all supplied methods on an object with methods bound to that object
export const bindMethods = <T extends Object>(obj: T, ...methods: Function[]) => {
	methods.forEach(func => (obj[func.name] = func.bind(obj)))
}

/**
 * Creates a label with a span and a component
 * Takes in the text label, the class, and function returning the component
 * @deprecated Use `FormRow`
 */
export const j2rLabel = (
	label: string,
	cl: string,
	title: string | (() => J2rObject),
	comp?: () => J2rObject,
): {
	tag: 'label'
	key: string
	cl: string
	children: J2rObject[]
	[others: string]: any
} => {
	// If only three params are given, assume title is not defined
	if (!_.isString(title)) {
		comp = title
		title = null
	}
	// Return the component
	return {
		tag: 'label',
		key: label || cl,
		cl: BuildClass({
			[cl]: true,
			'j2r-label': true,
		}),
		children: [
			{
				tag: 'span',
				key: 'label',
				cl: 'lbl',
				text: label,
				title: title ?? label,
			},
			_.assign(comp(), { key: 'comp' }),
		],
	}
}

/**
 * Creates a basic text node with a custom key - used for when the text might
 * have a duplicate or be empty and a key can't be generated automatically
 * @deprecated Use JSX
 */
export const j2rtxt = (key, text, tooltip?) => ({
	tag: 'span',
	key,
	text: String(text ?? ''),
	title: String(tooltip ?? text ?? ''),
})

/**
 * Creates a heading cell that changes object sorting keys when clicked
 * Shows arrows based on those same sorting keys' state
 * @deprecated Use `J2rListGrid` instead
 */
export const j2rHeadingCell = (reactEl, key, lbl?, sKey?, sKeyRev?, sKeyRevDef?) => {
	if (sKey == null) {
		sKey = 'sortingKey'
	}
	if (sKeyRev == null) {
		sKeyRev = 'sortingReverse'
	}
	return {
		tag: 'span',
		key,
		title: '',
		cl: BuildClass({
			'heading-cell-inner': true,
			sorted: key === reactEl.state[sKey],
			'sort-asc': key === reactEl.state[sKey] && !reactEl.state[sKeyRev],
			'sort-dsc': key === reactEl.state[sKey] && reactEl.state[sKeyRev],
		}),
		text: lbl ?? key,
		onClick: () =>
			reactEl.setState(s => ({
				[sKey]: key,
				[sKeyRev]: Do(() => {
					if (s[sKey] === key) {
						return !s[sKeyRev]
					}
					return sKeyRevDef ?? false
				}),
			})),
	}
}

/**
 * Same as `j2rHeadingCell` but function - does not mutate parent component state
 * @deprecated Use `J2rListGrid` instead
 */
export const j2rHeadingCellFunctional = (state, key, lbl, sKey?, sKeyRev?, onClick?) => {
	// Check if any of the arguments sent through are functions
	if (lbl instanceof Function) {
		onClick = lbl
		lbl = null
	}
	if (sKey instanceof Function) {
		onClick = sKey
		sKey = null
	}
	if (sKeyRev instanceof Function) {
		onClick = sKeyRev
		sKeyRev = null
	}

	if (sKey == null) {
		sKey = 'sortingKey'
	}
	if (sKeyRev == null) {
		sKeyRev = 'sortingReverse'
	}
	return {
		tag: 'span',
		key,
		cl: BuildClass({
			'heading-cell-inner': true,
			sorted: key === state[sKey],
			'sort-asc': key === state[sKey] && !state[sKeyRev],
			'sort-dsc': key === state[sKey] && state[sKeyRev],
		}),
		text: lbl ?? key,
		onClick: () =>
			onClick({
				[sKey]: key,
				[sKeyRev]: state[sKey] === key && !state[sKeyRev],
			}),
	}
}

/**
 * Same as `j2rHeadingCell`, but it allows an explicit tooltip to be given
 * @deprecated Use `J2rListGrid` instead
 */
export const j2rHeadingCellTooltip = (
	reactEl,
	key,
	lbl,
	title,
	sKey?,
	sKeyRev?,
	sKeyRevDef?,
) => {
	const el = j2rHeadingCell(reactEl, key, lbl, sKey, sKeyRev, sKeyRevDef)
	el.title = title
	return el
}

/**
 * Applies generic `value` and `onUpdate` properties to an object
 * Useful if it's a straight assign/save without any mutations
 * @deprecated Use `makeCmpt`
 */
export const j2rProps = (obj, prop, cmpt) => {
	// Get the actual component behind the function
	if (_.isFunction(cmpt)) {
		cmpt = cmpt()
	}

	// If not supplied, it's not a static custom onUpdate function
	if (cmpt.onUpdateStatic == null) {
		cmpt.onUpdateStatic = false
	}

	// Bind the event handler to the React component so its GUID is static
	const fnKey = `setStateProp_${prop}`
	if (obj[fnKey] == null) {
		obj[fnKey] = Do(() => {
			let fn = null
			if (cmpt.onUpdateStatic) {
				fn = cmpt.onUpdate
			}
			if (fn == null) {
				fn = Do(() => {
					const fnKeyInner =
						obj.updateState != null ? 'updateState' : 'setState'
					if (obj[fnKeyInner] == null) {
						console.trace('No update state method for', obj)
					}
					const fnInner = obj[fnKeyInner].bind(obj)
					if (cmpt.tag === J2rComboBox) {
						return v => fnInner({ [prop]: v.value })
					}
					return v => fnInner({ [prop]: v })
				})
			}
			return fn.bind(obj)
		})
	}

	// Return the component with the boilerplate props
	return _.assign(cmpt, {
		key: `cmpt-inner-${prop}`,
		value: obj.state[prop],
		onUpdate: Do(() => {
			if (cmpt.onUpdateStatic) {
				return obj[fnKey]
			}
			return cmpt.onUpdate ?? obj[fnKey]
		}),
	})
}

/**
 * A very high-level function for creating a generic form element
 * @deprecated Use `FormCmpt`
 */
export const j2rCmpt = (obj, prop, lbl, cmpt?) => {
	// If cmpt is not defined (i.e. only 3 args), it is either that the
	// component defaults to `J2rText`, or the label defaults to the property
	if (cmpt == null && lbl == null) {
		cmpt = { tag: J2rText }
		lbl = prop
	} else if (cmpt == null && typeof lbl === 'string') {
		cmpt = { tag: J2rText }
	} else if (cmpt == null) {
		cmpt = lbl
		lbl = prop
	}

	// Build the form element in a label with the propertied-component at the end
	const el = j2rLabel(lbl ?? prop, '', () => j2rProps(obj, prop, cmpt))

	// Ensure it has a key for React, and return
	el.key = el.key || `${prop}-${lbl}`
	return el
}

/** A high-level function for creating a fly-out with its content as a react component */
export const reactFlyout = (
	title: string | null,
	size: [number, number],
	cmpt: J2rObject,
	custom_params?: Partial<Omit<flyoutArgs, 'size' | 'callback'>>,
) => {
	const params = {
		size: size,
		callback: async (flyout: NewSystemFlyoutInstance) =>
			reactRender(
				flyout.Element,
				<div
					key="flyout-wrapper"
					className={BuildClass({
						'react-flyout-wrapper': true,
						'no-title': !title,
					})}
				>
					{ConditionalObject(
						Boolean(title),
						<div className="heading-outer">{title}</div>,
					)}
					<div className="flyout-body-inner">
						{React.cloneElement(j2r(cmpt), {
							key: 'flyout-content',
							flyout: flyout,
						})}
					</div>
				</div>,
			),
	}
	return newSystemFlyout({ ...custom_params, ...params })
}

/* Same function as above but handles the component with a wrapped function */
export const reactFlyoutFunc = (
	title: string,
	size: [number, number],
	cmpt: (flyout: NewSystemFlyoutInstance) => JSX.Element,
	custom_params?: Partial<Omit<flyoutArgs, 'size' | 'callback'>>,
) => {
	const params = {
		size: size,
		callback: async (flyout: NewSystemFlyoutInstance) =>
			reactRender(
				flyout.Element,
				<div
					key="flyout-wrapper"
					className={BuildClass({
						'react-flyout-wrapper': true,
						'no-title': !title,
					})}
				>
					{ConditionalObject(
						Boolean(title),
						<div className="heading-outer">{title}</div>,
					)}
					<div className="flyout-body-inner">{cmpt(flyout)}</div>
				</div>,
			),
	}
	return newSystemFlyout({ ...custom_params, ...params })
}

/**
 * ReactFlyoutNew uses the new flyout component
 * @deprecated Please use `reactFlyout` instead
 */
export var reactFlyoutNew = (
	title: string,
	size: [number, number],
	cmpt: J2rObject,
	custom_params?,
) => reactFlyout(title, size, cmpt, custom_params)

/** A loading spinner in a flyout that shows a message until a promise resolves */
export const j2rLoadingFlyout = ({
	cl,
	title,
	size,
	promise,
}: {
	cl?: string
	title?: string
	size?: [number, number]
	promise?: Promise<void>
}) => {
	// Open the flyout with the loading spinner inside
	if (size == null) {
		size = [280, 240]
	}
	if (title == null) {
		title = 'Loading...'
	}
	const flyout = reactFlyout(title, size, {
		tag: J2rLoadingSpinner,
		key: 'spinner',
		cl: BuildClass({
			[cl]: true,
			'j2r-loading-flyout': true,
		}),
		size: 'large',
	})

	// Close once the promise resolves
	;(promise ?? Promise.resolve()).then(() => {
		flyout.Close()
	})
}

/**
 * Function to generate a loading spinner / message pane
 * @deprecated Use `FormButtonSet` - it's weird not to have buttons above this anyway
 */
export const j2rResponseDiv = (is_loading, message_text) => ({
	cl: 'lblResponse cntr',
	key: 'loading-response',
	children: [
		is_loading
			? {
					tag: J2rLoadingSpinner,
					key: 'inner-spinner',
					size: 'small',
				}
			: message_text == null || typeof message_text === 'string'
				? {
						key: 'inner-text',
						text: message_text ?? '',
					}
				: message_text.constructor === Array
					? {
							key: 'inner-cmpt-items',
							children: message_text,
						}
					: _.assign({}, message_text, { key: 'inner-cmpt-raw' }),
	],
})

/**
 * Function to generate a button set with loading/message underneath
 * @deprecated Use `FormButtonSet`
 */
export const j2rButtonResponseSet = (is_loading, message_text, extra_args?, buttons?) => {
	// Unpack middle param
	if (buttons == null) {
		buttons = extra_args
		extra_args = {}
	}

	// Map the types basedon the name of the button
	const types = {}
	const submits = ['save', 'submit', 'go', 'login', 'sign in', 'add', 'verify', 'send']
	const standards = ['reset', 'cancel', 'undo', 'reorder']
	const deletes = ['delete', 'ignore']
	_.forEach(submits, x => {
		types[x] = 'submit'
	})
	_.forEach(standards, x => {
		types[x] = 'standard'
	})
	_.forEach(deletes, x => {
		types[x] = 'delete'
	})

	// Proxy-call the more advanced version of the function
	let count = -1
	return j2rButtonResponseSetAdvanced({
		loading: is_loading,
		message: message_text,
		buttons: _.map(buttons, (v, k) => ({
			label: k,
			disabled: false,
			onClick: v,
			type: Do(() => {
				if (extra_args.types != null) {
					count += 1
					return extra_args.types[count]
				}
				return types[k.toLowerCase()] || 'standard'
			}),
		})),
	})
}

/**
 * More customisable version of `j2rButtonResponseSet`
 * @deprecated Use `FormButtonSet`
 */
export var j2rButtonResponseSetAdvanced = obj => ({
	key: 'button-loading-response-wrapper',
	children: [
		// Buttons
		{
			cl: 'cntr buttons',
			key: 'button-set',
			children: _.map(obj.buttons, btn => ({
				tag: J2rButton,
				key: btn.label,
				label: btn.label,
				type: btn.type,
				disabled: btn.onClick && (obj.loading || btn.disabled),
				onClick: btn.onClick,
			})),
		},

		// Response div
		j2rResponseDiv(obj.loading, obj.message),
	],
})

/**
 * A loading spinner - small or large
 * @deprecated Use `LoadingSpinnerSmall` or `LoadingSpinnerLarge`
 * */
export class J2rLoadingSpinner extends React.PureComponent<{
	size: string
	cl?: string
}> {
	constructor(props) {
		super(props)
	}

	override render() {
		return j2r(() => {
			if (this.props.size === 'small') {
				return {
					tag: 'img',
					cl: BuildClass({
						[this.props.cl]: true,
						'loading-spinner': true,
						'loading-spinner-new': true,
					}),
					src: '/static/img/svg/ajax-small.svg',
					title: 'Loading...',
				}
			} else if (this.props.size === 'large') {
				return {
					key: 'loadingSpinnerLarge',
					cl: BuildClass({
						[this.props.cl]: true,
						loadingSpinnerLarge: true,
					}),
					children: [
						{ key: 'blue', cl: 'circle blue' },
						{ key: 'red', cl: 'circle red' },
						{ key: 'yellow', cl: 'circle yellow' },
						{ key: 'green', cl: 'circle green' },
					],
				}
			}
			throw new Error('Invalid size type for AJAX loading component')
		})
	}
}

/**
 * Basic text component
 * @deprecated Use ui5 `Textbox` or `Textarea`
 */
export class J2rText extends React.PureComponent<
	{
		value?: any
		onUpdate?: Function
		cl?: string
		id?: string
		title?: string
		type?: string
		placeholder?: string
		tabIndex?: number
		maxLength?: number
		disabled?: boolean
		readOnly?: boolean
		focusOnClick?: boolean
		onFocus?: Function
		onBlur?: Function
		fixer?: Function
		fixerChange?: Function // Only triggers when de-focusing
		multiLine?: boolean
		autoExpand?: boolean
		onKeyDown?: Function
		autoFocus?: boolean
		autoComplete?: string
		style?: any
	},
	any // TODO: fix state type
> {
	element: React.RefObject<HTMLInputElement | HTMLTextAreaElement>

	constructor(props) {
		super(props)
		this.element = React.createRef()
		this.state = {
			value: this.props.value,
			focused: false,
		}

		// Throw warning if autoExpand is true but multiLine is not
		if (this.props.autoExpand && !this.props.multiLine) {
			console.warn('In `J2rText`, `autoExpand` is true but `multiLine` is not?')
		}
	}

	override componentDidMount() {
		timer(() => {
			this.resizeToFitContent()
		})
	}

	override componentDidUpdate(prevProps) {
		// Reset the state when new external properties are given
		if (this.props.value !== prevProps.value) {
			this.setState({ value: this.props.value }, () => {
				this.resizeToFitContent()
			})
		}
	}

	resizeToFitContent() {
		// If not set to auto-expand, exit early
		if (!this.props.autoExpand) {
			return
		}

		// Get the text element - exit early if nothing found
		const el = this.element.current
		if (el == null) {
			return
		}

		// Get the computed styles for the border values
		const style = window.getComputedStyle(el)
		const btop = style.borderTopWidth ?? '0px'
		const bbot = style.borderBottomWidth ?? '0px'

		// Get the string height style - calculated CSS
		// Removing existing height and scrolling options gives an accurate result
		el.style.height = '0px'
		el.style.overflowY = 'hidden'
		let h = el.scrollHeight

		// Set it on the element adn remove temporary styles
		if (h < 28) {
			h = 28
		}
		const heightStyle = `calc(${h}px + ${btop} + ${bbot})`
		el.style.overflowY = null
		el.style.height = heightStyle
	}

	override render() {
		return j2r({
			tag: this.props.multiLine ? 'textarea' : 'input',
			id: this.props.id,
			// Dynamic class name calculation
			cl: BuildClass({
				[this.props.cl]: true,
				tb2: true,
				disabled: this.props.disabled,
			}),
			// Other properties - ref is used to apply JQuery logic post-rendering
			ref: this.element,
			type: this.props.type ?? undefined, // text by default
			disabled: this.props.disabled,
			placeholder: this.props.placeholder ?? '',
			tabIndex: this.props.tabIndex ?? undefined,
			autoFocus: this.props.autoFocus ?? undefined,
			maxLength: this.props.maxLength ?? undefined,
			readOnly: this.props.readOnly ?? undefined,
			value: this.state.value ?? '',
			title: this.props.title,
			autoComplete: this.props.autoComplete,
			onKeyDown: this.props.onKeyDown,
			style: this.props.style,
			// Handle focus state
			onFocus: e => {
				if (this.props.focusOnClick && !this.state.focused) {
					this.element.current?.select()
				}
				this.setState({ focused: true }, () => (this.props.onFocus ?? _.noop)(e))
			},
			onBlur: e => {
				this.setState(
					{
						focused: false,
						value: (this.props.fixerChange ?? _.identity)(this.state.value),
					},
					() => {
						if (this.props.fixerChange != null) {
							;(this.props.onUpdate ?? _.noop)(this.state.value)
						}
						;(this.props.onBlur ?? _.noop)(e)
					},
				)
			},
			// Sanitise the value and send the callback event
			onChange: e => {
				// Fix the value with an optional fixer property function
				const value = (this.props.fixer ?? _.identity)(e.target.value)
				// Update the state and emit the update event
				this.setState({ value }, () => {
					this.resizeToFitContent()
					;(this.props.onUpdate ?? _.noop)(this.state.value)
				})
			},
		})
	}

	focus() {
		this.element.current?.focus()
	}
}

/**
 * Basic time entry component
 * @deprecated Use ui5 `TextboxTime`
 */
export class J2rTime extends React.PureComponent<{
	value?: string
	onUpdate?: Function
	disabled?: boolean
	placeholder?: string
}> {
	constructor(props) {
		super(props)
	}

	override render() {
		return j2r({
			tag: J2rText,
			cl: 'time',
			maxLength: 5,
			value: this.props.value,
			onUpdate: this.props.onUpdate,
			disabled: this.props.disabled,
			placeholder: this.props.placeholder,
			fixerChange: v => validateTime(v) ?? '',
			focusOnClick: true,
		})
	}
}

/**
 * Basic dropdown - select box
 * @deprecated Use `J2rComboBox`
 */
export class J2rDropdown extends React.Component<
	{
		value?: string
		multiple?: boolean
		integer?: boolean
		disabled?: boolean
		options?: any[]
		nullable?: boolean
		onUpdate?: Function
		cl?: string
	},
	any // TODO: fix state type
> {
	constructor(props) {
		// cl, options, nullable, value, disabled, onUpdate, integer, multiple
		super(props)
		Bindings(this, [this.getValue, this.sanitizeValue])
		this.state = { value: this.props.value }
	}

	override componentDidUpdate(prevProps) {
		// Reset the state when new external properties are given
		if (prevProps.value !== this.props.value) {
			this.setState({ value: this.sanitizeValue(this.props.value) })
		}
	}

	getValue(el) {
		return this.sanitizeValue(
			Do(() => {
				if (this.props.multiple) {
					return fsmData($(el).find('option').toArray(), {
						filter: el => el.selected,
						map: el => el.value ?? 1,
					})
				}
				return el.value
			}),
		)
	}

	sanitizeValue(v) {
		// Helper function to sanitize a single value in a potential array
		const sanitizeNode = x => {
			if (!x) {
				return null
			} else if (this.props.integer) {
				return +x || null
			}
			return x
		}

		// Sanitize either a single item or an array
		if (this.props.multiple) {
			return v.map(sanitizeNode)
		}
		return sanitizeNode(v)
	}

	override render() {
		return j2r({
			tag: 'select',
			cl: BuildClass({
				tb2: true,
				[this.props.cl]: true,
				disabled: this.props.disabled,
				multiple: this.props.multiple,
			}),
			multiple: this.props.multiple ? 'multiple' : undefined,
			disabled: this.props.disabled ? 'disabled' : undefined,
			value: this.state.value ?? '',
			children: Do(() => {
				const O = this.props.options.map(x => this.buildOption(x))
				if (this.props.nullable != null) {
					O.unshift(this.buildOption(['', this.props.nullable]))
				}
				return O
			}),
			onChange: e => {
				this.setState({ value: this.getValue(e.target) }, () =>
					(this.props.onUpdate ?? _.noop)(this.state.value),
				)
			},
		})
	}

	buildOption(opt) {
		if (opt.constructor === Array) {
			return {
				tag: 'option',
				key: opt[0],
				value: opt[0],
				text: opt[1],
			}
		}
		return {
			tag: 'optgroup',
			label: opt.label,
			key: `group-${opt.label}`,
			children: opt.children.map(o => this.buildOption(o)),
		}
	}
}

/**
 * Checkbox with material style
 * @deprecated Use ui5 `Checkbox`
 */
export class J2rCheckbox extends React.PureComponent<
	{
		cl?: string
		disabled?: boolean
		readOnly?: boolean
		label?: string
		title?: string
		onUpdate?: Function
		tabIndex?: number
		triggerClick?: boolean // Enables custom click-event when not in a <label />
		standalone?: boolean // Adjusts the styles to be simpler and not offset anything
		value?: boolean
		onClick?: Function
		labelChildren?: any[]
	},
	any // TODO: fix state type
> {
	checkbox: React.RefObject<HTMLInputElement>

	constructor(props) {
		super(props)
		this.checkbox = React.createRef()
		Bindings(this, [this.evFocus, this.evBlur])
		this.state = {
			value: props.value ?? false,
			focused: false,
		}
	}

	override componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({ value: this.props.value ?? false })
		}
		if (prevProps.disabled !== this.props.disabled) {
			this.setState({
				disabled: this.props.disabled ?? false,
			})
		}
	}

	override render() {
		return j2r({
			cl: BuildClass({
				'material-checkbox-wrapper': true,
				[this.props.cl]: true,
				focused: this.state.focused,
				disabled: this.props.disabled,
				standalone: this.props.standalone,
			}),
			title: this.props.title ? this.props.title : undefined,
			children: [
				{
					key: 1,
					cl: BuildClass({
						'material-checkbox': true,
						selected: this.state.value,
						disabled: this.props.disabled,
					}),
					text: !this.props.labelChildren
						? (this.props.label ?? '')
						: undefined,
					children: this.props.labelChildren,
				},
				{
					key: 2,
					tag: 'input',
					ref: this.checkbox,
					type: 'checkbox',
					tabIndex: this.props.tabIndex,
					disabled: this.props.disabled,
					readOnly: this.props.readOnly,
					checked: this.state.value,
					onChange: () => {
						this.setState({ value: this.checkbox.current.checked }, () =>
							(this.props.onUpdate ?? _.noop)(this.state.value),
						)
					},
					onFocus: this.evFocus,
					onBlur: this.evBlur,
				},
			],
			onClick: e => {
				if (this.props.disabled || this.props.readOnly) {
					return
				}
				this.checkbox.current.focus()
				this.checkbox.current.checked = !this.state.value
				;(this.props.onClick ?? _.noop)(e)
				if (this.props.triggerClick) {
					this.setState({ value: this.checkbox.current.checked }, () =>
						(this.props.onUpdate ?? _.noop)(this.state.value),
					)
				}
			},
		})
	}

	evFocus() {
		this.setState({ focused: true })
	}

	evBlur() {
		this.setState({ focused: false })
	}

	focus() {
		this.checkbox.current.focus()
	}
}

type J2rSidebarControlProps<T extends string | number> = {
	cl?: string
	form: (value: T) => JSX.Element | J2rObject
	items?: {
		children?: any[]
		cl?: string
		text?: string
		title?: string
		value: T | -1
	}[]
	newLabel?: string
	onUpdate?: (val: T | -1) => void
	showFormOnNull?: boolean
	stubMessage?: string
	value?: T | -1
	width?: number
}

/**
 * Sidebar select and pane widget
 * @deprecated Use `SidebarControl` in ui5
 */
export class J2rSidebarControl<T extends string | number> extends React.Component<
	J2rSidebarControlProps<T>,
	any
> {
	listElement: React.RefObject<HTMLDivElement>

	constructor(props) {
		super(props)
		this.listElement = React.createRef()
		this.state = { value: props.value }
	}

	override componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({ value: this.props.value })
		}
	}

	override componentDidMount() {
		// Create a throttled function to update the scroll position state
		const fn = v => {
			if (v != null) {
				this.setState({ scrollPosition: v })
			}
		}
		const dbfn = _.throttle(fn, 50, {
			leading: true,
			trailing: true,
		})

		// Create the event to update the scroll position
		this.listElement.current?.addEventListener('scroll', () => {
			dbfn(this.listElement.current?.scrollTop)
		})
	}

	override render() {
		return (
			<div
				className={BuildClass({
					'j2r-sidebar-control': true,
					[this.props.cl]: true,
				})}
			>
				{this.buildSidebar()}
				{this.buildForm()}
			</div>
		)
	}

	buildSidebar() {
		return (
			<div
				className="sidebar"
				ref={this.listElement}
				style={{
					minWidth: `${this.props.width ?? 160}px`,
					maxWidth: `${this.props.width ?? 160}px`,
				}}
			>
				{this.getSidebarRows()}
			</div>
		)
	}

	buildForm() {
		let M: JSX.Element
		if (this.state.value || this.props.showFormOnNull) {
			M = j2r(this.props.form(this.state.value))
		} else if (this.props.stubMessage != null) {
			M = (
				<div className="stub-message-wrapper">
					<div className="stub-message">{this.props.stubMessage}</div>
				</div>
			)
		} else {
			M = <div />
		}

		// Add key and class
		return React.cloneElement(M, {
			key: M.key ?? 'form',
			className: BuildClass({
				[M.props.className]: true,
				form: true,
			}),
		})
	}

	getSidebarRows() {
		// Get the list of items
		// Include an add new button if requested
		let { items } = this.props
		if (this.props.newLabel) {
			items = items.concat({
				value: -1,
				cl: 'new',
				text: this.props.newLabel,
			})
		}

		// Build the row items
		const rows = items.map(item =>
			j2r({
				key: item.value,
				cl: BuildClass({
					item: true,
					[item.cl]: true,
					selected: item.value === this.state.value,
				}),
				text: item.text,
				title: item.title ?? item.text,
				children: item.children,
				onClick: () => {
					this.setState({ value: item.value }, () => {
						;(this.props.onUpdate ?? _.noop)(item.value)
					})
				},
			}),
		)

		// Return the rows
		return rows
	}
}

/** An appendable/deletable list component */
export class J2rListAddRemove extends React.Component<
	{
		nameMapping: Function
		sourceIDs: (number | string)[]
		selected?: (number | string)[]
		onUpdate?: Function
		onSave?: Function
		placeholder?: string
		overrideButtons?: boolean
	},
	{
		value?: number
		isLoading: boolean
		message: string
		selected: (number | string)[]
	}
> {
	constructor(props) {
		super(props)
		Bindings(this, [this.save, this.reset, this.add, this.remove])
		this.state = {
			value: null, // Always set back to null but flips quickly to reset the dropdopwn
			isLoading: false,
			message: '',
			selected: _.uniq(this.props.selected ?? []),
		}
	}

	override componentDidUpdate(prevProps) {
		if (!_.isEqual(prevProps.selected, this.props.selected)) {
			this.setState({ selected: this.props.selected }) // If true, hides internal button system
		}
	}

	override render() {
		return j2r({
			cl: 'j2r-list-add-remove',
			children: [
				// Dropdown to add new items
				this.buildForm(),

				// List box
				this.state.selected.length + this.props.selected.length > 0
					? this.buildSelected()
					: this.buildSelectedStub(),

				// Buttons - save and reset
				!this.props.overrideButtons ? this.buildButtons() : undefined,
			],
		})
	}

	buildSelectedStub() {
		return {
			cl: 'j2rlist selected noselect',
			key: 'selected-stub',
			children: [
				{
					key: 'stub-text',
					cl: 'error_msg_2 cntr',
					text: 'List Empty',
				},
			],
		}
	}

	buildSelected() {
		return {
			tag: J2rListComponent,
			cl: BuildClass({
				'selected-items noselect': true,
				'no-buttons': this.props.overrideButtons,
			}),
			key: 'selected',
			items: fsmData(_.uniq(this.state.selected.concat(this.props.selected)), {
				sort: x => {
					const prefix = !this.props.selected.includes(x) ? '** ' : ''
					return prefix + this.props.nameMapping(x)
				},
				map: x => this.buildRow(x),
			}),
		}
	}

	buildRow(x) {
		const is_new = !this.props.selected.includes(x)
		const is_del = !this.state.selected.includes(x)
		return {
			value: x,
			cl: BuildClass({
				'list-row': true,
				new: is_new,
				del: is_del,
			}),
			children: [
				// Label
				{
					tag: 'span',
					key: 'lbl',
					cl: 'lbl',
					text: this.props.nameMapping(x),
					title: this.props.nameMapping(x),
				},

				// Indicator (if changed)
				{
					cl: 'indicator',
					key: 'indicator',
					children: [''],
					title: Do(() => {
						if (is_new) {
							return 'Adding to list (after you click save)'
						} else if (is_del) {
							return 'Deleting from list (after you click save)'
						}
						return undefined
					}),
				},

				// Button to delete (or re-add)
				{
					tag: 'span',
					key: 'delete-button',
					cl: BuildClass({
						'delete-button': true,
						add: is_del,
					}),
					children: [
						{
							tag: 'img',
							key: 'icon',
							src: '/static/img/cross.png',
						},
					],
					onClick: () => {
						if (is_del) {
							this.add(x)
						} else {
							this.remove(x)
						}
					},
				},
			],
		}
	}

	buildForm() {
		return {
			cl: 'add-new-item',
			key: 'add-new-item',
			children: [
				{
					tag: J2rComboBox,
					key: 'dropdown',
					value: this.state.value,
					placeholder: this.props.placeholder ?? 'Add Item...',
					options: fsmData(this.props.sourceIDs, {
						filter: x => !this.state.selected.includes(x),
						sort: x => this.props.nameMapping(x),
						map: x => ({
							value: x,
							text: this.props.nameMapping(x),
						}),
					}),
					onUpdate: v => {
						// Add the item if one was selected
						if (v.value != null) {
							this.add(v.value)
						}

						// Flip the dropdown back to null
						this.setState({ value: v.value }, () => {
							this.setState({ value: null })
						})
					},
				},
			],
		}
	}

	buildButtons() {
		return j2rButtonResponseSet(this.state.isLoading, this.state.message, {
			Save: this.save,
			Reset: this.reset,
		})
	}

	logUpdate() {
		// Reports back to the parent component (if asked)
		;(this.props.onUpdate ?? _.noop)(this.state.selected)
	}

	add(k) {
		this.setState(
			s => ({ selected: _.uniq(s.selected.concat(k)) }),
			() => {
				this.logUpdate()
			},
		)
	}

	remove(k) {
		this.setState(
			s => ({ selected: _.uniq(s.selected.filter(x => x !== k)) }),
			() => {
				this.logUpdate()
			},
		)
	}

	reset() {
		const delta = {
			message: '',
			selected: _.uniq(this.props.selected),
		}
		this.setState(delta, () => {
			this.logUpdate()
		})
	}

	save() {
		// Get the delta for added items and delted items
		const delta = {
			added: _.filter(this.state.selected, x => !this.props.selected.includes(x)),
			removed: _.filter(this.props.selected, x => !this.state.selected.includes(x)),
		}

		// If nothing has changed, don't do anything
		if (delta.added.length + delta.removed.length === 0) {
			this.setState({ message: 'Nothing changed' })
			timer(1000, () => {
				this.setState({ message: '' })
			})
			return
		}

		// Call the `onSave` handler
		this.setState({
			isLoading: true,
			message: '',
		})
		this.props.onSave(delta, msg =>
			timer(() => {
				this.setState({ isLoading: false })
				if (msg === true) {
					timer(() => {
						this.reset()
					})
				} else {
					this.setState({ message: msg })
				}
			}),
		)
	}
}

/**
 * A tooltip wrapper element
 * @deprecated Use `HelpTooltip` instead
 */
export class J2rTooltip extends React.Component<
	{
		title: string
		content: any[] | string
		elTag?: string
		cl?: string
		options?: object
	},
	any // TODO: fix state type
> {
	static defaultProps = {
		elTag: 'div',
		cl: '',
		options: {},
	}
	element: React.RefObject<unknown>
	instance: any

	constructor(props) {
		super(props)
		this.element = React.createRef()
		this.instance = null
	}

	override componentDidMount() {
		this.instance = this.rebuild()
	}

	override componentDidUpdate(prevProps) {
		// If the options have changed, rebuild the tippy
		if (!_.isEqual(this.props.options, prevProps.options)) {
			this.instance.destroy()
			this.instance = this.rebuild()
			return
		}

		// If the tooltip has updated, update
		if (prevProps.title !== this.props.title) {
			this.instance.setContent(this.props.title)
		}
	}

	override componentWillUnmount() {
		this.instance.destroy()
	}

	rebuild() {
		const result = tippy(
			this.element.current,
			_.assign(
				{ content: this.props.title },
				this.getDefaultOptions(),
				this.props.options,
			),
		)
		return result.instances[0]
	}

	getDefaultOptions() {
		return {
			delay: 1000,
			arrow: true,
			touchHold: true,
		}
	}

	override render() {
		return j2r(() => {
			// Build the base element with known fields
			const isText = typeof this.props.content === 'string'
			const el = {
				tag: this.props.elTag,
				ref: this.element,
				cl: `tippy-wrapper ${this.props.cl}`,
				children: !isText ? this.props.content : undefined,
				text: isText ? this.props.content : undefined,
			}

			// Dynamic pass-through of any other given props
			const used = ['title', 'options', 'content', 'elTag', 'cl']
			fsmData(_.keys(this.props), {
				filter: x => !used.includes(x),
				map: x => (el[x] = this.props[x]),
			})

			// Return
			return el
		})
	}
}

/**
 * Help indicators with tooltip
 * @deprecated Use `HelpIcon` instead
 */
export class J2rHelp extends React.Component<{
	title: string
	cl?: string
}> {
	static defaultProps = { cl: '' }

	constructor(props) {
		super(props)
	}

	override render() {
		return j2r({
			tag: J2rTooltip,
			elTag: 'img',
			cl: `help-tooltip-global ${this.props.cl}`,
			alt: 'Help',
			tabIndex: -1,
			src: '/static/img/svg/small-help-icon.svg',
			title: this.props.title,
			options: { delay: 200 },
			content: '',
		})
	}
}

/**
 * A modal higher-order component to wrap content in modals
 * @deprecated use `Modal` in ui5
 */
export class J2rModalPortal extends React.Component<{
	cl?: string
	children?: React.ReactNode
}> {
	element: HTMLDivElement

	constructor(props) {
		super(props)
		this.element = document.createElement('div')
		this.element.classList.add('j2r-modal')
		if (this.props.cl != null) {
			this.element.classList.add(this.props.cl)
		}
	}

	override componentDidMount() {
		document.body.appendChild(this.element)
	}

	override componentWillUnmount() {
		document.body.removeChild(this.element)
	}

	override componentDidUpdate(prevProps) {
		if (prevProps.cl !== this.props.cl) {
			if (prevProps.cl != null) {
				this.element.classList.remove(prevProps.cl)
			}
			if (this.props.cl != null) {
				this.element.classList.add(this.props.cl)
			}
		}
	}

	override render() {
		return ReactDOM.createPortal(this.props.children, this.element)
	}
}

/** A read-only text component with an open external icon */
export class J2rLabelOpenExternal extends React.Component<
	{
		cl?: string
		label?: string
		placeholder?: string
		title?: string
		onClick?: Function
	},
	any // TODO: fix state type
> {
	constructor(props) {
		super(props)
		Bindings(this, [this.openExternal])
	}

	override render() {
		return j2r({
			cl: BuildClass({
				[this.props.cl]: true,
				'text-label-open-external': true,
			}),
			children: [
				{
					tag: J2rText,
					key: 'lbl',
					disabled: true,
					title: this.props.title,
					value: this.props.label,
					placeholder: this.props.placeholder,
				},
				{
					tag: J2rButton,
					key: 'btn',
					cl: 'open-external',
					type: 'borderless',
					inner: [
						{
							tag: 'img',
							key: 'img',
							src: '/static/img/svg/open-external.svg',
						},
					],
					onClick: this.openExternal,
				},
			],
		})
	}

	openExternal() {
		;(this.props.onClick ?? _.noop)()
	}
}
