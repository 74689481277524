import { BuildClass } from '..'
import { React, _ } from '../../lib'
import { CJSX } from './meta-types'
import { stubTextarea } from './stubs'
import { Textarea } from './textbox'
import { VoiceRecorder } from './voice-recorder'

/** Multi-line textbox component with toggle to use voice input */
export const TextareaWithVoice = React.forwardRef(
	(
		props: {
			value: string
			onUpdate: (value: string) => void
			prompt: string | null
			maxLength: number
			fixOnBlur?: (value: string) => string
			serialize?: (value: string) => string
			deserialize?: (valueString: string) => string
			onFinishTranscribing?: (value: string) => void
			selectOnClick?: boolean
			autoExpand?: boolean
			disableVoiceInput?: boolean
		} & React.DetailedHTMLProps<
			React.TextareaHTMLAttributes<HTMLTextAreaElement>,
			HTMLTextAreaElement
		>,
		ref: React.ForwardedRef<HTMLTextAreaElement>,
	) => {
		// DOM reference
		const refInput = React.useRef<HTMLTextAreaElement>(stubTextarea)

		// Reference
		React.useImperativeHandle(ref, () => refInput.current)

		// Track whether the voice recording is visible
		const [voiceRecording, setVoiceRecording] = React.useState(false)
		const [isTranscribing, setTranscribing] = React.useState(false)

		// Render
		return (
			<div
				className={BuildClass({
					'ui5 ui5-textarea tailwind-wrapper': true,
					[props.className ?? '']: true,
				})}
				style={{ border: 'none', padding: '0px', margin: '0px' }}
			>
				<div className="w-full h-full relative">
					<div className="w-full h-full z-0">
						<Textarea
							ref={refInput}
							removeBaseClass={true}
							className="w-full h-full p-[3px] resize-none border border-[hsl(0,0%,75%)] text-[#000] pb-6"
							{..._.omit(props, [
								'ref',
								'className',
								'prompt',
								'maxLength',
							])}
						/>
						<div
							className={BuildClass({
								'absolute inline-flex bottom-[2px] right-[1px] p-1': true,
								'text-xs text-[#888] select-none bg-[rgba(255,255,255,0.8)]':
									true,
								'text-red-700': props.maxLength < props.value.length,
							})}
							title={`Maximum length allowed: ${props.maxLength}`}
						>
							<span className="leading-4 mr-1">{props.value.length}</span>
							<InputProgressDonut
								className="align-baseline"
								value={props.value.length}
								max={props.maxLength}
							/>
							<CJSX cond={!(props.disableVoiceInput ?? false)}>
								<button
									type="button"
									className={BuildClass({
										'group px-1 py-0 m-[-4px] ml-1 inline-block':
											true,
										'hover:outline-1 hover:outline-blue-700 hover:outline hover:bg-yellow-200':
											true,
									})}
									onClick={() => {
										setVoiceRecording(true)
									}}
									title="Click to dictate text using your voice."
								>
									<img
										src="/static/img/i8/ios16-microphone.svg"
										className="w-5 h-5 opacity-70 group-hover:opacity-100"
									/>
								</button>
							</CJSX>
						</div>
					</div>
					<CJSX cond={voiceRecording}>
						<div
							className={BuildClass({
								'w-[calc(100%-2px)] absolute bottom-[1px] left-[1px] z-10 bg-white':
									true,
								collapse: isTranscribing,
							})}
						>
							<VoiceRecorder
								className="h-full w-full"
								prompt={props.prompt}
								autoStart={true}
								onSend={v => {
									const value = `${props.value ?? ''}\n\n${v}`.trim()
									props.onUpdate(value)
									setVoiceRecording(false)
									setTranscribing(false)
									props.onFinishTranscribing(value)
								}}
								onTranscribingStart={() => {
									setTranscribing(true)
								}}
								onCancel={() => {
									setTranscribing(false)
									setVoiceRecording(false)
								}}
							/>
						</div>
					</CJSX>
					<CJSX cond={isTranscribing}>
						<div className="w-[calc(100%-2px)] absolute bottom-[1px] left-[1px] z-20 bg-white text-center leading-6">
							Transcribing...
						</div>
					</CJSX>
				</div>
			</div>
		)
	},
)

const InputProgressDonut = (props: {
	className?: string
	value: number
	max: number
}) => {
	// Calculate percentage progress and whether it's been exceeded
	const percentage = Math.min(props.value / props.max, 1)
	const hasExceeded = props.value > props.max
	const colour = hasExceeded ? 'hsl(0, 100%, 50%)' : 'hsl(120, 100%, 30%)'

	// Render
	return (
		<div
			role="progressbar"
			aria-valuenow={props.value}
			aria-valuemax={props.max}
			aria-valuemin={0}
			aria-live="polite"
			className={BuildClass({
				'relative inline-block w-4 h-4 rounded-full': true,
				'after:bg-white after:rounded-full after:w-2 after:h-2': true,
				[props.className ?? '']: true,
			})}
			style={{
				background: `conic-gradient(${colour} ${percentage * 100}%, #DDDDDD 0%)`,
			}}
		></div>
	)
}
