import { BuildClass, Do, Maybe, fsmData } from '../../../universal'
import { React, _ } from '../../lib'
import { Checkbox } from './checkbox'
import { ContextMenuItem, setContextMenu } from './context-menu'
import { HelpIcon } from './help'
import { List, ListInstance, ListRow } from './list'
import { getInternalValue } from './list-util'
import {
	ConditionalObject,
	RSInstance,
	RSInstanceD,
	useRSInstance,
	useStateSync,
} from './meta-types'
import { stubListInstance } from './stubs'
import { TextSearched, searchText } from './text'

// TYPES #################################################################################

export type groupID = any
export type sortableVal = any
export type filterVal = any

export type HSL = { h: number; s: number; l: number }

export type ListGridGroup<T> = {
	ID: groupID
	Name: string
	Records: T[]
}

type groupingDelta<T> = {
	groupingField: string
	groupingFieldRev: boolean
	grouping: groupingState<T>
}

export type ListGridField<T> = {
	// Column information
	key: string // Key of the record
	lbl: string // Label to show in the heading row
	lblLong?: string // Longer version of label to show in context menus
	lblDesc?: string // Label tooltip to show in the heading row
	tooltipBubble?: boolean // Whether a question mark tooltip hint is shown
	row?: number // Defines which sub-row this field appears on (default: 1)
	disabled?: boolean // Whether the row is hidden
	// Component attributes
	className?: (record: T) => Maybe<string> // Adds a custom class to each cell in the column
	text: (record: T) => string | number // Gets text from value - this is searchable
	searchable?: boolean
	display?: (record: T) => React.JSX.Element // Gets custom rendered JSX (overides `text`)
	tooltip?: (record: T) => string // Gets tooltip text
	preSort?: (record: T, isRev?: boolean) => sortableVal // Takes priority over sortVal - mostly for editable grid
	sortVal?: (record: T, isRev?: boolean) => sortableVal // Converts a value to a sortable value
	sortReverseDefault?: boolean // Whether the default sort is descending
	sortable?: boolean // Whether this field can be sorted (generally implied)
	onClick?: (record: T, e?: React.MouseEvent) => void // Pass-through on-click handler for the cell
	// Colouring by value options - default low: 0/100%/75%, high: 120/100%/70%
	colorVal?: (record: T) => number // Gets a value suitable for numeric colour scales
	colorScale?: { low?: HSL; high?: HSL }
	colorRange?: { low: number; high: number }
	// Grouping-specific field attributes
	groupVal?: (record: T) => groupID // Turns record into grouping ID (when grouped)
	groupName?: (groupID: groupID, record: T) => string // Turns grouping ID into group name (when grouped)
	groupSort?: (group: ListGridGroup<T>) => sortableVal // Turns grouping ID into sort index (when grouped)
	// Filtering-by-value-specific field attributes
	filterVal?: {
		getKey: (record: T) => string | number // Overrides `text` to get value to filter by
		getText: (key: string | number) => string // Turns the key from `filterVal.getKey` into string label
	}
	filterValues?: () => Maybe<filterVal[]> // Options shown shown even if not found in current list
	width?: {
		/** Default width of the column */
		init?: number
		/** Flex value for the column */
		flex?: number
	}
}

export type ListGridView = {
	colorBy?: string
	// Sorting
	sortKey?: string
	sortRev?: boolean
	// Filtering
	hiddenFields?: string[]
	columnFilters?: { [key: string]: Maybe<filterVal[]> }
	// Grouping
	groupingField?: string
	groupingFieldRev?: boolean
}

type ListGridProps<T, V, M> = {
	className?: string
	classRow?: (record: Maybe<T>) => string // Takes in a record and returns its custom classes
	classCell?: (record: Maybe<T>, field: ListGridField<T>) => string // Takes in a record and field, returns custom classes
	tooltipRow?: (record: Maybe<T>) => string // Takes in a record and returns its custom tooltip
	pk: (record: T) => V
	selectable?: (record: T) => boolean // Whether a row can be selected (defaults to true)
	onContextMenu?: (record: T, e: React.MouseEvent) => void
	onDoubleClick?: (record: T, e: React.MouseEvent) => void
	onKeyDown?: (
		ev: React.KeyboardEvent<HTMLInputElement>,
		defaultEvent: (ev: React.KeyboardEvent<HTMLInputElement>) => void,
	) => void
	data: T[]
	searchText?: string // Overrules the one in the `view` model - legacy
	fields: ListGridField<T>[]
	// Grouping options
	grouping?: groupingState<T>
	// Selection state management
	value: M extends true ? V[] : Maybe<V>
	onUpdate: (value: M extends true ? V[] : Maybe<V>) => void
	multiple: M
	multipleDefault?: boolean
	readOnly?: boolean
	checkboxSelection?: boolean
	// View stetings - sort / group / filters / colour etc.
	// Leave as null for the reset default
	view?: ListGridView
	onUpdateView?: (value: ListGridView) => void
	defaultSortingKey: string
	defaultSortingReverse?: boolean
	// Extra options
	height?: (record: T) => number
	sortable?: boolean
	onClick?: (record: T, ev?: React.MouseEvent) => void // Passes in the record associated with the clicked row
	style?: React.CSSProperties
	sortableItems?: any // Drag and drop manual sorting // TODO ???
	disableFocusControl?: boolean
	disableClickHandlers?: boolean
	disableLazyRendering?: boolean
}

export type groupingState<T> = {
	key: (record: T) => groupID // Takes in a row record, gives a grouping ID
	name: (groupID: groupID, record: T) => Maybe<string> // Takes in a grouping ID, gives a title string
	sort: (group: ListGridGroup<T>) => sortableVal // Takes grouping obj (ID, Name, Records)
	reverse: boolean // Whether the groups are reversed
	gaps: boolean // Whether an empty row sits above each new group
}

export type ListGridInstance<T, M extends boolean> = {
	getElement: () => HTMLDivElement
	getListElement: () => HTMLDivElement
	getFullTable: () => any[][]
	getFilteredRecords: (searchTextOverride?: string) => T[]
	getHiddenFields: () => string[]
	getList: () => ListInstance<string, M>
	focus: () => void
}

type ListGridState<T> = {
	value: string[]
	view: ListGridView
	grouping: groupingState<T>
}

type ListGridRefs<M extends boolean> = {
	root: React.RefObject<HTMLDivElement>
	optionsList: React.RefObject<ListInstance<string, M>>
	colorScale: React.MutableRefObject<[number, number]>
	needles: React.MutableRefObject<string[]>
}

type ReducerStateD<T, V extends string | number, M extends boolean> = RSInstanceD<
	ListGridProps<T, V, M>,
	ListGridState<T>,
	ListGridRefs<M>,
	Payload<T>
>
type ReducerState<T, V extends string | number, M extends boolean> = RSInstance<
	ListGridProps<T, V, M>,
	ListGridState<T>,
	ListGridRefs<M>
>
// updateState: (delta: (state: ListGridState<T>) => Partial<ListGridState<T>>) => void
// updateView: (delta: (state: ListGridView) => Partial<ListGridView>) => void
// updateGroups: (delta: (state: ListGridState<T>) => groupingDelta<T>) => void

// BASE HOOK #############################################################################

const ListGridComponent = <T, V extends string | number, M extends boolean>(
	props: ListGridProps<T, V, M>,
	ref: React.ForwardedRef<ListGridInstance<T, M>>,
): React.JSX.Element => {
	// Reducer
	const rs = useRSInstance<
		ListGridProps<T, V, M>,
		ListGridState<T>,
		ListGridRefs<M>,
		Payload<T>
	>({
		props: props,
		// Refs
		refs: {
			root: React.useRef(null),
			optionsList: React.useRef(stubListInstance),
			colorScale: React.useRef([0, 0]),
			needles: React.useRef([]),
		},
		// Initial state
		defaultState: React.useCallback(
			props => ({
				value: getInternalValue(props.value).map(String),
				view: getViewFromProps(props, props.view),
				grouping: getGroupingStateDefault(
					props,
					props.view?.groupingField ?? null,
					props.view?.groupingFieldRev ?? null,
				),
			}),
			[],
		),
		actionToDelta: getPartialStateFromAction,
	})

	// Update the list value event
	const updateListValue = React.useCallback((v: string | string[]) => {
		rs.dispatch([Action.UpdateSelectedValue, { value: getInternalValue(v) }])
	}, [])

	// Update the cached needles
	rs.refs.needles.current = (rs.props.searchText ?? '').split(/\s+/).filter(x => x)

	// Sync state for the value
	useStateSync<M extends true ? V[] : V, string[]>({
		propVal: props.value,
		stateVal: rs.state.value,
		setProp: v => {
			const vals = v.map(x => convertInternalValueToProp(props, x))
			const pVals = props.multiple ? vals : _.first(vals)
			props.onUpdate(pVals as M extends true ? V[] : V)
		},
		setState: v => {
			rs.dispatch([
				Action.UpdateSelectedValue,
				{ value: getInternalValue(v).map(String) },
			])
		},
		compareFn: _.isEqual,
	})

	// Sync state for the view - only if the view is given
	useStateSync<ListGridView, ListGridView>({
		propVal: getViewFromProps(rs.props, rs.props.view),
		stateVal: rs.state.view,
		setProp: v => rs.props.onUpdateView?.(v),
		setState: v => {
			updateView(rs, () => v)
		},
		disabled: !rs.props.view || !rs.props.onUpdateView,
	})

	// Cache the colour scale
	rs.refs.colorScale.current = React.useMemo(
		() => cacheColorScale(props.fields, props.data, rs.state.view.colorBy),
		[props.fields, props.data, rs.state.view.colorBy],
	)

	// Reference instance
	React.useImperativeHandle(ref, () => ({
		getElement: () => rs.refs.root.current,
		getListElement: () => rs.refs.optionsList.current.getElement(),
		getFullTable: () => getFullTable(rs),
		getFilteredRecords: (searchTextOverride?: string) =>
			getFilteredRecords(rs, searchTextOverride),
		getHiddenFields: () => rs.state.view.hiddenFields,
		getList: () => rs.refs.optionsList.current,
		focus: () => {
			rs.refs.optionsList.current.focus()
		},
	}))

	// Render
	return (
		<div
			className={BuildClass({
				'ui5-listgrid': true,
				[props.className]: true,
				'checkbox-selection': props.checkboxSelection,
			})}
			style={props.style}
		>
			{buildHeadingRow(rs)}
			<List<string, M>
				value={props.multiple ? rs.state.value : _.first(rs.state.value)}
				ref={rs.refs.optionsList}
				onUpdate={updateListValue}
				multiple={props.multiple}
				multipleDefault={props.multipleDefault}
				readOnly={props.readOnly}
				height={props.height?.(null) ?? 26}
				rows={_.compact(buildItems(rs))}
				// sortable={props.sortableItems} // TODO - add this back?
				disableFocusControl={props.disableFocusControl}
				disableClickHandlers={props.disableClickHandlers}
				onKeyDown={props.onKeyDown}
				disableLazyRendering={props.disableLazyRendering}
			/>
		</div>
	)
}

const ListGridStaticComponent = <T, V extends string | number>(
	props: Omit<
		ListGridProps<T, V, false>,
		| 'value'
		| 'onUpdate'
		| 'readOnly'
		| 'selectable'
		| 'multiple'
		| 'multipleDefault'
		| 'checkboxSelection'
		| 'disableFocusControl'
		| 'sortableItems'
	>,
	ref: React.ForwardedRef<ListGridInstance<T, false>>,
): React.JSX.Element => (
	<ListGrid
		ref={ref}
		value={null}
		onUpdate={_.noop}
		readOnly={true}
		selectable={() => false}
		multiple={false}
		multipleDefault={false}
		checkboxSelection={false}
		disableFocusControl={true}
		sortableItems={null}
		{...props}
	/>
)

// RENDER HELPERS ########################################################################
const buildHeadingRow = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
): React.JSX.Element => {
	// Group fields by row number
	const fieldsBySubRow = _.groupBy(rs.props.fields, F => F.row ?? 1)
	const subRowKeys = fsmData(_.keys(fieldsBySubRow), {
		sort: x => +x,
		map: x => +x,
	})
	return (
		<div
			className={BuildClass({
				heading: true,
				[rs.props.classRow?.(null)]: true,
			})}
		>
			{subRowKeys.map(sr => (
				<div
					key={sr}
					className={BuildClass({
						heading: true,
						'sub-row': true,
						[`sub-row-${sr}`]: true,
					})}
				>
					{ConditionalObject(sr == 1 && rs.props.checkboxSelection, () => (
						<span
							key="checkbox-selection"
							className="checkbox-selection cell noselect"
						>
							<Checkbox
								lbl=""
								value={Do(() => {
									const values = _.sortBy(
										rs.props.data.map(x => rs.props.pk(x)),
									)
									return _.isEqual(values, _.sortBy(rs.state.value))
								})}
								onClick={e => {
									e.stopPropagation()
								}}
								onUpdate={v => {
									updateState(rs, () => ({
										value: !v
											? []
											: rs.props.data.map(x =>
													String(rs.props.pk(x)),
												),
									}))
								}}
							/>
						</span>
					))}
					<>
						{fsmData(rs.props.fields, {
							filter: F =>
								(F.row ?? 1) == sr &&
								!(F.disabled ?? false) &&
								!rs.state.view.hiddenFields.includes(F.key),
							map: F => buildHeadingCell(rs, F),
						})}
					</>
				</div>
			))}
		</div>
	)
}

const buildHeadingCell = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	F: ListGridField<T>,
): React.JSX.Element => {
	// Cache some values first
	const key = F.key
	let lblDesc = F.lblDesc ?? F.lbl
	let { lbl } = F
	const sortable =
		(rs.props.sortable ?? true) &&
		(F.sortVal != null || F.text != null) &&
		(F.sortable ?? true)

	// Adjust the labels if it's filtered
	if (rs.state.view.columnFilters[key] != null) {
		lblDesc += ' (filtered by value)'
		lbl += '*'
	}

	// Build the heading cell field
	const view = rs.state.view
	return (
		<span
			key={key}
			className={BuildClass({
				// Class includes the field name, sorting stuff, and help indicator
				// Field-specific class for column identification
				[(key ?? '').toLowerCase().replace(/[^a-z0-9]/g, '')]: true,
				// Base styles
				cell: true, // All heading cells have this
				[rs.props.classCell?.(null, F)]: true, // Custom cell class
				[F.className?.(null)]: true, // Custom row class
				'help-indicator': F.tooltipBubble, // For help tooltips
				filtered: view.columnFilters[key] != null,
				// Sorting styles
				nosort: !sortable,
				sorted: sortable && key === view.sortKey,
				'sort-asc': sortable && key === view.sortKey && !view.sortRev,
				'sort-dsc': sortable && key === view.sortKey && view.sortRev,
				// Grouping styles
				grouped: key === view.groupingField,
				'group-asc': key === view.groupingField && !rs.state.grouping.reverse,
				'group-dsc': key === view.groupingField && rs.state.grouping.reverse,
			})}
			style={Do(() => {
				const s: React.CSSProperties = {}
				if (F.width?.init != null) {
					s.width = F.width.init
					s.maxWidth = F.width.init
					s.minWidth = F.width.init
				}
				if (F.width?.flex != null) {
					s.flex = F.width.flex
				}
				return s
			})}
			title={!F.tooltipBubble ? lblDesc : undefined}
			onClick={Do(() => {
				// Click event only applies if it's sortable or is the current grouping key
				// Grouped by this - reverse the asc/desc order
				if (key === view.groupingField) {
					return () => {
						updateState(rs, s => ({
							grouping: {
								...s.grouping,
								reverse: !s.grouping.reverse,
							},
						}))
					}
					// Sorting
				} else if (sortable) {
					return () => {
						updateView(rs, s => ({
							sortKey: key,
							sortRev: Do(() => {
								if (s.sortKey === key) {
									return !s.sortRev
								}
								return F.sortReverseDefault ?? false
							}),
						}))
					}
					// No on-click handler
				}
				return undefined
			})}
			onMouseDown={e => {
				// On middle mouse down - activate grouping
				// If already grouping by this column, ungroup
				if (e.button === 1) {
					e.stopPropagation()
					e.preventDefault()
					updateGroups(rs, s =>
						getGroupingDelta(rs, {
							groupingField: key !== s.view.groupingField ? key : null,
							groupingFieldRev: key !== s.view.groupingField ? false : null,
						}),
					)
					return
				}
			}}
			onContextMenu={e => {
				e.preventDefault()
				headingCellContextMenu(rs, e, F)
			}}
		>
			{ConditionalObject(!F.tooltipBubble, lbl)}
			{ConditionalObject(
				F.tooltipBubble,
				<>
					<div key="lbl" title={lblDesc}>
						{lbl}
					</div>
					<HelpIcon key="help-tooltip" title={lblDesc} />
				</>,
			)}
		</span>
	)
}

type ListRowWithContentArray<T extends string | number> = ListRow<T> & {
	contentArray: () => string[]
}
const buildItems = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
): ListRowWithContentArray<string>[] => {
	// Iterate over each group
	const groups = getGroupedRecords(rs)
	const grouping = rs.state.grouping ?? rs.props.grouping

	return _.flatMap(groups, group => {
		// Calculate what's happening with the headings
		const heading_gap =
			(groups.length > 1 || group.ID != null) && (grouping.gaps ?? true)
		const heading_row = (groups.length > 1 || group.ID != null) && group.Name != null
		let total_rows = group.Records.length
		total_rows += heading_gap ? 1 : 0
		total_rows += heading_row ? 1 : 0

		// Build the rows for this group
		return [
			// Build the heading for the group - only if there are groups and gaps wanted
			Do(() => {
				if (heading_gap) {
					return {
						height: group.ID === groups[0].ID ? 0 : null,
						value: `listgrid-group-gap-${group.ID}`,
						selectable: false,
						className: 'no-odd',
						content: () => (
							<div
								className={BuildClass({
									row: true,
									'listgrid-group-heading-gap': true,
									'no-select-highlight': true,
									'hidden-grouping-row': group.ID === groups[0].ID,
									[rs.props.classRow?.(null)]: true,
								})}
							>
								<span>&nbsp;</span>
							</div>
						),
						contentArray: () => [''],
					} as ListRowWithContentArray<string>

					// Otherwise add a hidden row
				} else if (heading_row) {
					return {
						height: 0,
						value: `listgrid-group-mod21-${group.ID}`,
						selectable: false,
						className: 'no-odd',
						content: () => <div className="row hidden-grouping-row" />,
						contentArray: () => [''],
					} as ListRowWithContentArray<string>
				}
				return null
			}),

			// Heading row for the group
			ConditionalObject(heading_row, () => buildGroupHeadingRow(rs, group)),

			// Apply an independent sort process to each group
			// Build the group items as if it's its own grid
			...buildItemsInnerGroup(rs, group.Records),

			// Add an empty row to ensure the group has an even number
			// This ensures the row highlights are consistent between groups
			ConditionalObject(heading_row && groups.length > 1 && total_rows % 2 != 0, {
				height: 0,
				value: `listgrid-group-mod22-${group.ID}`,
				selectable: false,
				className: 'no-odd',
				content: () => <div className="row hidden-grouping-row" />,
				contentArray: () => [''],
			}),
		]
	})
}

const buildItemsInnerGroup = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	records: T[],
): ListRowWithContentArray<string>[] => {
	// Get the sorting function
	const sortingFunctions = Do(() => {
		// Sorting key depends on whether it's user sortable
		const userSortable = rs.props.sortable ?? true
		const sortKey = userSortable ? rs.state.view.sortKey : rs.props.defaultSortingKey

		// Loop through the fields and find which sorting function to use
		// If not user-sortable, use the `defaultSortingKey`
		let sortFn = null
		let preSort = null
		_.forEach(rs.props.fields, F => {
			if (F.key == sortKey) {
				preSort = F.preSort
				sortFn = F.sortVal ?? F.text ?? (x => x[F.key])
				return false
			}
			return true
		})
		if (sortFn != null) {
			return _.compact([preSort, sortFn])
		}

		// No sorting key found
		console.warn('No sorting value found for key: ', sortKey)
		return [() => 1]
	})

	// Iterate the records
	return fsmData(records, {
		sort: sortingFunctions.map(fn => record => fn(record, rs.state.view.sortRev)),
		reverse: rs.state.view.sortRev && (rs.props.sortable ?? true),
		map: x => ({
			height: rs.props.height?.(x),
			selectable: (rs.props.selectable ?? (() => true))(x),
			value: String(rs.props.pk(x)),
			content: () => buildItem(rs, x),
			contentArray: () => buildItemContentArray(rs, x),
		}),
	})
}

const buildGroupHeadingRow = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	group,
): ListRowWithContentArray<string> => ({
	value: `listgrid-group-${group.ID}`,
	selectable: false,
	className: 'no-odd',
	contentArray: () => [group.Name],
	content: () => (
		<div
			className={BuildClass({
				row: true,
				'listgrid-group-heading': true,
				'no-select-highlight': true,
				[(rs.props.classRow ?? (() => ''))(null)]: true,
			})}
			title={`${group.Name} - ${group.Records.length}`}
		>
			{/*
					TODO - implement `@props.checkboxSelection` for group headings
					TODO - add collapsing button to show/hide the group
				*/}
			{group.Name}
		</div>
	),
})

const buildItemContentArray = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	record: T,
): string[] =>
	fsmData(rs.props.fields, {
		filter: F =>
			!(F.disabled ?? false) && !rs.state.view.hiddenFields.includes(F.key),
		map: F => String(F.text(record) ?? ''),
	})

const buildItem = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	record: T,
): React.JSX.Element => {
	// Group fields by row number
	const fieldsBySubRow = _.groupBy(rs.props.fields, F => F.row ?? 1)
	const subRowKeys = fsmData(_.keys(fieldsBySubRow), {
		sort: x => +x,
		map: x => +x,
	})

	// Build the row
	const selectable = (rs.props.selectable ?? (() => true))(record)
	const value = String(rs.props.pk(record))
	return (
		<div
			className={BuildClass({
				row: true,
				[(rs.props.classRow ?? (() => null))(record)]: true,
			})}
			title={rs.props.tooltipRow?.(record) ?? ''}
			onClick={ev => rs.props.onClick?.(record, ev)}
			onContextMenu={ev => rs.props.onContextMenu?.(record, ev)}
			onDoubleClick={ev => rs.props.onDoubleClick?.(record, ev)}
		>
			{subRowKeys.map(sr => (
				<div
					key={sr}
					className={BuildClass({
						'sub-row': true,
						[`sub-row-${sr}`]: true,
					})}
				>
					{/* Checkbox as the first field, if doing checkbox selection */}
					{ConditionalObject(sr == 1 && rs.props.checkboxSelection, () => (
						<span className="checkbox-selection cell">
							{ConditionalObject(
								selectable,
								<Checkbox
									lbl=""
									readOnly={true}
									value={_.includes(rs.state.value, value)}
									onUpdate={_.noop}
								/>,
							)}
						</span>
					))}

					{/* Fields */}
					<>
						{fsmData(rs.props.fields, {
							filter: F =>
								(F.row ?? 1) == sr &&
								!(F.disabled ?? false) &&
								!rs.state.view.hiddenFields.includes(F.key),
							map: F => (
								<span
									key={F.key}
									style={Do(() => {
										const s: React.CSSProperties =
											buildColorStyle(rs, F, record) ?? {}
										if (F.width?.init != null) {
											s.width = F.width.init
											s.maxWidth = F.width.init
											s.minWidth = F.width.init
										}
										if (F.width?.flex != null) {
											s.flex = F.width.flex
										}
										return s
									})}
									className={BuildClass({
										cell: true,
										[(F.key ?? '')
											.toLowerCase()
											.replace(/[^a-z0-9]/g, '')]: true,
										[rs.props.classCell?.(record, F)]: true,
										[F.className?.(record)]: F.className != null,
									})}
									title={Do(() => {
										if (F.tooltip != null) {
											return F.tooltip(record)
										}
										return String(F.text(record) ?? '')
									})}
									onClick={Do(() => {
										if (F.onClick != null) {
											return e => {
												F.onClick(record, e)
											}
										}
										return undefined
									})}
								>
									{buildCell(rs, F, record)}
								</span>
							),
						})}
					</>
				</div>
			))}
		</div>
	)
}

const buildColorStyle = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	field: ListGridField<T>,
	record: T,
): Maybe<{ background: string }> => {
	// Return null if not colouring by this field
	if (rs.state.view.colorBy == null || rs.state.view.colorBy !== field.key) {
		return null
	}

	// Get the value for this cell - no background if it's non-numeric
	let val = (field.colorVal ?? field.sortVal ?? field.text)?.(record)
	if (val == null || isNaN(+val)) {
		return null
	}
	val = +val

	// Get the colour style if colouring by this field and the proportion of the value
	const minMaxRange = field.colorRange ?? {
		low: rs.refs.colorScale.current[0],
		high: rs.refs.colorScale.current[1],
	}
	const scale = minMaxRange.high - minMaxRange.low
	const proportion = (val - minMaxRange.low) / scale // domain [0, 1]

	// Get the HSL scale for the field - fill in defaults of a red/green shift
	const hsl_high = field.colorScale?.high ?? { h: 120, s: 100, l: 70 }
	const hsl_low = field.colorScale?.low ?? { h: 0, s: 100, l: 75 }

	// Calculate the style on the spectrum and return
	const h = proportion * (hsl_high.h - hsl_low.h) + hsl_low.h
	const s = proportion * (hsl_high.s - hsl_low.s) + hsl_low.s
	const l = proportion * (hsl_high.l - hsl_low.l) + hsl_low.l
	return { background: `hsl(${h},${s}%,${l}%)` }
}

const buildCell = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	field: ListGridField<T>,
	record: T,
): React.JSX.Element => {
	// If it's a custom component, just render that
	// This doesn't show highlights on search result matches though
	if (field.display) {
		return <>{field.display(record)}</>
	}

	// Generate the text with possible search highlight
	const text = String(field.text(record) ?? '')
	return <TextSearched text={text} needles={rs.refs.needles.current} />
}

// CONTEXT MENU ##########################################################################

const headingCellContextMenu = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	ev: React.MouseEvent,
	field: ListGridField<T>,
): void => {
	setContextMenu({
		position: {
			x: ev.clientX,
			y: ev.clientY,
		},
		items: _.compact([
			// Reset to the default options
			{
				label: 'Reset view',
				icon: '/static/img/i8/material-outline-clear-filters.svg',
				onClick: () => {
					updateState(rs, () => ({
						grouping: null,
						view: getViewFromProps(rs.props, rs.props.view),
					}))
				},
			},

			// Copy the unfiltered table to the clipboard
			{
				label: 'Copy as text',
				icon: '/static/img/i8/material-outline-copy.svg',
				onClick: () => {
					const lines = _.map(getFullTable(rs), x => x.join('\t'))
					navigator.clipboard.writeText(lines.join('\n'))
				},
			},

			// Sorting options
			(rs.props.sortable ?? true) ? '---' : undefined,
			{
				label: 'Sort ascending',
				shortcut: 'LMB',
				shortcutDesc: 'Left mouse button',
				icon: '/static/img/i8/material-outline-ascending-sorting.svg',
				hidden: !(rs.props.sortable ?? true),
				onClick: () => {
					updateView(rs, () => ({
						sortKey: field.key,
						sortRev: false,
					}))
				},
			},
			{
				label: 'Sort descending',
				icon: '/static/img/i8/material-outline-descending-sorting.svg',
				hidden: !(rs.props.sortable ?? true),
				onClick: () => {
					updateView(rs, () => ({
						sortKey: field.key,
						sortRev: true,
					}))
				},
			},
			buildContextMenuSortBy(rs),

			// Grouping options
			(rs.props.sortable ?? true) ? '---' : undefined,
			{
				label: 'Group ascending',
				shortcut: 'MMB',
				shortcutDesc: 'Middle mouse button',
				icon: '/static/img/i8/material-outline-ascending-sorting.svg',
				hidden: !(rs.props.sortable ?? true),
				onClick: () => {
					updateGroups(rs, () =>
						getGroupingDelta(rs, {
							groupingField: field.key,
							groupingFieldRev: false,
						}),
					)
				},
			},
			{
				label: 'Group descending',
				icon: '/static/img/i8/material-outline-descending-sorting.svg',
				hidden: !(rs.props.sortable ?? true),
				onClick: () => {
					updateGroups(rs, () =>
						getGroupingDelta(rs, {
							groupingField: field.key,
							groupingFieldRev: true,
						}),
					)
				},
			},
			buildContextMenuGroupBy(rs),

			// Filtering by value
			'---',
			buildContextMenuFilterByValue(rs, field),

			// Column toggling
			{
				label: 'Hide column',
				icon: '/static/img/i8/material-outline-closed-eye.svg',
				onClick: () => {
					updateView(rs, s => ({
						hiddenFields: s.hiddenFields.concat(field.key),
					}))
				},
			},
			{
				label: 'Toggle columns...',
				icon: '/static/img/i8/material-outline-delete-column.svg',
				items: [
					{
						label: 'Show All',
						dontClose: true,
						icon: () => {
							if (rs.state.view.hiddenFields.length === 0) {
								return '/static/img/i8/material-outline-eye.svg'
							}
							return ''
						},
						onClick: () => {
							updateView(rs, s => ({
								hiddenFields: Do(() => {
									if (s.hiddenFields.length === 0) {
										return rs.props.fields.map(F => F.key)
									}
									return []
								}),
							}))
						},
					},
					'---',
					...fsmData(rs.props.fields, {
						filter: F => !(F.disabled ?? false),
						map: F => ({
							label: F.lblLong ?? F.lbl,
							labelDesc: F.lblDesc ?? F.lbl,
							icon: () => {
								if (rs.state.view.hiddenFields.includes(F.key)) {
									return null
								}
								return '/static/img/i8/material-outline-eye.svg'
							},
							dontClose: true,
							onClick: () => {
								updateView(rs, s => ({
									hiddenFields: Do(() => {
										if (s.hiddenFields.includes(F.key)) {
											return s.hiddenFields.filter(x => F.key !== x)
										}
										return s.hiddenFields.concat(F.key)
									}),
								}))
							},
						}),
					}),
				],
			},

			// Colour by value
			'---',
			{
				label: 'Colour by value',
				icon: '/static/img/i8/material-outline-paint-palette.svg',
				onClick: () => {
					updateView(rs, s => ({
						colorBy: s.colorBy !== field.key ? field.key : null,
					}))
				},
			},
		] as ContextMenuItem[]),
	})
}

const buildContextMenuSortBy = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
): ContextMenuItem => ({
	label: 'Sort By...',
	icon: '/static/img/i8/material-outline-sort.svg',
	hidden: !(rs.props.sortable ?? true),
	items: fsmData(rs.props.fields, {
		filter: F => (F.sortVal != null || F.text != null) && (F.sortable ?? true),
		map: F => ({
			label: F.lblLong ?? F.lbl,
			labelDesc: F.lblDesc ?? F.lbl,
			dontClose: true,
			icon: () => {
				if (rs.state.view.sortKey !== F.key) {
					return null
				} else if (rs.state.view.sortRev) {
					return '/static/img/i8/material-outline-descending-sorting.svg'
				}
				return '/static/img/i8/material-outline-ascending-sorting.svg'
			},
			onClick: () => {
				updateView(rs, s => ({
					sortKey: F.key,
					sortRev: Do(() => {
						if (s.sortKey === F.key) {
							return !s.sortRev
						}
						return F.sortReverseDefault ?? false
					}),
				}))
			},
		}),
	}),
})

const buildContextMenuGroupBy = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
): ContextMenuItem => ({
	label: 'Group by...',
	icon: '/static/img/i8/material-outline-group-objects.svg',
	hidden: !(rs.props.sortable ?? true),
	items: [
		// Group by none
		{
			label: 'None',
			dontClose: true,
			icon: () => {
				if (rs.state.view.groupingField != null) {
					return null
				}
				if (rs.props.grouping == null && rs.state.grouping != null) {
					return null
				}
				if (rs.props.grouping != null && rs.state.grouping == null) {
					return null
				}
				return '/static/img/i8/material-outline-checkmark.svg'
			},
			onClick: () => {
				if (rs.props.grouping == null) {
					updateGroups(rs, () =>
						getGroupingDelta(rs, {
							groupingField: null,
							groupingFieldRev: null,
							grouping: null,
						}),
					)
				} else {
					// Override default grouping with no grouping
					updateGroups(rs, () =>
						getGroupingDelta(rs, {
							groupingField: null,
							groupingFieldRev: null,
							grouping: {
								key: () => 1,
								name: () => null,
								sort: () => 1,
								reverse: false,
								gaps: false,
							},
						}),
					)
				}
			},
		},

		// Group by default - only if there is a default grouping
		{
			label: 'Default',
			hidden: rs.props.grouping == null,
			icon: () => {
				if (rs.state.grouping == null) {
					return '/static/img/i8/material-outline-checkmark.svg'
				}
				return ''
			},
			dontClose: true,
			onClick: () => {
				updateGroups(rs, () =>
					getGroupingDelta(rs, {
						groupingField: null,
						groupingFieldRev: null,
						grouping: null,
					}),
				)
			},
		},

		// Group by each field
		'---',
		...fsmData(rs.props.fields, {
			filter: F => (F.groupVal ?? F.text) != null && !(F.disabled ?? false),
			map: F => ({
				label: F.lblLong ?? F.lbl,
				labelDesc: F.lblDesc ?? F.lbl,
				icon: () => {
					if (rs.state.view.groupingField !== F.key) {
						return null
					} else if (rs.state.grouping.reverse) {
						return '/static/img/i8/material-outline-descending-sorting.svg'
					}
					return '/static/img/i8/material-outline-ascending-sorting.svg'
				},
				dontClose: true,
				onClick: () => {
					updateGroups(rs, s =>
						getGroupingDelta(rs, {
							groupingField: F.key,
							groupingFieldRev:
								s.view.groupingField === F.key
									? !s.view.groupingFieldRev
									: false,
						}),
					)
				},
			}),
		}),
	],
})

const buildContextMenuFilterByValue = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	field: ListGridField<T>,
): ContextMenuItem => ({
	label: 'Filter column',
	icon: '/static/img/i8/material-outline-filter.svg',
	hidden: field.text == null,
	items: () => {
		// Add the extra custom values defined on the field
		// This is handy if you know the values can come from a set and allow tthe user
		// to pre-filter them even though they're not in the current list
		// For example - filtering a task list by task state to ignore "Awaiting Resource"
		// even if that task state doesn't appear in the current list - for the future
		const values_fixed: filterVal[] = field.filterValues?.() ?? []

		// Get all unique values for this column, sorted
		let values_found = _.uniq(
			fsmData(rs.props.data, {
				sort: x => (field.sortVal ?? field.text)?.(x) ?? null,
				map: x => (field.filterVal?.getKey ?? field.text)?.(x) ?? null,
			}),
		)
		values_found = _.filter(values_found, x => !values_fixed.includes(x))

		// Join the two types of values together
		const values = _.uniq(_.concat(values_found, values_fixed))

		// Get the list of existing filters
		let existing = rs.state.view.columnFilters[field.key] ?? null

		// Build the context menu items
		const tick = '/static/img/i8/material-outline-checkmark.svg'
		return [
			// Select all/none
			{
				label: 'Select All',
				icon: existing == null ? tick : undefined,
				dontClose: true,
				onClick: () => {
					updateView(rs, s => {
						const c = _.clone(s.columnFilters)
						const k = field.key
						c[k] = c[k] != null ? null : []
						return { columnFilters: c }
					})
				},
			},
			'---',
			// All possible values
			..._.map(values, v => ({
				label: (field.filterVal?.getText ?? String)(v),
				icon: existing == null || existing.includes(v) ? tick : undefined,
				dontClose: true,
				onClick: () => {
					updateView(rs, s => {
						const c = _.clone(s.columnFilters)
						existing = c[field.key]
						if (existing == null) {
							existing = values.filter(x => x !== v)
						} else if (existing.includes(v)) {
							existing = existing.filter(x => x !== v)
						} else {
							existing = existing.concat(v)
						}
						if (_.difference(values, existing).length === 0) {
							existing = null
						}
						c[field.key] = existing
						return { columnFilters: c }
					})
				},
			})),
		]
	},
})

// HELPERS ###############################################################################

const getViewFromProps = <T, V extends string | number, M extends boolean>(
	props: ListGridProps<T, V, M>,
	view: ListGridView,
): ListGridView => ({
	colorBy: view?.colorBy ?? null,
	sortKey: view?.sortKey ?? props.defaultSortingKey,
	sortRev: view?.sortRev ?? props.defaultSortingReverse ?? false,
	hiddenFields: view?.hiddenFields ?? [],
	columnFilters: view?.columnFilters ?? {},
	groupingField: view?.groupingField ?? null,
	groupingFieldRev: view?.groupingFieldRev ?? null,
})

const getGroupedRecords = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
): ListGridGroup<T>[] => {
	// Get the grouping data - fallback to props if no state given
	const grouping = rs.state.grouping ?? rs.props.grouping

	// Filter the records
	const records = getFilteredRecords(rs)

	// Run each record through the grouping function to divide into groups
	const groups = _.groupBy(records, x => JSON.stringify(grouping?.key?.(x) ?? null))

	// Helper function to get a group name from its ID
	const getGroupObj = (k): ListGridGroup<T> => {
		const items: T[] = groups[JSON.stringify(k)]
		return {
			ID: k,
			Name: grouping?.name?.(k, items[0]) ?? null,
			Records: items,
		}
	}

	// Get the group keys and sort them
	let group_keys = _.keys(groups).map(x => JSON.parse(x))
	group_keys = _.sortBy(group_keys, gk => {
		const fn = grouping?.sort ?? (x => x.Name)
		return fn(getGroupObj(gk))
	})

	// Reverse if desired
	if (grouping?.reverse) {
		_.reverse(group_keys)
	}

	// Return the array of groups
	return _.map(group_keys, x => getGroupObj(x))
}

const getFilteredRecords = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	searchTextOverride?: string,
): T[] => {
	// Get our override needle if one was supplied
	let needle = rs.refs.needles.current
	if (searchTextOverride != null) {
		needle = searchTextOverride.split(/\s+/).filter(x => x)
	}

	// Filter based on search text, if applicable
	const filterSearchText = Do(() => {
		// No search text? Not filtering by search
		if (needle.length == 0) {
			return () => true
		}

		// Get all searchable text generation functions - to lowercase
		const textFns = fsmData(rs.props.fields, {
			filter: F =>
				F.text != null && (F.searchable ?? true) && !(F.disabled ?? false),
			map: F => (x: T) => String(F.text(x) ?? '').toLowerCase(),
		})

		// Build a search filter function
		return (record: T) => {
			let found = false
			_.forEach(textFns, fn => {
				if (searchText(fn(record), needle, true)) {
					found = true
					return false
				}
				return true
			})
			return found
		}
	})

	// Filter based on values
	const filterColumnValues = Do(() => {
		// Get all columns with value filters
		const cols = fsmData(rs.props.fields, {
			filter: F => {
				const values = rs.state.view.columnFilters[F.key]
				return values != null && F.text != null
			},
			map: F => ({
				getValue: record => (F.filterVal?.getKey ?? F.text)?.(record) ?? null,
				values: rs.state.view.columnFilters[F.key],
			}),
		})

		// If no values are filtered, make a faster filter function
		if (cols.length === 0) {
			return () => true
		}

		// Build value filter function
		return (record: T) => {
			let failed = false
			_.forEach(cols, x => {
				const needle = x.getValue(record)
				if (!x.values.includes(needle)) {
					failed = true
					return false
				}
				return true
			})
			return !failed
		}
	})

	// Filter the raw data record properties to get the filtered records
	return _.filter(rs.props.data, record => {
		// Check if it fails the search filter
		if (!filterSearchText(record)) {
			return false
		}

		// Check if it fails the field value filters
		if (!filterColumnValues(record)) {
			return false
		}

		// All tests passed
		return true
	})
}

const getGroupingDelta = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	group: {
		groupingField: Maybe<string>
		groupingFieldRev: Maybe<boolean>
		grouping?: groupingState<T>
	},
): groupingDelta<T> => {
	// If the grid is not sortable, we can't group
	if (!(rs.props.sortable ?? true)) {
		return {
			groupingField: null,
			groupingFieldRev: null,
			grouping: null,
		}
	}
	return {
		groupingField: group.groupingField,
		groupingFieldRev: group.groupingFieldRev,
		grouping:
			group.grouping ??
			getGroupingStateDefault(
				rs.props,
				group.groupingField,
				group.groupingFieldRev,
			),
	}
}

const getGroupingStateDefault = <T, V extends string | number, M extends boolean>(
	props: ListGridProps<T, V, M>,
	groupingField: Maybe<string>,
	groupingFieldRev: Maybe<boolean>,
): groupingState<T> => {
	// Get the field for the `groupingField`
	const F = fsmData(props.fields, {
		filter: F => groupingField === F.key,
		takeFirst: true,
	})

	// No grouping field? Nothing to group
	if (F == null) {
		return null
	}

	// Get the grouping key function
	// Nothing found? Trying to group by an ungroupable field
	const keyFn = F.groupVal ?? F.sortVal ?? F.text
	if (keyFn == null) {
		return {
			key: () => 1,
			name: () => null,
			sort: () => 1,
			reverse: false,
			gaps: false,
		}
	}

	// Build the four-grouping function array
	return {
		key: keyFn,
		name: F.groupName ?? ((_k, x) => String(F.text(x))),
		sort: F.groupSort ?? (x => (F.sortVal ?? F.text)(x.Records[0])),
		reverse: groupingFieldRev,
		gaps: false,
	}
}

const getFullTable = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
): string[][] => {
	// Get the heading
	const heading_row = fsmData(rs.props.fields, {
		filter: F =>
			!(F.disabled ?? false) && !rs.state.view.hiddenFields.includes(F.key),
		map: F => String(F.lbl),
	})

	// Get the content values
	const rows = _.compact(buildItems(rs)).map((x: any) => {
		const rowFn = x?.getItem != null ? x.getItem : x
		const row = _.isFunction(rowFn) ? rowFn() : rowFn
		return row.contentArray()
	})

	// Return them joined together
	return [heading_row, ...rows]
}

const cacheColorScale = <T extends any>(
	propsFields: ListGridField<T>[],
	propsData: T[],
	stateColorBy: string,
): [number, number] => {
	// If there's no colouring option selected, exit early setting it to null
	if (stateColorBy == null) {
		return [null, null]
	}

	// Get the field function
	const fn = fsmData(propsFields, {
		filter: F => stateColorBy === F.key,
		map: F => F?.colorVal ?? F?.sortVal ?? F?.text ?? _.noop,
		takeFirst: true,
	})

	// Get the values for each record
	// Cache the min and max values
	const vals = _.map(propsData, x => +fn(x) || null)
	return [_.min(vals), _.max(vals)]
}

const convertInternalValueToProp = <T, V extends string | number, M extends boolean>(
	props: ListGridProps<T, V, M>,
	val: string,
): V => {
	let isNumeric = false
	const record = props.data[0]
	if (record) {
		isNumeric = _.isNumber(props.pk(record))
	}
	return isNumeric ? (+val as V) : (val as V)
}

// ACTION HELPERS ########################################################################

const updateState = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	delta: (state: ListGridState<T>) => Partial<ListGridState<T>>,
) => {
	rs.dispatch([Action.UpdateState, delta])
}
const updateView = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	delta: (state: ListGridView) => Partial<ListGridView>,
) => {
	rs.dispatch([Action.UpdateView, delta])
}
const updateGroups = <T, V extends string | number, M extends boolean>(
	rs: ReducerStateD<T, V, M>,
	delta: (state: ListGridState<T>) => groupingDelta<T>,
) => {
	rs.dispatch([Action.UpdateGrouping, delta])
}

// ACTION INDEX ##########################################################################

enum Action {
	UpdateState,
	UpdateView,
	UpdateGrouping,
	UpdateSelectedValue,
}

type Payload<T> =
	| [Action.UpdateState, (state: ListGridState<T>) => Partial<ListGridState<T>>]
	| [Action.UpdateView, (state: ListGridView) => Partial<ListGridView>]
	| [Action.UpdateGrouping, (state: ListGridState<T>) => groupingDelta<T>]
	| [Action.UpdateSelectedValue, { value: string[] }]

const getPartialStateFromAction = <T, V extends string | number, M extends boolean>(
	rs: ReducerState<T, V, M>,
	p: Payload<T>,
): Maybe<Partial<ListGridState<T>>> => {
	switch (p[0]) {
		case Action.UpdateState:
			return p[1](rs.state)
		case Action.UpdateSelectedValue:
			return { value: p[1].value }
		case Action.UpdateView:
			return { view: { ...rs.state.view, ...p[1](rs.state.view) } }
		case Action.UpdateGrouping:
			const d = p[1](rs.state)
			return {
				grouping: d.grouping,
				view: {
					...rs.state.view,
					groupingField: d.groupingField,
					groupingFieldRev: d.groupingFieldRev,
				},
			}
	}
}

// FINAL EXPORTS #########################################################################

/**
 * A listgrid component using lambda bindings.
 * See `ListGridProps` and `ListGridField` for input info.
 */
export const ListGrid = React.forwardRef(ListGridComponent)

/**
 * A static version of the `ListGrid` component.
 * Removes compulsory fields regarding row selection.
 */
export const ListGridStatic = React.forwardRef(ListGridStaticComponent)
