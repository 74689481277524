/**
 * This is where we store all editable grid components
 * These will just wrap other components with some adapter logic
 */

import { ContactCard, Maybe, ModelType } from '../../../universal'
import { React, _ } from '../../lib'
import { ContactWidgetHook } from '../component-address-contact'
import { validateTime } from '../component-main'
import { moment } from '../moment-wrapper'
import { Checkbox as CheckboxInner } from './checkbox'
import { ColorPicker } from './color'
import { ComboBoxOptions, Combobox as ComboboxInner } from './combobox'
import { Datebox } from './date'
import { CellValidation, GridField } from './editable-grid'
import { sortableVal } from './list-grid'
import { Focusable } from './meta-types'
import { TextSearched } from './text'
import { Textbox } from './textbox'

const Inf = Infinity

type Dict = { [key: string]: any }

type CellComponentInput<T, V> = {
	record: T
	value: V
	ref: React.RefObject<Focusable<Element>>
	searchText: Maybe<string>
	readOnly: boolean
	onUpdate: (value: Maybe<V>, commit?: boolean) => void
	onFocus: () => void
	onBlur: () => void
	onCommit: () => void // When this is run, the current value is made into the view
}

export type CellComponent<T, V> = {
	cmpt: (input: CellComponentInput<T, V>) => React.JSX.Element // JSX with I/O
	validation: CellValidation[]
	className?: (value: V) => string
	text?: (value: V) => string // Searchable - available if we want a static view
	sortVal?: (value: V, isRev?: boolean) => sortableVal // Generally the same as text
	readOnly?: boolean | ((record: T) => boolean) // Can be overridden by the field
	proxyValue?: (value: V) => V // Modifies a value before updating
}

// Function for checking whether the readOnly flag is a boolean or a function,
// if it is a function then it must be called with the record passed through
export const checkReadOnly = <T,>(
	readOnly: Maybe<boolean | ((record: T) => boolean)>,
	record: T,
): boolean => {
	if (readOnly == null) {
		return false
	}
	if (_.isBoolean(readOnly)) {
		return readOnly
	}
	return readOnly(record)
}

export namespace GridCell {
	/** A static readonly cell showing some text - strings only */
	export const StaticString = <T extends Dict>(
		field: { data?: T[] } & Omit<
			GridField<T, string>,
			'onUpdate' | 'readOnly' | 'cell'
		> &
			object,
	): GridField<T, string> => ({
		..._.omit(field, 'data'),
		onUpdate: () => {},
		readOnly: true,
		hideIndicators: true,
		cell: {
			text: x => x,
			sortVal: x => x.toLowerCase(),
			className: () => 'static-text string',
			readOnly: true,
			cmpt: ({ value, searchText }) => (
				<span title={String(value ?? '')}>
					<TextSearched
						text={String(value ?? '')}
						needles={searchText ? searchText.split(/\s+/g) : null}
					/>
				</span>
			),
			validation: [],
		} as CellComponent<T, string>,
	})

	/** A readonly cell showing some text - numbers only */
	export const StaticNumber = <T extends Dict>(
		field: { data?: T[] } & Omit<
			GridField<T, Maybe<number>>,
			'onUpdate' | 'readOnly' | 'cell'
		> &
			object,
	): GridField<T, Maybe<number>> => ({
		..._.omit(field, 'data'),
		onUpdate: () => {},
		readOnly: true,
		sortVal: field.sortVal ?? field.value,
		hideIndicators: true,
		cell: {
			text: x => (x ? String(x) : ''),
			sortVal: x => x,
			className: () => 'static-text number',
			readOnly: true,
			cmpt: ({ value, searchText }) => (
				<span title={String(value ?? '')}>
					<TextSearched
						text={String(value ?? '')}
						needles={searchText ? searchText.split(/\s+/g) : null}
					/>
				</span>
			),
			validation: [],
		} as CellComponent<T, Maybe<number>>,
	})

	/** A readonly cell showing JSX based on the record */
	export const StaticJSX = <T extends Dict>(
		field: { data?: T[] } & Omit<
			GridField<T, undefined>,
			'onUpdate' | 'readOnly' | 'cell' | 'value'
		> & {
				cell: (input: {
					record: T
					ref: React.RefObject<Focusable<Element>>
					searchText: Maybe<string>
					onFocus: () => void
					onBlur: () => void
				}) => React.JSX.Element
			},
	): GridField<T, undefined> => ({
		..._.omit(field, 'data'),
		value: () => undefined,
		onUpdate: () => {},
		readOnly: true,
		sortVal: field.sortVal,
		validation: [],
		cell: {
			readOnly: true,
			validation: [],
			cmpt: x =>
				field.cell({
					record: x.record,
					ref: x.ref,
					searchText: x.searchText,
					onFocus: x.onFocus,
					onBlur: x.onBlur,
				}),
		} as CellComponent<T, undefined>,
	})

	/** A checkbox cell */
	export const Checkbox = <T extends Dict>(
		field: Omit<GridField<T, boolean>, 'cell'> & { data?: T[] },
	): GridField<T, boolean> => ({
		..._.omit(field, 'data'),
		cell: {
			text: x => (x ? 'Yes' : 'No'),
			sortVal: x => (x ? 1 : 0),
			className: () => 'checkbox',
			proxyValue: x => x,
			readOnly: typeof field?.readOnly === 'boolean' ? field?.readOnly : false,
			cmpt: ({ value, ref, readOnly, onUpdate, onFocus, onBlur }) => (
				<CheckboxInner
					ref={ref as React.RefObject<Focusable<HTMLInputElement>>}
					value={value}
					onUpdate={v => {
						onUpdate(v, true)
					}}
					onFocus={onFocus}
					onBlur={onBlur}
					readOnly={readOnly}
					lbl=""
				/>
			),
			validation: [],
		} as CellComponent<T, boolean>,
	})

	/** A basic text cell */
	export const Text = <T extends Dict, V extends Maybe<string> = string>(
		field: Omit<GridField<T, V>, 'cell'> & {
			data?: T[]
			cell?: {
				placeholder?: string
				minLength?: number
				maxLength?: number
				trim?: boolean
				sortVal?: (value: string) => any
				proxyValue?: (value: V) => V
				fixOnBlur?: (value: V) => V
				validations?: CellValidation[]
			}
		},
	): GridField<T, V> => ({
		..._.omit(field, 'data'),
		cell: {
			text: x => x,
			sortVal: field?.sortVal ?? (x => String(x ?? '').toLowerCase()),
			className: () => 'textbox',
			proxyValue: field.cell?.proxyValue ?? (x => x),
			readOnly: field?.readOnly,
			cmpt: ({
				value,
				ref,
				readOnly,
				onUpdate,
				onFocus,
				onBlur,
				onCommit,
				searchText,
			}) => (
				<>
					<div className="search-shadow">
						<TextSearched
							text={String(value ?? '')}
							needles={searchText ? searchText.split(/\s+/g) : null}
						/>
					</div>
					<Textbox
						ref={ref as React.RefObject<Focusable<HTMLInputElement>>}
						value={value ?? ''}
						onUpdate={v => {
							onUpdate(v as V)
						}}
						onFocus={onFocus}
						onKeyDown={e => {
							if (e.key == 'Enter') {
								e.preventDefault()
								onCommit()
							}
						}}
						onBlur={() => {
							let v = value
							if (field.cell?.fixOnBlur) {
								v = field.cell?.fixOnBlur(v)
							}
							if (field.cell?.trim) {
								v = (v?.trim() ?? null) as V
							}
							onUpdate(v, true)
							onBlur()
						}}
						placeholder={field.cell?.placeholder}
						readOnly={readOnly}
					/>
				</>
			),
			validation: [
				{
					req: (x: V) => (x?.length ?? 0) >= (field.cell?.minLength ?? 0),
					msg: `min length ${field.cell?.minLength ?? 0}`,
				},
				{
					req: (x: V) => (x?.length ?? 0) <= (field.cell?.maxLength ?? Inf),
					msg: `max length ${field.cell?.maxLength ?? Inf}`,
				},
				...(field.cell?.validations ?? []),
			],
		} as CellComponent<T, V>,
	})

	/** A basic text cell that only accepts numbers. Value from `onUpdate` will sometimes be a string mid-edit */
	export const Numeric = <T extends Dict>(
		field: Omit<GridField<T, Maybe<number | string>>, 'cell'> & {
			data?: T[]
			cell?: {
				placeholder?: string
				decimals?: number
				minLength?: number
				maxLength?: number
				proxyValue?: (value: Maybe<string>) => Maybe<string>
				fixOnBlur?: (value: string | number) => number
				validations?: CellValidation[]
			}
		},
	): GridField<T, Maybe<number | string>> => {
		const fixOnBlur = (v: Maybe<number | string>) => {
			if (!v || v === '') {
				return null
			}
			const nv = isNaN(+v) || v === '' ? '' : (+v).toFixed(field.cell?.decimals)
			if (field.cell?.fixOnBlur) {
				return field.cell.fixOnBlur(nv)
			}
			return isNaN(+nv) ? '' : +nv
		}
		return {
			..._.omit(field, 'data'),
			cell: {
				text: x => String(x ?? ''),
				sortVal: x => Number(x || 0),
				className: () => 'textbox',
				proxyValue: v => {
					const nv = String(v).replace(/[^0-9\.]/g, '')
					if (field.cell?.proxyValue) {
						return field.cell?.proxyValue(nv)
					}
					if (nv == '') {
						return null
					}
					return nv
				},
				readOnly: field?.readOnly,
				cmpt: ({
					value,
					ref,
					readOnly,
					onUpdate,
					onFocus,
					// onBlur,
					onCommit,
					searchText,
				}) => (
					<>
						<div className="search-shadow">
							<TextSearched
								text={String(value ?? '')}
								needles={searchText ? searchText.split(/\s+/g) : null}
							/>
						</div>
						<Textbox
							ref={ref as React.RefObject<Focusable<HTMLInputElement>>}
							value={String(value ?? '')}
							onUpdate={v => {
								onUpdate(String(v))
							}}
							onFocus={onFocus}
							onKeyDown={e => {
								if (e.key == 'Enter') {
									e.preventDefault()
									onCommit()
								}
							}}
							style={{ textAlign: 'right' }}
							onBlur={() => {
								let v = value
								if (fixOnBlur) {
									v = fixOnBlur(v ?? '')
								}
								if (v === '') {
									v = null
								}
								if (v && !isNaN(+v)) {
									v = (+v).toFixed(field.cell?.decimals ?? 0)
								}
								onUpdate(v, true)
								// onBlur()
							}}
							placeholder={field.cell?.placeholder}
							readOnly={readOnly}
						/>
					</>
				),
				validation: [
					{
						req: (x: Maybe<string>) =>
							(x?.length ?? 0) >= (field.cell?.minLength ?? 0),
						msg: `min length ${field.cell?.minLength ?? 0}`,
					},
					{
						req: (x: Maybe<string>) =>
							(x?.length ?? 0) <= (field.cell?.maxLength ?? Inf),
						msg: `max length ${field.cell?.maxLength ?? Inf}`,
					},
					...(field.cell?.validations ?? []),
				],
			} as CellComponent<T, Maybe<number | string>>,
		}
	}

	/** A dropdown cell with given options. Requires `getText` to fetch label from key */
	export const Combobox = <T extends Dict, V extends string | number>(
		field: Omit<GridField<T, V>, 'cell'> & {
			data?: T[]
			cell: {
				options: ComboBoxOptions<V> | ((record: T) => ComboBoxOptions<V>)
				getText: (value: V) => string
				nullable?: string
				placeholder?: string
			}
		},
	): GridField<T, V> => {
		// Wrap `getTextWrapped` to handle null values
		const getTextWrapped = (x: V): string => {
			if (x == null) {
				return field.cell?.nullable ?? ''
			}
			return field.cell?.getText(x)
		}
		// Return the cell component
		return {
			..._.omit(field, 'data'),
			cell: {
				text: x => getTextWrapped(x),
				sortVal: x => getTextWrapped(x).toLowerCase(),
				className: () => 'combobox',
				cmpt: ({
					record,
					value,
					ref,
					readOnly,
					onUpdate,
					onFocus,
					onBlur,
					searchText,
				}) => (
					<>
						<div className="search-shadow">
							<TextSearched
								text={String(getTextWrapped(value))}
								needles={searchText ? searchText.split(/\s+/g) : null}
							/>
						</div>
						<ComboboxInner
							disabled={readOnly}
							ref={ref as React.RefObject<Focusable<HTMLInputElement>>}
							value={value}
							onUpdate={v => {
								onUpdate(v, true)
							}}
							nullable={field.cell?.nullable}
							onFocus={onFocus}
							onBlur={onBlur}
							placeholder={field.cell?.placeholder}
							options={
								_.isFunction(field.cell?.options)
									? field.cell?.options(record)
									: field.cell?.options
							}
						/>
					</>
				),
				validation: [],
			} as CellComponent<T, V>,
		}
	}

	/** A dropdown cell with given options with multi-selection. Requires `getText` to fetch label from key */
	export const ComboboxMulti = <T extends Dict, V extends string | number>(
		field: Omit<GridField<T, V[]>, 'cell'> & {
			data?: T[]
			cell: {
				options: ComboBoxOptions<V>
				getText: (value: V) => string
				nullable?: string
				placeholder?: string
			}
		},
	): GridField<T, V[]> => {
		// Wrapping text fetcher to comma-separate user-given values
		const getText = (value: V[]): string =>
			value.map(x => field.cell?.getText(x)).join(', ')
		// Return component
		return {
			..._.omit(field, 'data'),
			cell: {
				text: x => getText(x),
				sortVal: x => getText(x).toLowerCase(),
				className: () => 'combobox',
				cmpt: ({ value, ref, onUpdate, onFocus, onBlur, searchText }) => (
					<>
						<div className="search-shadow">
							<TextSearched
								text={String(getText(value))}
								needles={searchText ? searchText.split(/\s+/g) : null}
							/>
						</div>
						<ComboboxInner
							ref={ref as React.RefObject<Focusable<HTMLInputElement>>}
							value={value}
							onUpdate={v => {
								onUpdate(v, true)
							}}
							onFocus={onFocus}
							onBlur={onBlur}
							nullable={field.cell?.nullable}
							multiple={true}
							placeholder={field.cell?.placeholder}
							options={field.cell?.options}
						/>
					</>
				),
				validation: [],
			} as CellComponent<T, V[]>,
		}
	}

	/** A basic date textbox - auto-converts from YYYY-MM-DD internally to DD/MM/YYYY */
	export const Date = <T extends Dict>(
		field: Omit<GridField<T, Maybe<string>>, 'cell'> & {
			data?: T[]
			cell?: {
				placeholder?: string
				sortVal?: (value: string) => any
				proxyValue?: (value: Maybe<string>) => Maybe<string>
				fixOnBlur?: (value: string) => string
				validations?: CellValidation[]
				format?: string
			}
		},
	): GridField<T, Maybe<string>> => ({
		..._.omit(field, 'data'),
		cell: {
			text: x => moment(x).format(field.cell?.format ?? 'DD/MM/YYYY') ?? '',
			sortVal: field.cell?.sortVal ?? (x => String(x ?? '').toLowerCase()),
			className: () => 'datebox',
			proxyValue: field.cell?.proxyValue ?? (x => x),
			readOnly: field?.readOnly,
			cmpt: ({
				value,
				ref,
				readOnly,
				onUpdate,
				onFocus,
				onBlur,
				onCommit,
				searchText,
			}) => (
				<>
					<div className="search-shadow">
						<TextSearched
							text={
								moment(value).format(
									field.cell?.format ?? 'DD/MM/YYYY',
								) ?? ''
							}
							needles={searchText ? searchText.split(/\s+/g) : null}
						/>
					</div>
					<Datebox
						ref={ref as React.RefObject<Focusable<HTMLInputElement>>}
						value={value ?? ''}
						onUpdate={v => {
							onUpdate(v, true)
						}}
						fmt={field.cell?.format ?? 'DD/MM/YYYY'}
						onFocus={onFocus}
						onKeyDown={e => {
							if (e.key == 'Enter') {
								e.preventDefault()
								onCommit()
							}
						}}
						onBlur={() => {
							let v = value
							if (field.cell?.fixOnBlur) {
								v = field.cell?.fixOnBlur(v ?? '')
							}
							onUpdate(v, true)
							onBlur()
						}}
						placeholder={field.cell?.placeholder}
						readOnly={readOnly}
					/>
				</>
			),
			validation: field.cell?.validations ?? [],
		} as CellComponent<T, Maybe<string>>,
	})

	/** A basic time textbox - auto-validates into hh:mm */
	export const TextTime = <T extends Dict>(
		field: Omit<GridField<T, Maybe<string>>, 'cell'> & {
			data?: T[]
			cell?: {
				nullable: boolean
				placeholder?: string
				sortVal?: (value: string) => any
				proxyValue?: (value: Maybe<string>) => Maybe<string>
				fixOnBlur?: (value: string) => string
			}
		},
	): GridField<T, Maybe<string>> =>
		Text({
			..._.omit(field, 'data'),
			cell: {
				minLength: 0,
				maxLength: 5,
				placeholder: field.cell?.placeholder,
				proxyValue: field.cell?.proxyValue ?? (x => x),
				fixOnBlur: v => {
					if (!v) {
						return null
					}
					return field.cell?.fixOnBlur
						? field.cell?.fixOnBlur(v)
						: validateTime(v) || null
				},
				sortVal: field.cell?.sortVal ?? (x => validateTime(x)),
				validations: _.compact([
					{
						req: (x: Maybe<string>) => !x || validateTime(x) != '',
						msg: 'Invalid time format',
					},
					!(field.cell?.nullable ?? false)
						? {
								req: (x: Maybe<string>) => Boolean(x && x != ''),
								msg: 'Time cannot be empty',
							}
						: undefined,
				]),
			},
		})

	// TODO - implement colour box
	export const Color = <T extends Dict>(
		field: Omit<GridField<T, string>, 'cell'> & {
			data?: T[]
			cell?: {
				colors: string[][]
			}
		},
	): GridField<T, string> => ({
		..._.omit(field, 'data'),
		cell: {
			className: () => 'color-picker',
			cmpt: ({ value, ref, readOnly, onUpdate, onFocus, onBlur }) => (
				<ColorPicker
					ref={ref as React.RefObject<Focusable<HTMLInputElement>>}
					value={value ?? ''}
					onUpdate={v => {
						onUpdate(v, true)
					}}
					readOnly={readOnly}
					onFocus={onFocus}
					onBlur={onBlur}
					colors={field.cell?.colors}
				/>
			),
			validation: [],
		} as CellComponent<T, string>,
	})

	// TODO - implement contact widget
	export const Contact = <T extends Dict>(
		field: Omit<GridField<T, ContactCard>, 'cell' | 'sortable'> & {
			data?: T[]
			cell: {
				updateDataModel: (dmodelContacts: ModelType<ContactCard>) => void
			}
		},
	): GridField<T, ContactCard> => ({
		..._.omit(field, 'data'),
		sortable: false,
		cell: {
			text: x =>
				x
					? _.compact([x.Email, x.Fax, x.Mobile, x.Phone, x.Website])
							.map(String)
							.join(', ')
					: '',
			className: () => 'contact-widget',
			readOnly: field?.readOnly,
			cmpt: ({ value, ref, readOnly, onUpdate, onFocus, onBlur }) => (
				<ContactWidgetHook
					ref={ref as React.RefObject<Focusable<HTMLInputElement>>}
					value={value ?? {}}
					onUpdate={(v, dmodelContacts) => {
						field.cell.updateDataModel(dmodelContacts)
						onUpdate(v, true)
					}}
					onFocus={onFocus}
					onBlur={onBlur}
					readOnly={readOnly}
				/>
			),
			validation: field?.validation ?? [],
		} as CellComponent<T, ContactCard>,
	})
}
