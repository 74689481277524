import { BuildClass, Do, Maybe, clamp, guid, timer } from '../../universal'
import { $, CryptoJS, React, _ } from '../lib'
import { IRIS } from './component-iris'
import { j2h } from './component-j2h'
import { J2rLoadingSpinner, j2r } from './component-react'
import { getNewFlyout, systemFlyout, to_page_unlock } from './flyouts-old'
import { moment } from './moment-wrapper'
import { Bindings } from './ui5'

// Uploadable
export const uploadableInit = obj => new UploadWidget(obj)

class UploadWidget {
	queue: any[]
	param: any
	coverCl: string
	startTime: moment.Moment
	$form: any

	constructor(obj) {
		this.queue = []
		this.param = this.normaliseData(obj)
		this.coverCl = 'file-drag-cover'
		this.addDropHandler()
	}

	normaliseData(obj) {
		if (obj == null) {
			obj = {}
		}
		if (obj.drop == null) {
			obj.drop = true
		}
		if (obj.args == null) {
			obj.args = () => ({})
		}
		if (obj.showForm == null) {
			obj.showForm = true
		}
		if (obj.maxSize == null) {
			obj.maxSize = 16
		}
		if (obj.simultaneous == null) {
			obj.simultaneous = 2
		}
		if (obj.pasteImage == null) {
			obj.pasteImage = false
		}
		if (obj.$dragContainer == null) {
			obj.$dragContainer = $('body')
		}
		if (obj.onComplete == null) {
			obj.onComplete = _.noop
		}
		if (obj.onFileComplete == null) {
			obj.onFileComplete = _.noop
		}
		if (obj.formSize == null) {
			obj.formSize = [640, 500]
		}
		if (obj.url == null) {
			obj.url = '/upload/'
		}
		return obj
	}

	addDropHandler() {
		// Skip if option not enabled
		if (!this.param.drop) {
			return
		}

		// Add a relative position so the blue cover will position correctly
		this.param.$dragContainer.css({ position: 'relative' })

		// Add a tab index if images are pasted in here
		if (this.param.pasteImage) {
			this.param.$dragContainer.attr({ tabindex: 1 })
			this.param.$dragContainer.on('paste', e => {
				const { items } = e.originalEvent.clipboardData
				const images = []
				_.forEach(items, item => {
					if (item.kind !== 'file') {
						return
					}
					const file = item.getAsFile()
					images.push(file)
				})
				this.cropAndUpload(images[0])
			})
		}

		// Suppression event
		this.param.$dragContainer.on(
			'drag dragstart dragend dragover dragenter dragleave drop',
			e => {
				if (e.originalEvent.dataTransfer?.files != null) {
					e.preventDefault()
					e.stopPropagation()
				}
			},
		)

		// Event for when the dragging enters the region
		this.param.$dragContainer.on('dragenter dragover', e => {
			// Skip if no files are found
			if (e.originalEvent.dataTransfer?.files == null) {
				return
			}

			// Skip if a cover already exists
			if (this.param.$dragContainer.children(`.${this.coverCl}`).length > 0) {
				return
			}

			// Create a cover - remove when mouse exists it
			const $cover = $(j2h({ class: this.coverCl }))
			this.param.$dragContainer.append($cover)
			$cover.on('dragleave dragend drop', () =>
				this.param.$dragContainer.children(`.${this.coverCl}`).remove(),
			)
		})

		// When dropped
		this.param.$dragContainer.on('drop', e =>
			_.forEach(e.originalEvent.dataTransfer?.files, f => {
				this.addToUploadQueue(f)
			}),
		)
	}

	openForm() {
		// Check if a form is already open
		if ($('.frmUpload').length > 0) {
			return
		}

		// Set what time the form opened
		this.startTime = moment()

		// Create the fly-out
		this.$form = systemFlyout({
			title: 'Upload',
			size: this.param.formSize,
		})
		const $pane = this.$form.find('.floater_body').empty().addClass('frmUpload')
		$pane.css({ height: 'calc(100% - 44px)' })
		this.$form.find('.flyout_buttons').remove()

		// Build the form
		$pane.append(
			j2h([
				// File input
				{
					tag: 'input',
					class: 'filesInput',
					attr: {
						name: 'file',
						type: 'file',
						multiple: null,
					},
					ev: {
						change: e => {
							// Add items to the queue
							const el = e.currentTarget
							_.forEach(el.files, f => {
								this.addToUploadQueue(f)
							})

							// Reset the form
							$(el).wrap('<form>').closest('form').get(0).reset()
							$(el).unwrap()
						},
					},
				},

				// Queue pane to show pending files
				{ class: 'pnQueue' },
			]),
		)

		// Remove the form if not wanted
		if (this.param.showForm === false) {
			return $pane.find('.filesInput').remove()
		}
	}

	addToUploadQueue(file) {
		// Remove any cover UI handler
		$(`.${this.coverCl}`).remove()

		// Open the form (if not already)
		this.openForm()

		// Create the file upload object
		const F = new UploadFile({
			file,
			url: this.param.url,
			args: this.param.args(),
			maxSize: this.param.maxSize,
			onRead: () => this.checkQueue(),
			onComplete: obj => {
				this.param.onFileComplete(obj)
				return this.checkQueue()
			},
		})

		// Append its element to the form
		$('.pnQueue').append(F.$el)

		// Add the object to the queue
		this.queue.push(F)
	}

	checkQueue() {
		// Get the set of files ready to upload
		// Get the number of files currently uploading
		const ready_to_upload = []
		let currently_uploading = 0
		_.forEach(this.queue, F => {
			// Skip if completed or not ready
			if (F.completed) {
				return
			}
			if (!F.ready) {
				return
			}

			// Increment uploading count
			if (F.uploading) {
				currently_uploading += 1

				// Add to the ready to upload
			} else {
				ready_to_upload.push(F)
			}
		})

		// Skip if everything is completed
		if (currently_uploading === 0 && ready_to_upload.length === 0) {
			const timeTaken = moment().diff(this.startTime)
			timer(800 - timeTaken, () => this.param.onComplete())
			return []
		}

		// If the number of files uploading is less than the max count, do more
		let index = 0
		return Do(() => {
			const result = []
			while (
				currently_uploading < this.param.simultaneous &&
				index < ready_to_upload.length
			) {
				const F = ready_to_upload[index]
				F?.upload()
				result.push((index += 1))
			}
			return result
		})
	}

	cropAndUpload(img) {
		new ImageEdit(img, file =>
			timer(600, () => {
				file.name = 'pasted.png'
				this.addToUploadQueue(file)
			}),
		)
	}
}

export class UploadFile {
	$el: any
	uploaderObject: () => Promise<void>
	file: any
	onRead: any
	onComplete: any
	maxSize: any
	args: any
	url: any
	filename: any
	ready: any
	uploading: any
	completed: any

	constructor(obj) {
		// Unpack input
		this.file = obj.file
		this.onRead = obj.onRead ?? _.noop
		this.onComplete = obj.onComplete ?? _.noop
		this.maxSize = obj.maxSize
		this.args = obj.args
		this.url = obj.url

		// Setup instance variables
		this.filename = this.file.name
		;[this.ready, this.uploading, this.completed] = [false, false, false]

		// Generate the DOM indicator for this upload
		this.$el = this.makeProgressBlock()

		// Create the uploader object - returns a function that uploads the file once run
		this.uploaderObject = uploadFileIris({
			fileObj: this.file,
			onProgress: (obj: { percentage: number }) => {
				if (isNaN(obj.percentage)) {
					return this.$el.find('.lbls .prog').text('Error!')
				}
				return this.$el.find('.progress').progressbar('value', obj.percentage)
			},
			onComplete: response => {
				this.$el.attr({ value: 100 })
				this.uploading = false
				this.completed = true
				this.onComplete(response)
			},
		})
		this.ready = true
		timer(() => this.onRead())
	}

	makeProgressBlock() {
		// Create new line in the queue
		const $el = $(
			j2h({
				class: 'queue-item',
				children: [
					{
						class: 'lbls pending',
						children: [
							{ tag: 'span', class: 'file', text: this.filename },
							{ tag: 'span', class: 'prog', text: '...' },
							{
								tag: 'span',
								class: 'size',
								text: Do(() => {
									const kb = this.file.size / 1024
									return `${kb.toFixed(1)} KB`
								}),
							},
						],
					},
					{ class: 'progress' },
				],
			}),
		)
		const $prog = $el.find('.progress')

		// Create the progress bar
		$prog.progressbar({
			// Update the percentage indiciator
			change: () =>
				$el.find('.prog').text(
					Do(() => {
						const val = +$prog.progressbar('value')
						if (val !== 100) {
							$el.find('.lbls').removeClass('done')
							$el.removeClass('done')
						}
						return `${val.toFixed(1)}%`
					}),
				),
			// Mark as complete
			complete: () => {
				$el.find('.lbls').addClass('done')
				$el.addClass('done')
				return $el.find('.prog').text('100%')
			},
		})

		// Add to the form
		return $el
	}

	async upload() {
		this.uploading = true
		return this.uploaderObject()
	}
}

class ImageEdit {
	file: any
	callback: any
	img: HTMLImageElement
	b64: any
	dimensions: number[]
	multiplier: number
	scaledDimensions: number[]
	$F: any
	$selector: JQuery<any>

	constructor(img, cb) {
		Bindings(this, [this.updateCropPreview])
		this.file = img
		this.callback = cb
		this.img = new Image()
		this.b64 = undefined
		this.dimensions = [0, 0]
		this.multiplier = 1
		this.imgRead()
	}

	imgRead() {
		const reader = new FileReader()
		reader.onload = e => {
			this.b64 = e.target.result
			this.img.onload = () => {
				this.dimensions = [this.img.width, this.img.height]
				timer(() => {
					this.showWidget()
				})
			}
			this.img.src = this.b64
		}
		reader.readAsDataURL(this.file)
	}

	updateMultiplier(w, h) {
		// Scale large images based on screen size
		const [max_w, max_h] = [window.innerWidth - 50, window.innerHeight - 50]
		this.multiplier = Math.min(1, max_w / w, max_h / h)
		this.scaledDimensions = [w * this.multiplier, h * this.multiplier]
	}

	buildFlyout() {
		const $flyout = getNewFlyout('', this.scaledDimensions)
		this.$F = $flyout.closest('.to_floater')
		this.$F.addClass('frmImageEdit')
		this.$F.children().remove()
	}

	showWidget() {
		// Get the dimensions
		const [w, h] = this.dimensions
		this.updateMultiplier(w, h)
		const [sw, sh] = this.scaledDimensions
		this.buildFlyout()

		// Build the flyout with the image inside
		// flyoutUpdateSize(@$F, sw, sh)
		this.$F.empty()
		this.$F.css({ padding: 0 })
		this.$F.append(
			j2h({
				tag: 'img',
				attr: {
					src: this.b64,
					style: `width:${sw}px;height:${sh}px;`,
				},
			}),
		)

		// Overlay the crop selector
		this.$selector = $(
			j2h({
				class: 'cropSelector',
				attr: { tabindex: 1 },
			}),
		)
		this.$selector.draggable({
			containment: 'parent',
			drag: this.updateCropPreview,
		})
		this.$selector.resizable({
			containment: 'parent',
			handles: 'n, e, s, w, ne, se, sw, nw',
			resize: this.updateCropPreview,
		})
		this.$F.append(this.$selector)

		// Create the stub margins
		_.times(4, () => this.$F.append(j2h({ class: 'black' })))

		// Initial update of the selector
		timer(600, () => {
			this.updateCropPreview()
		})

		// When the enter key is pressed while the selection is focused, it will crop
		this.$selector.focus().on('keypress', e => {
			if (e.which === 13) {
				this.crop()
			}
		})
	}

	updateCropPreview() {
		// Get the two geos
		const [frame, select] = this.getSelectionFrameGeos()

		// Determine the size of each margin
		const margins = {
			north: select.y - frame.y,
			east: frame.x + frame.w - (select.x + select.w),
			south: frame.y + frame.h - (select.y + select.h),
			west: select.x - frame.x,
		}

		// Create the geo for the 4 strips to surround the selection
		const lhs = {
			x: 0,
			y: 0,
			w: margins.west,
			h: frame.h,
		}
		const rhs = {
			x: margins.west + select.w,
			y: 0,
			w: margins.east,
			h: frame.h,
		}
		const ths = {
			x: margins.west,
			y: 0,
			w: select.w,
			h: margins.north,
		}
		const bhs = {
			x: margins.west,
			y: margins.north + select.h,
			w: select.w,
			h: margins.south,
		}

		// Helper function to create a strip based on this size
		const getStrip = geo =>
			[
				`left: ${geo.x}px`,
				`top: ${geo.y}px`,
				`width: ${geo.w}px`,
				`height: ${geo.h}px`,
			].join(';')

		// Apply the styles to the margin elements
		const $els = this.$F.children('.black')
		;[lhs, rhs, ths, bhs].forEach((geo, index) =>
			$els.eq(index).attr({ style: getStrip(geo) }),
		)
	}

	getSelectionFrameGeos() {
		// Get the geo of the wrapping frame
		const FPOS = this.$F.position()
		const frame = {
			x: FPOS.left,
			y: FPOS.top,
			w: this.$F.width(),
			h: this.$F.height(),
		}

		// Get the geo of the selection
		const SPOS = this.$selector.position()
		const select = {
			x: SPOS.left + frame.x,
			y: SPOS.top + frame.y,
			w: this.$selector.width(),
			h: this.$selector.height(),
		}

		// Return the two
		return [frame, select]
	}

	crop() {
		// Inverse multiplier
		const invMul = 1 / this.multiplier

		// Get the two geos
		const [frame, select] = this.getSelectionFrameGeos()

		// Get the coordinate set for the crop - adjust for multiplier
		const C = {
			x: Math.round((select.x - frame.x) * invMul),
			y: Math.round((select.y - frame.y) * invMul),
			w: Math.round(select.w * invMul),
			h: Math.round(select.h * invMul),
		}

		// Create a canvas with the dimensions of the new image
		const canvas = j2h({ tag: 'canvas' })
		canvas.width = C.w
		canvas.height = C.h

		// Draw the image to the canvas
		const context = canvas.getContext('2d')
		context.drawImage(this.img, C.x, C.y, C.w, C.h, 0, 0, C.w, C.h)

		// Convert the canvas to an image file blob
		return canvas.toBlob(blob => {
			to_page_unlock()
			return this.callback(blob)
		})
	}
}

// IRIS-style upload function #

export var uploadFileIris = ({
	fileObj,
	onStart = _.noop,
	onProgress = _.noop,
	onComplete = _.noop,
}: {
	fileObj: File
	onStart?: Function
	onProgress?: Function
	onComplete?: (resp: { file: File; checksum: Maybe<string> }) => void
}) => {
	// Magic numbers
	const MIN_PACKET_SIZE = 2 * 1024 // 2 KB
	const MAX_PACKET_SIZE = 32 * 1024 // 32 KB
	const PARALLEL_COUNT = 4

	// Validate the input data
	const fileID = guid().replace(/-/g, '').substring(0, 8)

	// console.log {fileID, fileObj, fileName, args, onProgress, onComplete}

	// Wrap the user events
	let isComplete = false
	const onProgressInner = obj => {
		obj = _.assign({}, obj, { file: fileObj })
		obj.percentage = isComplete ? 100 : obj.percentage
		return onProgress(obj)
	}
	const onCompleteInner = checksum => {
		isComplete = true
		onComplete({
			file: fileObj,
			checksum: checksum,
		})
	}

	// Get the checksum of the file
	const getChecksumLocal = new Promise((resolve, reject) => {
		// Get the MD5 checksum of the file so we can predict the link
		const reader = new FileReader()
		reader.onerror = e => {
			reject(e)
		}
		reader.onloadend = () => {
			const wordArray = CryptoJS.lib.WordArray.create(
				reader.result as unknown as number[],
			)
			const hash = CryptoJS.MD5(wordArray).toString()
			resolve(hash.toString())
		}
		reader.readAsArrayBuffer(fileObj)
	})

	// Promise - read file contents as base64
	const getBase64Contents = async () =>
		new Promise<string>((resolve, reject) => {
			const reader = new FileReader()
			reader.onloadend = () => {
				resolve(String(reader.result).split(',')[1])
			}
			;(reader as any).onerror = e => {
				reject(e)
			}
			reader.readAsDataURL(fileObj)
		})

	// Fetches the packets to upload
	const getUploadablePackets = async () =>
		new Promise<
			{
				uploaded: boolean
				fileID: string
				pktIndex: number
				pktCount: number
				contentType: string
				data: string
			}[]
		>(async (resolve, reject) =>
			getBase64Contents()
				.then(b64String => {
					// No file data? instant completion
					if (b64String == null) {
						onProgressInner(100)
						onCompleteInner(null)
						return
					}

					// Get the packet size - either 100 packets or the minimum
					let packet_size = Math.ceil(b64String.length / 200)
					packet_size = clamp(packet_size, MIN_PACKET_SIZE, MAX_PACKET_SIZE)

					// Split the string into chunks of max size `PACKET_SIZE`
					const starts = _.range(0, b64String.length, packet_size)
					const packets = starts.map(start => {
						const end = Math.min(start + packet_size, b64String.length)
						return b64String.substring(start, end)
					})

					// Wrap each packet in metadata
					resolve(
						packets.map((blob, index) => ({
							uploaded: false,
							fileID,
							pktIndex: index + 1,
							pktCount: packets.length,
							contentType: fileObj.type,
							data: blob,
						})),
					)
				})
				.catch(reject),
		)

	// Function that returns a promise to send a given packet
	const sendPacket = async packet =>
		// console.log "Sent #{packet.pktIndex}/#{packet.pktCount}"
		new Promise((resolve, reject) => {
			IRIS.Send({
				data: {
					progID: 0,
					funcID: 26,
					fileID: packet.fileID,
					pktIndex: packet.pktIndex,
					pktCount: packet.pktCount,
					contentType: packet.contentType,
					__data: packet.data,
				},

				// Handle errors
				no: data => {
					console.error(data)
					reject(data)
				},

				// Handle packet response
				yes: data => {
					// console.log "Received #{packet.pktIndex}/#{packet.pktCount}"
					if (data.uploadFinished) {
						data.uploadProgress = 100
					}
					onProgressInner({
						percentage: data.uploadProgress,
						found: data.packetsFound,
						needed: data.packetsNeeded,
					})
					if (data.uploadFinished) {
						onCompleteInner(data.fileHash)
					}
					resolve(data)
				},
			})
		})

	// Helper function to execute promises sequentially
	const sequencePromises = async (promise, packet) =>
		new Promise(resolve => {
			resolve(promise.then(async () => sendPacket(packet)))
		})

	// Return the function to run when uploading is to commence
	// Ensure that we have a checksum
	return async () =>
		getChecksumLocal
			.then(async checksum => {
				// Start event fires when we have a checksum
				onStart(checksum)

				// Start uploading
				return getUploadablePackets()
			})
			.then(packets => {
				// Divide the packets into four groups
				const groups = _.range(PARALLEL_COUNT).map(() => [])
				packets.forEach((P, i) => {
					groups[i % PARALLEL_COUNT].push(P)
				})

				// Run the promises
				groups.forEach(G => {
					G.reduce(sequencePromises, Promise.resolve())
				})
			})
			.catch(e => {
				console.error(e)
			})
}

// REACT COMPONENT #

export class UploadFileContainer extends React.Component<
	{
		cl?: string
		inner?: any[]
		height?: number
		onUpload: (
			responses: {
				file: File
				checksum: string
			}[],
			cb: (success?: boolean | string) => void,
		) => void
		dragText?: string
		dropText?: string
		subtitle?: string
	},
	{
		isHovering: number
		isUploading: boolean
		isRegistering: boolean
		progress: any
	}
> {
	rootNode: React.RefObject<HTMLDivElement>
	uploaders: any
	inputRef: React.RefObject<HTMLInputElement>

	constructor(props) {
		super(props)
		Bindings(this, [
			this._onClick,
			this._onFileSelected,
			this._onDragEnter,
			this._onDragLeave,
			this._onDragOver,
			this._onDrop,
			this._onPaste,
		])
		this.rootNode = React.createRef()
		this.inputRef = React.createRef()
		this.state = {
			isHovering: 0,
			isUploading: false,
			isRegistering: false,
			progress: null,
		}
	}

	override componentDidMount() {
		const el = this.rootNode.current
		el.addEventListener('mouseup', this._onDragLeave)
		el.addEventListener('dragenter', this._onDragEnter)
		el.addEventListener('dragover', this._onDragOver)
		el.addEventListener('dragleave', this._onDragLeave)
		el.addEventListener('drop', this._onDrop)
		el.addEventListener('paste', this._onPaste)
		el.addEventListener('click', this._onClick)
	}

	override render() {
		return j2r({
			ref: this.rootNode,
			cl: BuildClass({
				[this.props.cl]: true,
				'drag-drop-container': true,
			}),
			style: { height: `${this.props.height}px` },
			children: Do(() => {
				// If it's processing an upload, just show spinning circles with
				// an "uploading" message
				if (this.state.isUploading) {
					return [
						{
							cl: 'uploading-progress',
							key: 'uploading-progress',
							children: [
								{
									key: 'msg',
									cl: 'msg',
									text: Do(() => {
										if (this.state.isRegistering) {
											return 'Registering...'
										} else if (this.state.progress != null) {
											const avg =
												_.sum(this.state.progress) /
												this.state.progress.length
											return `${Math.round(avg)}%`
										}
										return 'Starting upload...'
									}),
								},
								{
									tag: J2rLoadingSpinner,
									key: 'spinner',
									size: 'large',
								},
							],
						},
					]
				}

				// Usual children - a mouse-catcher and pass-through of the contents
				return _.flatten([
					{
						key: 'mc',
						cl: 'mouse-catcher',
						children: [
							{
								key: 'mc-inner',
								cl: BuildClass({
									'mouse-catcher-inner': true,
									hovering: this.state.isHovering > 0,
								}),
							},
						],
					},
					this.buildCustomContent(),
				])
			}),
		})
	}

	buildCustomContent() {
		// Check if custom content was supplied
		if (this.props.inner != null) {
			return this.props.inner
		}

		// Otherwise fallback to the default "drop here to upload" thing
		return {
			cl: BuildClass({
				'upload-component-stub': true,
				hovering: Boolean(this.state.isHovering),
			}),
			key: 'stub-upload-indicator',
			children: [
				<input
					key="file"
					type="file"
					id="fileUpload"
					style={{ display: 'none' }}
					ref={this.inputRef}
					onChange={this._onFileSelected}
				/>,
				{
					tag: 'span',
					key: 'text',
					text: Do(() => {
						if (this.state.isHovering) {
							return this.props.dropText ?? 'Drop to upload'
						}
						return this.props.dragText ?? 'Click or drag files here'
					}),
				},
				{
					tag: 'span',
					key: 'subtitle',
					cl: 'subtitle',
					text: this.props.subtitle ?? '',
				},
			],
		}
	}

	_onClick(_e) {
		this.inputRef.current?.click()
	}

	_onFileSelected(e) {
		const files = e.target.files
		if (files != null) {
			timer(() => {
				this.uploadFiles(_.range(files.length).map(x => files.item(x)))
			})
		}
		return false
	}

	_onDragEnter(e) {
		this.setState({ isHovering: Math.max(0, this.state.isHovering + 1) })
		e.stopPropagation()
		e.preventDefault()
		return false
	}

	_onDragOver(e) {
		e.preventDefault()
		e.stopPropagation()
		return false
	}

	_onDragLeave(e) {
		this.setState({ isHovering: Math.max(0, this.state.isHovering - 1) })
		e.stopPropagation()
		e.preventDefault()
		return false
	}

	_onDrop(e) {
		e.preventDefault()
		this.setState({ isHovering: 0 })
		const files = e.dataTransfer?.files
		if (files != null) {
			timer(() => {
				this.uploadFiles(_.range(files.length).map(x => files.item(x)))
			})
		}
		// No event
		return false
	}

	_onPaste(e) {
		const images = []
		_.forEach(e.clipboardData.items, item => {
			if (item.kind !== 'file') {
				return
			}
			const file = item.getAsFile()
			images.push(file)
		})
		if (images.length > 0) {
			this.cropAndUpload(images[0])
		}
	}

	cropAndUpload(img) {
		new ImageEdit(img, file =>
			timer(600, () => {
				file.name = 'pasted.png'
				this.uploadFiles([file])
			}),
		)
	}

	uploadFiles(files: File[]) {
		// Disallow if already uploading and validate input
		if (this.state.isUploading || files == null || !files.length) {
			return
		}

		// Set the initial progress counters and containers for responses
		this.setState({ progress: _.map(files, () => null) })
		const responses: Maybe<{ file: File; checksum: string }>[] = _.map(
			files,
			() => null,
		)

		// Get the uploader objects
		let required_to_finish = files.length
		this.uploaders = files.map((file, index) =>
			uploadFileIris({
				fileObj: file,
				onProgress: e => {
					this.setState(s => ({
						progress: s.progress.map((p, i) => {
							if (i === index) {
								return e.percentage
							}
							return p
						}),
					}))
				},
				onComplete: e => {
					console.log(`Completed: ${e.checksum}`)
					responses[index] = e
					required_to_finish -= 1
					if (required_to_finish === 0) {
						const cb = () => {
							this.setState({
								isUploading: false,
								isRegistering: false,
							})
						}
						this.setState({ isRegistering: true })
						this.props.onUpload(responses, cb)
					}
				},
			}),
		)

		// Run the uploads
		this.setState({ isUploading: true })
		this.uploaders.forEach(x => x())
	}
}
