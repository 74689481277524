import { BuildClass, Do, Maybe } from '../../../universal'
import { React } from '../../lib'
import { Alerts } from '../component-main'
import { reactRender, unmountReactOnElement } from '../component-react'
import { svrtsRequest } from '../svrts-interface'
import { Button } from './buttons'
import { LoadingSpinnerSmall } from './loading'
import { CJSX } from './meta-types'
import { Modal } from './modal'
import { stubCanvas } from './stubs'
import { Textarea } from './textbox'

// Theming options
const theme = {
	MainText: 'text-slate-900',
	TextboxBG: 'bg-neutral-100',
	TextboxBorder: 'border-neutral-100',
	TextboxBorderFocus: 'focus:border-neutral-100',
	SendButtonBG: 'bg-blue-700',
	SendButtonHoverBG: 'hover:bg-blue-800',
	RecordButtonBG: 'bg-red-500',
	RecordButtonHoverBG: 'hover:bg-red-600',
	TextboxBGRGB: '#ffffff',
	TextboxWaveformStroke: '#171717',
}

// Globals
const SECONDS_TO_SHOW_WAVE = 1
const AUDIO_FFT_SIZE = 2048
const EXP_SCALE = 0.5 // square root

// Types
type audioState = {
	audioChunks: BlobPart[]
	mediaRec: MediaRecorder | null
	analyser: AnalyserNode | null
}

type globalVoiceRecordingProps = {
	/**
	 * Whether to show the transcribed text in an editable box before sending.
	 * If not editing, the response text is returned immediately once transcription completes.
	 */
	editBeforeSending: boolean
	/** Passed to Whisper to give a hint about what kind of content is in the voice clip */
	prompt: Maybe<string>
	/** Draws a box around this element while processing */
	originatingFormElement: HTMLElement
	/**
	 * Function to run when the text is transcribed.
	 * The transcription box won't disappear until callback(true) is passed
	 */
	onTranscribed: (text: string, callback: (result: true | string) => void) => void
}
export const startGlobalVoiceRecording = (input: globalVoiceRecordingProps): void => {
	// Create the container for the component
	const el = document.createElement('div')
	el.id = 'global-voice-wrapper'
	document.body.appendChild(el)
	reactRender(el, <GlobalVoiceRecording {...input} container={el} />).catch(err => {
		console.error(err)
	})
}

const focusOriginalOrSubmitButton = (originatingFormElement: HTMLElement): void => {
	const submitButton: HTMLButtonElement = originatingFormElement.querySelector(
		'button[type="submit"]',
	)
	if (submitButton) {
		submitButton.focus()
		return
	}
	originatingFormElement.focus()
}

const GlobalVoiceRecording = (
	props: globalVoiceRecordingProps & { container: HTMLDivElement },
) => {
	// All of this is just to make it so that we can remove the element properly once we're done
	const [mountCount, setMountCount] = React.useState(0)
	const [hasCancelled, setHasCancelled] = React.useState(false)
	const [isRendering, setIsRendering] = React.useState(true)
	const removeSelf = () => {
		focusOriginalOrSubmitButton(props.originatingFormElement)
		setIsRendering(false)
	}
	React.useEffect(() => {
		if (!isRendering) {
			unmountReactOnElement(props.container)
			requestAnimationFrame(() => {
				props.container.remove()
			})
		}
	}, [props.container, isRendering])
	React.useEffect(() => {
		if (hasCancelled && mountCount == 0) {
			removeSelf()
		}
	}, [hasCancelled, mountCount])

	// Manage the state
	const [transcribing, setTranscribing] = React.useState(false)
	const [text, setText] = React.useState<Maybe<string>>(null)
	const [sending, setSending] = React.useState(false)

	// Event to send the transcribed text to be processed by the original caller
	const sendResult = (txt: string) => {
		setSending(true)
		if (!isRendering) {
			return
		}
		props.onTranscribed(txt, result => {
			if (!isRendering) {
				return
			}
			if (result !== true) {
				Alerts.Alert({ msg: result })
			}
			removeSelf()
		})
	}

	// Get the originating form's location on screen so we can draw a box around it
	const formRect = props.originatingFormElement.getBoundingClientRect()

	// Render the component
	return (
		isRendering && (
			<Modal>
				<div
					className="tailwind-wrapper"
					style={{
						height: '100%',
						width: '100%',
						background: 'rgba(0,0,0,0.1)',
					}}
				>
					<div
						className={BuildClass({
							'relative  z-20': true,
							'bg-white border-neutral-600': true,
							'max-w-[500px] mx-auto my-2 p-1 rounded-md': true,
							'shadow-md drop-shadow-md': true,
						})}
					>
						<VoiceRecorder
							autoStart={true}
							prompt={props.prompt ?? null}
							onSend={v => {
								setTranscribing(false)
								setText(v)
								if (!props.editBeforeSending) {
									sendResult(v ?? '')
								}
							}}
							onTranscribingStart={() => {
								setTranscribing(true)
							}}
							onMount={() => {
								setMountCount(v => v + 1)
							}}
							onCancel={() => {
								setMountCount(v => v - 1)
								setHasCancelled(true)
							}}
						/>
						{transcribing && (
							<div className="m1 text-center py-1">
								Transcribing... <LoadingSpinnerSmall />{' '}
								<a
									className="cursor text-blue-600"
									onClick={e => {
										e.preventDefault()
										removeSelf()
									}}
								>
									[cancel]
								</a>
							</div>
						)}
						{!transcribing && text != null && (
							<>
								<Textarea
									value={text}
									onUpdate={setText}
									autoExpand={true}
									placeholder="Transcribed text goes here..."
									className="w-full mt-1"
								/>
								{sending ? (
									<div className="m1 text-center py-1">
										Processing... <LoadingSpinnerSmall />{' '}
										<a
											className="cursor text-blue-600"
											onClick={e => {
												e.preventDefault()
												removeSelf()
											}}
										>
											[cancel]
										</a>
									</div>
								) : (
									<Button
										type="submit"
										className="w-[calc(100%-8px)] m-1"
										lbl="Submit"
										onClick={() => {
											sendResult(text ?? '')
										}}
									/>
								)}
							</>
						)}
					</div>
					<div
						className="border-dashed border-4 border-red-600 fixed z-10"
						style={{
							top: `${formRect.top - 2}px`,
							left: `${formRect.left - 2}px`,
							width: `${formRect.width + 4}px`,
							height: `${formRect.height + 4}px`,
						}}
					/>
				</div>
			</Modal>
		)
	)
}

export const VoiceRecorder = (props: {
	className?: string
	autoStart?: boolean
	prompt: string | null
	onSend: (text: string | null) => void
	onTranscribingStart?: () => void
	onMount?: () => void
	onCancel?: () => void
}): React.JSX.Element => {
	// DOM references
	const elWaveform = React.useRef<HTMLCanvasElement | null>(null)

	// Form state
	const [isLoading, setIsLoading] = React.useState(false)

	// Hold the audio state as a ref so it never caches old values in a closure
	const audioState = React.useRef<audioState>({
		audioChunks: [],
		mediaRec: null,
		analyser: null,
	})
	const [, setAudioStateCounter] = React.useState(0)

	// Event for starting recording
	const startRecording = React.useCallback(async () => {
		await startRecordingWrapper({
			audioState: audioState.current,
			elWaveform: elWaveform.current ?? stubCanvas,
		})
		audioState.current.audioChunks = []
		setAudioStateCounter(s => s + 1)
	}, [audioState, setAudioStateCounter, elWaveform])

	// Event when recording stops - might send or might discard
	const stopRecording = React.useCallback(
		(send: boolean) => {
			stopRecordingWrapper({
				audioState: audioState.current,
				onUpdate: voice => {
					props.onTranscribingStart?.()
					sendTranscriptionRequest({
						voice: voice,
						prompt: props.prompt,
						setIsLoading: setIsLoading,
						onComplete: text => {
							props.onSend(text)
						},
					}).catch(err => {
						console.error(err)
					})
				},
				send: send,
			})

			// Update state
			audioState.current.mediaRec = null
			setAudioStateCounter(s => s + 1)

			// Send cancel signal if not sending
			if (!send) {
				requestAnimationFrame(() => {
					props.onCancel?.()
				})
			}
		},
		[props, audioState, setAudioStateCounter],
	)

	// Add global keyboard bindings for press-to-record for the right ctrl key
	React.useEffect(() => {
		defineGlobalKeybinds(startRecording, stopRecording)
	}, [startRecording, stopRecording])

	// If auto-starting, do that
	React.useEffect(() => {
		props.onMount?.()
		if (!props.autoStart) {
			return () => {}
		}
		const prm = startRecording().catch(err => {
			console.error(err)
		})
		return () => {
			prm.then(() => {
				stopRecording(false)
			}).catch(err => {
				console.error(err)
			})
		}
	}, [])

	// Render
	return (
		<div
			className={BuildClass({
				'tailwind-wrapper': true,
				[props.className ?? '']: true,
			})}
		>
			<div className="w-full z-10 flex shadow-md">
				{/* Waveform preview */}
				<div
					className={BuildClass({
						'flex-grow box-border border rounded-l-md border-r-0': true,
						[theme.TextboxBG]: true,
						[theme.TextboxBorder]: true,
					})}
				>
					<canvas
						ref={elWaveform}
						className={BuildClass({
							'w-full h-10': true,
							hidden: isLoading || !audioState.current.mediaRec,
						})}
					/>
				</div>

				{/* Button to start recording */}
				<CJSX cond={!audioState.current.mediaRec}>
					<button
						className={BuildClass({
							'px-4 py-2 rounded-r-md': true,
							[theme.MainText]: true,
							[theme.SendButtonBG]: true,
							[theme.SendButtonHoverBG]: true,
						})}
						title="Click to record a voice message to send, instead of typing"
						onClick={() => {
							startRecording().catch(err => {
								console.error(err)
							})
						}}
					>
						<img
							alt="Voice Message"
							src="/static/img/i8/ios16-microphone.svg"
							className="w-6 h-6 invert"
						/>
					</button>
				</CJSX>

				{/* Cancel / Send buttons */}
				<CJSX cond={Boolean(audioState.current.mediaRec)}>
					<Button
						type="ui5-borderless-24"
						img="/static/img/i8/ios16-cross.svg"
						style={{ padding: '0 4px' }}
						className={`px-2 py-2 ${theme.MainText} ${theme.SendButtonBG} ${theme.SendButtonHoverBG}`}
						title="Click to cancel your voice recording without sending"
						onClick={() => {
							stopRecording(false)
						}}
					/>
					<Button
						type="ui5-borderless-24"
						img="/static/img/i8/ios16-tick.svg"
						autoFocus={true}
						style={{ padding: '0 4px' }}
						className={`px-2 py-2 ${theme.MainText} rounded-r-md ${theme.RecordButtonBG} ${theme.RecordButtonHoverBG}`}
						title="Click to send your voice recording"
						onClick={() => {
							stopRecording(true)
						}}
					/>
				</CJSX>
			</div>
		</div>
	)
}

// Function to run when asking the server to transcribe
const sendTranscriptionRequest = async (input: {
	voice: Blob
	prompt: string | null
	setIsLoading: (isLoading: boolean) => void
	onComplete: (text: string) => void
}) => {
	input.setIsLoading(true)
	const audioBuffer = await input.voice.arrayBuffer()
	const audioBase64 = Buffer.from(audioBuffer).toString('base64')
	svrtsRequest({
		data: {
			funcID: [0, 51],
			audio: audioBase64,
			prompt: input.prompt,
		},
		any: () => {
			input.setIsLoading(false)
		},
		no: data => {
			Alerts.Alert({ msg: data.message })
		},
		yes: data => {
			input.onComplete(data.text)
		},
	})
}

// Function to run when recording starts
const startRecordingWrapper = async (input: {
	audioState: audioState
	elWaveform: HTMLCanvasElement
}) => {
	// Get the recorder
	const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
	const recorder = new MediaRecorder(stream, {
		audioBitsPerSecond: 64000,
		mimeType: 'audio/webm',
	})
	input.audioState.mediaRec = recorder

	// Create an audio analyser node and connect to a media source
	const audioContext = new window.AudioContext()
	const analyser = audioContext.createAnalyser()
	analyser.fftSize = AUDIO_FFT_SIZE
	const source = audioContext.createMediaStreamSource(stream)
	source.connect(analyser)
	input.audioState.analyser = analyser

	// Create a buffer for the data and a buffer for the history
	const bufferLength = analyser.fftSize
	const dataArray = new Uint8Array(bufferLength)
	const canvas = input.elWaveform
	const historySize = audioContext.sampleRate * SECONDS_TO_SHOW_WAVE
	const historyBuffer = new Uint8Array(historySize).fill(128)

	// Create a canvas context for drawing
	const canvasContext = canvas?.getContext('2d')
	if (canvas && canvasContext) {
		// Function to draw the waveform
		const draw = () => {
			// Get the data
			analyser.getByteTimeDomainData(dataArray)

			// Add the new data to the history
			historyBuffer.set(dataArray, historyBuffer.length - bufferLength)
			historyBuffer.copyWithin(0, bufferLength)

			// Clear the canvas
			canvasContext.fillStyle = theme.TextboxBGRGB
			canvasContext.fillRect(0, 0, canvas.width, canvas.height)

			// Set the stroke
			canvasContext.lineWidth = 1
			canvasContext.strokeStyle = theme.TextboxWaveformStroke
			canvasContext.beginPath()

			// Draw the waveform
			let x = 0
			const sliceWidth = (canvas.width * 1.0) / historySize
			// eslint-disable-next-line no-loops/no-loops
			for (let i = 0; i < historySize; i++) {
				// Get the y value
				// Apply a square root function to the amplitude
				const v1 = historyBuffer[i] ?? 128 // domain: 0 to 256
				const v2 = v1 / 128.0 - 1 // domain: -1 to 1
				const vScaled = Math.abs(v2) ** EXP_SCALE * Math.sign(v2)
				const y = ((vScaled + 1) * canvas.height) / 2

				// Draw the line
				if (i === 0) {
					canvasContext.moveTo(x, y)
				} else {
					canvasContext.lineTo(x, y)
				}

				// Shift x coordinate for next iteration
				x += sliceWidth
			}

			// Draw the line to the right edge
			canvasContext.lineTo(canvas.width, canvas.height / 2)
			canvasContext.stroke()

			// Call this function again the next time the browser is ready to draw
			if (input.audioState.mediaRec) {
				requestAnimationFrame(draw)
			}
		}
		draw()
	}

	// Add the event to add chunks to the array
	recorder.addEventListener('dataavailable', event => {
		input.audioState.audioChunks.push(event.data)
	})

	// Start the recording
	recorder.start()
}

// Function to run when recording stops
const stopRecordingWrapper = (input: {
	send: boolean
	audioState: audioState
	onUpdate: (voice: Blob) => void
}) => {
	// Stop recording
	const mediaRec = input.audioState.mediaRec
	if (!mediaRec) {
		return
	}

	// If sending, add the event for when it stops
	// Send audio data as a POST request
	if (input.send) {
		mediaRec.addEventListener('stop', () => {
			Do(async () => {
				// Check if there are any chunks
				if (input.audioState.audioChunks.length == 0) {
					console.error('No audio chunks available')
					return
				}

				// Get the audio duration (async) and send based on length
				const duration = await getAudioDuration(input.audioState.audioChunks)
				// Must be > 1 second
				if (duration < 1) {
					console.log('Skipping sending voice clip - length < 1: ', duration)
					input.audioState.audioChunks = []
					return
				}

				// Send the message
				const audio_blob = new Blob(input.audioState.audioChunks)
				input.onUpdate(audio_blob)
				input.audioState.audioChunks = []
			}).catch(err => {
				console.error(err)
			})
		})
	}

	// Stop it
	mediaRec.stop()
	mediaRec.stream.getTracks().forEach(track => {
		track.stop()
	})
	input.audioState.analyser?.disconnect()
}

// Adds global keybinding for ctrl push-to-talk
const defineGlobalKeybinds = (
	startRecording: () => Promise<void>,
	stopRecording: (send: boolean) => void,
) => {
	const pttKeyDown = (ev: KeyboardEvent) => {
		if (ev.code === 'ControlRight' && !ev.repeat) {
			startRecording().catch(err => {
				console.error(err)
			})
		}
	}
	const pttKeyUp = (ev: KeyboardEvent) => {
		if (ev.code === 'ControlRight') {
			stopRecording(true)
		}
	}
	document.addEventListener('keydown', pttKeyDown)
	document.addEventListener('keyup', pttKeyUp)
	return () => {
		document.removeEventListener('keydown', pttKeyDown)
		document.removeEventListener('keyup', pttKeyUp)
	}
}

// Gets the duration of the audio in seconds
const getAudioDuration = async (chunks: BlobPart[]): Promise<number> =>
	new Promise<number>(resolve => {
		const audioBlob = new Blob(chunks)
		const audioURL = URL.createObjectURL(audioBlob)
		const audio = new Audio(audioURL)
		audio.onloadedmetadata = () => {
			console.log(audio.duration)
			resolve(audio.duration)
		}
	})
