import { Maybe, zod } from '../../../../universal'
import { React, _ } from '../../../lib'
import { ComboBoxOptions, Combobox } from '../combobox'
import { FormField, FormFieldJSX } from './types'

/** This is common to both `FormType.Dropdown` and `FormType.DropdownMulti` */
type FormCombobox<T extends string | number> = {
	/** Options that go into the combobox */
	options: ComboBoxOptions<T>
	/**
	 * A function to quickly get the text for an option ID.
	 *
	 * The component could search `options` for an O(n) lookup, but this is faster
	 * since you can generally just do a map lookup in O(log n).
	 *
	 * Forms scale less than grids so this is optional - performance likely not an issue
	 */
	getText?: (value: T) => string
	/** The null option on the combobox will be given this label */
	nullable?: string
	/** If there's only one available option, auto-select it. Default: TRUE */
	autoPickOnly?: boolean
}

const MAX_ITEMS_FOR_ENUM = 20

type flatOption = [string | number, string]
const getEnumInfo = <R,>(options: ComboBoxOptions<string | number>) => {
	const options_flat: flatOption[] = options.flatMap(x => {
		if ('options' in x) {
			return x.options.map(y => [y.value, y.text] as flatOption)
		}
		return [[x.value, x.text]] as flatOption[]
	})
	let text_values = options_flat.map(x => x[1])
	const can_fit_all = text_values.length <= MAX_ITEMS_FOR_ENUM
	if (!can_fit_all) {
		text_values = _.sampleSize(text_values, MAX_ITEMS_FOR_ENUM)
	}
	return {
		enums: text_values,
		enumsAreComplete: can_fit_all,
		reversal: (value: unknown): R => {
			if (!_.isArray(value)) {
				return options_flat.find(y => y[1] == value)?.[0] as R
			}
			return value.map(v => options_flat.find(y => y[1] == v)?.[0]) as R
		},
	}
}

/** Enum dropdown - single select with numeric keys */
export const FormTypeDropdown = (
	settings: FormField<Maybe<number>> & FormCombobox<number>,
): FormFieldJSX<Maybe<number>> => ({
	...settings,
	valueDefaults: {
		def: () => null,
		validators: [],
	},
	height: 36,
	typeMap: {
		schemaPublic: zod.nullable(zod.number()),
		schemaRaw: zod.nullable(zod.number()),
		toPublic: x => x,
		toInternal: x => x,
	},
	llmInfo: {
		stringifiedType: 'string',
		description: settings.doc ?? settings.lbl,
		...getEnumInfo<Maybe<number>>(settings.options),
	},
	jsx: props => (
		<Combobox<number, false>
			multiple={false}
			value={props.value}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			options={settings.options}
			nullable={settings.nullable}
			disabled={props.readOnly || props.disabled}
			autoPickOnly={settings.autoPickOnly ?? true}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
		/>
	),
})
/** Enum dropdown - single select with string keys */
export const FormTypeDropdownStringKey = (
	settings: FormField<Maybe<string>> & FormCombobox<string>,
): FormFieldJSX<Maybe<string>> => ({
	...settings,
	valueDefaults: {
		def: () => null,
		validators: [],
	},
	height: 36,
	typeMap: {
		schemaPublic: zod.nullable(zod.string()),
		schemaRaw: zod.nullable(zod.string()),
		toPublic: x => x,
		toInternal: x => x,
	},
	llmInfo: {
		stringifiedType: 'string',
		description: settings.doc ?? settings.lbl,
		...getEnumInfo<Maybe<string>>(settings.options),
	},
	jsx: props => (
		<Combobox<string, false>
			multiple={false}
			value={props.value}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			options={settings.options}
			nullable={settings.nullable}
			disabled={props.readOnly || props.disabled}
			autoPickOnly={settings.autoPickOnly ?? true}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
		/>
	),
})

/** Enum dropdown - multiple select with numeric key */
export const FormTypeDropdownMulti = (
	settings: FormField<number[]> & FormCombobox<number>,
): FormFieldJSX<number[]> => ({
	...settings,
	valueDefaults: {
		def: () => [],
		validators: [],
	},
	height: 36,
	typeMap: {
		schemaPublic: zod.array(zod.number()),
		schemaRaw: zod.array(zod.number()),
		toPublic: x => x,
		toInternal: x => x,
	},
	llmInfo: {
		stringifiedType: 'string[]',
		description: settings.doc ?? settings.lbl,
		...getEnumInfo<number[]>(settings.options),
	},
	jsx: props => (
		<Combobox<number, true>
			multiple={true}
			value={props.value}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			options={settings.options}
			nullable={settings.nullable}
			disabled={props.readOnly || props.disabled}
			autoPickOnly={settings.autoPickOnly ?? true}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
		/>
	),
})

/** Enum dropdown - multiple select with string key */
export const FormTypeDropdownMultiStringKey = (
	settings: FormField<string[]> & FormCombobox<string>,
): FormFieldJSX<string[]> => ({
	...settings,
	valueDefaults: {
		def: () => [],
		validators: [],
	},
	height: 36,
	typeMap: {
		schemaPublic: zod.array(zod.string()),
		schemaRaw: zod.array(zod.string()),
		toPublic: x => x,
		toInternal: x => x,
	},
	llmInfo: {
		stringifiedType: 'string[]',
		description: settings.doc ?? settings.lbl,
		...getEnumInfo<string[]>(settings.options),
	},
	jsx: props => (
		<Combobox<string, true>
			multiple={true}
			value={props.value}
			onUpdate={props.onUpdate}
			className={props.className ?? undefined}
			title={props.title ?? undefined}
			options={settings.options}
			nullable={settings.nullable}
			disabled={props.readOnly || props.disabled}
			autoPickOnly={settings.autoPickOnly ?? true}
			onFocus={props.onFocus}
			onBlur={props.onBlur}
		/>
	),
})
