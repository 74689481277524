import { BuildClass, Do, Maybe, fsmData, timer } from '../../universal'
import { $, React, _ } from '../lib'
import { switchFacilityNew } from './component-facility-switcher'
import { j2h } from './component-j2h'
import {
	J2rLoadingSpinner,
	J2rObject,
	J2rTooltip,
	j2r,
	j2rtxt,
	reactRender,
} from './component-react'
import { TOGLOB, trionlineAjax } from './component-trionline'
import { moment } from './moment-wrapper'
import {
	Bindings,
	CJSX,
	ConditionalObject,
	ContextMenuOptions,
	ContextMenuWrapper,
} from './ui5'
import {
	WindowManagerToolbar,
	WindowManagerUI,
	WindowManagerUIOptions,
} from './window-manager'

// This is the script that stores components relating to the general frame UI
// of all of TriOnline. Things like the window manager are kept in their own
// files, but this is the meta-frame that these things slot into.

// Window manager stubs
// Declare own vars for these to remove the need to reference the WManage project
// If we didn't do this it would create a circular reference
declare let openNotificationFlyout: any

// Track the page refresh timestamp
export const latestRefresh = { ts: moment().format('YYYY-MM-DD-HH-mm-ss') }

const mapping: () => { [key: string]: string } = () => ({
	'/': window.rootData?.Session?.User ? 'openPageDashboard' : 'openLoginPage',
	'/roster/': 'roster.openNavRoster',
	'/roster/employee/': 'openNavEmployeeRoster',
	'/roster/settings/': 'openNavRosterSettings',
	'/billing/': 'billing.openNavBilling',
	'/billing/cdc-budget/': 'billing.openNavBillingBudget',
	'/billing/agreements/': 'billing.openNavRIWStandalone',
	'/timeclock/': 'openNavTimeclock',
	'/admin/backup-browser/': 'openNavBackupBrowser',
	'/admin/stp-approval-permissions/': 'openNavSTPApprovalPermissions',
	'/iris/': 'openNavIRISControlPanel',
	'/errors/': 'errors.openNavErrorManager',
	'/admin/clone-facility/': 'openNavCloneFacility',
	'/users/': 'openNavUserList',
	'/users-new/': 'users.openNavUserList',
	'/admin/demo/': 'openNavDemoUsers',
	'/download/': 'openNavDownloadPage',
	'/payroll/stp/': 'openNavKBSnippet',
	'/acct/': 'openNavAccountPage',
	'/payroll/stp/approve/': 'openNavSTPApprovals',
	'/privacy/': 'openNavKBSnippet',
	'/terms/': 'openNavKBSnippet',
	'/admin/sql/': 'websql.openNavWebSQL',
	'/admin/handles/': 'openNavHandles',
	'/admin/facility-list/': 'openFacilityList',
	'/payroll/payslips/': 'openNavPayslips',
	'/tms/': 'tms.openNavTMS',
	'/help/': 'openNavHelpPage',
	'/payroll/': 'payroll.openNavPayroll',
	'/kb/': 'openNavKBRoot',
	'/zeyar/login/': 'openZeyarLogin',
	'/crm/': 'crm.openNavCRM',
	'/hr/': 'hr.openNavHR',
	'/kb/<snippet>/': 'openNavKBSnippet',
	'/bulletins/view/<bulletin>/': 'openNavKBSnippet',
	'/leave/applications/': 'openNavLeaveApplications',
	'/payroll/queries/': 'openNavPayQueries',
	'/leave/scheduled/': 'openNavScheduledLeave',
	'/roster/clockings/': 'openNavClockings',
	'/bulletins/': 'openNavBulletin',
	'/mfa-elevation/': 'openMFALoginForm',
	'/forced-password-change/': 'openForcedPasswordChangeForm',
	'/acct/new-password/': 'openResetPassword',
	'/admin/zeyar-settings/': 'openZeyarSettings',
	'/admin/notification-editor/': 'openNavNotificationEditor',
	'/screenshare/': 'openNavScreenShare',
	'/leave/accrued/': 'openAccruedLeave',
	'/admin/schema-details/<database>/': 'openNavSchemaDetails',
	'/hr/onboard/': 'openEmployeeOnboard',
	'/search-index/': 'openNavSearchIndex',
	'/appraisals/': 'openNavViewAppraisals',
	'/appraisals/<appraisal>/': 'openNavViewAppraisals',
	'/settings/roles/': 'openNavManagementRoles',
	'/api/unsubscribe/<token>/': 'openEmailUnsubscribe',
	'/polls/': 'openNavPollManager',
	'/polls/<pollcode>/': 'openNavPolls',
	'/admin/invoicing/': 'openInvoiceMetrics',
	'/qfr-charts/': 'openNavQFRCharts',
})

const indexedNewFramePages = () => {
	// Convert to regex
	const mappingCompiled = _.map(mapping(), (fn, pattern) => {
		// Escape forward slashes
		pattern = pattern.replace(/\//g, '\\/')

		// Find any named groups and iterate over them to replace them with regex
		// Keep track of the given parameter names
		const params = []
		_.forEach(pattern.match(/<.*?>/g), grp => {
			pattern = pattern.replace(grp, '(.*?)')
			params.push(grp.substring(1, grp.length - 1))
		})

		// Return the regex pattern, the params (in order) and the handler function
		return {
			pattern: new RegExp(`^${pattern}$`),
			handler: getFunctionFromString(fn),
			params,
		}
	})

	// Return the compiled mapping
	return mappingCompiled
}

const getFunctionFromString = (fnString: string): Maybe<Function> => {
	let namespace: any = window
	let failed: boolean = false
	fnString.split('.').forEach(x => {
		if (namespace != null && x in namespace) {
			namespace = namespace[x]
		} else {
			failed = true
		}
	})
	if (failed) {
		return null
	}
	return namespace as Function
}

const getHandlerForURL = url => {
	let match = null

	// Check if the link is registered as a React page
	_.forEach(indexedNewFramePages(), route => {
		// Check if it matches - otherwise exit iteration early
		const results = url.match(route.pattern)
		if (results == null) {
			return undefined
		}

		// It matches - objectify the URL params from key to value
		const params = {}
		results.slice(1).forEach((val, index) => {
			const key = route.params[index]
			params[key] = val
		})

		// Return the handler function curried with the URL params
		// Return false to break out of the loop
		match = {
			fn: route.handler,
			params,
		}
		return false
	})

	// Return the match (or null)
	// Try without GET parameters if it fails
	if (match != null) {
		return match
	}
	if (url.split('#')[0] !== url) {
		getHandlerForURL(url.split('#')[0])
		return
	}
	if (url.split('?')[0] !== url) {
		getHandlerForURL(url.split('?')[0])
		return
	}
}

const getUrlParts = (url = location.href) => {
	// Convert to a relative path
	const prefix = `${location.protocol}//${location.host}`
	if (location.href.startsWith(prefix)) {
		url = url.substring(prefix.length)
	}

	// Split the path away from the raw GET param string
	const [path, raw_args] = url?.split('?', 2)

	// Convert the args into a dictionary
	const args = {}
	;(raw_args ?? '').split('&').forEach(x => {
		const [key, val] = x?.split('=')
		args[key] = decodeURIComponent(val)
	})

	// Return structured object representation of the current URL
	return { url, path, args }
}

const checkBrowserCompatibility = () => {
	try {
		// Try sets
		const set = new Set([1, 2, 3])
		Array.from(set)

		// Modern query selectors
		const nodes = document.querySelectorAll('*')
		nodes.forEach(() => {})

		// Promises
		const promise1 = Promise.resolve(3)
		const promise2 = new Promise(resolve => {
			resolve(1)
		})
		Promise.all([promise1, promise2]).then(() => {})

		return true
	} catch (error) {
		return false
	}
}

export const createUI2018 = () => {
	// If this is internet explorer, display basic info telling them to not do this
	if (navigator.userAgent.indexOf('Trident') !== -1) {
		showIEErrorPage()
		return
	}

	if (!checkBrowserCompatibility()) {
		showOutdatedBrowserPage()
		return
	}

	// Get the data package
	{
		let data
		const el = document.querySelector('#dataPackage') as HTMLInputElement
		const dataRaw = el.value
		el.remove()
		try {
			data = JSON.parse(dataRaw)
		} catch (error) {
			console.error(`Could not decode JSON: ${dataRaw}`)
			data = {}
		}
		window.rootData = _.assign(data, { UrlParts: getUrlParts() })
	}

	// Empty the body and put a root level element in its place
	document.querySelector('html').classList.add('react')
	document.body.classList.remove('capped_width')
	document.body.classList.add('react')
	document.body.innerText = ''
	document.body.appendChild(j2h({ class: 'ui-root' }))
	document.body.appendChild(j2h({ attr: { id: 'modal-container' } }))

	// Set the initial page state
	let { url } = getUrlParts()
	pageStateReplace(_.cloneDeep(window.rootData), url)

	// Check if we're redirecting from this page
	const is_redirecting = Do(() => {
		const D = window.rootData.Page ?? {}
		return D.mfaLogin || D.pwdChange
	})

	// Create the options object - start with the basic template and adjust
	// with the overriding properties of the page given in `getContentFromURL`
	let props = {
		tag: TriOnlineRoot,
		// Data
		sidebar: window.rootData.Sidebar,
		switcher: window.rootData.Switcher,
		session: window.rootData.Session,
		// Content
		path: url,
		content: null,
		toolbar: null,
		transparentBackground: false,
		windowManager: null,
	}
	const content = getContentFromURL()
	if (!is_redirecting && (content == null || !content.fn)) {
		timer(() => {
			throw new Error(`Cannot get content for ${location.pathname}`)
		})
	}
	props = _.assign({}, props, fixPageState(content.fn?.(content.params) ?? {}))

	// Set the transparent background flag - done outside of React since it's on the HTML tag
	if (props.transparentBackground) {
		document.querySelector('html').classList.add('transBG')
	}
	if (props.windowManager != null) {
		document.querySelector('html').classList.add('window-manager')
	}

	// Create the root element
	reactRender(document.querySelector('.ui-root'), j2r(props)).then(() => {
		timer(() => window.rootUI.updateSidebarExpansion())

		// Update the theme class for dark mode
		window.rootUI.refreshTheme()

		// Add the event listener to update the component with page dimensions
		window.addEventListener('resize', () => {
			if (document.body !== null) {
				window.rootUI.setState({
					width: document.body.clientWidth,
					height: document.body.clientHeight,
				})
			}
		})

		// Index the page locks
		timer(() => {
			TOGLOB.$lock = $('.toPageLock')
				.toArray()
				.map(x => $(x))
		})

		// If the page requires MFA or a password change, redirect
		if (window.rootData.Page?.mfaLogin) {
			url = `/mfa-elevation/?rd=${encodeURI(location.pathname)}`
			openNavLinkMaybe(url, true) // Replace state
		}
		if (window.rootData.Page?.pwdChange) {
			url = `/forced-password-change/?rd=${encodeURI(location.pathname)}`
			openNavLinkMaybe(url, true)
			return // Replace state
		}
	})
}

type RootSidebar = {
	Link: Maybe<string>
	Label: string
	Children: {
		Label: string
		Link: string
	}[]
}[]
type RootSession = {
	Token: string
	User: number
	RealUser: number
	Name: string
	Facility: number
	SubFacility: Maybe<number>
	Authority: number
	NoStaging: Maybe<boolean>
}

class TriOnlineRoot extends React.Component<
	{
		userName?: string
		notificationCount?: number
		windowManager?: object
		showSearch?: boolean
		collapseToIcon?: boolean
		content?: Maybe<() => J2rObject>
		legacyFrame?: {
			onCreate: Function
			onDestroy: Function
		}
		toolbar?: Maybe<() => J2rObject>
		sidebar?: Maybe<RootSidebar>
		sidebarOverride: Maybe<() => JSX.Element>
		path?: string
		dashboard?: any
		switcher?: any
		session?: RootSession
		contentPadding?: boolean
		cappedWidth?: boolean
		transparentBackground?: boolean
		hideSidebar?: boolean
		hideFooter?: boolean
		hideTopNav?: boolean
		theme?: string
		appName?: string
		expandedSidebar?: string
	},
	{
		width: number
		height: number
		searchText: string
		content: Maybe<() => J2rObject>
		windowManager: WindowManagerUIOptions
		legacyFrame: {
			onCreate: Function
			onDestroy: Function
		}
		contextMenu: ContextMenuOptions
		toolbar: Maybe<() => J2rObject>
		path: string
		sidebar: Maybe<RootSidebar>
		sidebarOverride: Maybe<() => JSX.Element>
		switcher: {
			current: [number, Maybe<number>]
			isSN: boolean
			facilities: [number, string, number][]
			rosters: [number, number, string][]
			servers: {
				[key: string]: {
					ID: number
					IsDev: number
					IsProd: number
					Subdomain: string
					FullName: string
					Description: string
				}
			}
		}
		session: RootSession
		notificationCount: number
		showSearch: boolean
		contentPadding: boolean
		cappedWidth: boolean
		transparentBackground: boolean
		hideSidebar: boolean
		hideFooter: boolean
		hideTopNav: boolean
		collapseToIcon: boolean
		theme: string
		appName: string
		expandedSidebar: string
		sidebarOpen: boolean
		kebabOpen: boolean
	}
> {
	content: React.RefObject<any>
	wmanagerToolbar: React.RefObject<WindowManagerToolbar>
	customToolbar: React.RefObject<any>

	constructor(props) {
		super(props)
		Bindings(this, [
			this.closeContextMenu,
			this.getResponsiveClassName,
			this.setContextMenu,
		])

		// Save instance to the window object for easy external access
		window.rootUI = this

		// Create references
		this.content = React.createRef()
		this.wmanagerToolbar = React.createRef()
		this.customToolbar = React.createRef()

		// Create initial state
		this.state = {
			width: document.body.clientWidth,
			height: document.body.clientHeight,
			searchText: '',
			// Content
			content: this.props.content ?? (() => null),
			windowManager: this.props.windowManager ?? null,
			legacyFrame: this.props.legacyFrame ?? null,
			contextMenu: null,
			// Unpack properties
			toolbar: this.props.toolbar ?? null,
			path: this.props.path ?? '/',
			sidebar: this.props.sidebar,
			sidebarOverride: this.props.sidebarOverride,
			switcher: this.props.switcher,
			session: this.props.session,
			notificationCount: this.props.notificationCount ?? 0,
			showSearch: this.props.showSearch ?? null,
			contentPadding: this.props.contentPadding ?? true,
			cappedWidth: this.props.cappedWidth ?? false,
			transparentBackground: this.props.transparentBackground ?? false,
			hideSidebar: this.props.hideSidebar ?? false,
			hideFooter: this.props.hideFooter ?? false,
			hideTopNav: this.props.hideTopNav ?? false,
			collapseToIcon: this.props.collapseToIcon ?? false,
			theme: this.props.theme ?? 'green',
			appName: this.props.appName ?? 'main',
			// Internal state
			expandedSidebar: this.props.expandedSidebar ?? null,
			sidebarOpen: false,
			kebabOpen: false,
		}
		this.setScrolling()
	}

	override render() {
		return j2r(() => {
			if (this.isProd()) {
				return this.buildFrame()
			}
			return {
				tag: React.StrictMode,
				children: [this.buildFrame()],
			}
		})
	}

	isProd() {
		if (['127.0.0.1', 'dev.localhost', '127.0.0.1:8001'].includes(location.host)) {
			return false
		}
		const h = location.host.split('.')
		if (h.length === 3) {
			return true
		}
		return !['dev', 'dan', 'dean', 'rob'].includes(h[0])
	}

	buildFrame() {
		return {
			key: 'ui-root',
			cl: BuildClass({
				'ui-root-inner': true,
				[this.getResponsiveClassName()]: true,
				[this.state.theme ?? 'green']: true,
				'hide-top-nav': this.state.hideTopNav,
				transBG: this.state.transparentBackground,
			}),
			children: [
				{
					id: 'hub_wrapper',
					key: 'hub_wrapper',
					cl: BuildClass({
						capped_width: this.state.cappedWidth,
					}),
					children: [
						this.buildToolbar(),
						this.state.session.User != null ? this.buildSidebar() : undefined,
						{
							key: 'body',
							id: 'hub_body',
							cl: BuildClass({
								[this.getResponsiveClassName()]: true,
								'no-footer': this.state.hideFooter,
								'hidden-sidebar':
									this.state.hideSidebar ||
									this.state.session.User == null,
							}),
							children: [this.buildContent(), this.buildFooter()],
						},
						{
							key: 'plt',
							cl: BuildClass({
								toPageLockTransparent: true,
								hamburger: true,
								active: this.state.sidebarOpen,
							}),
							onClick: () => {
								this.setState(
									{
										kebabOpen: false,
										sidebarOpen: false,
									},
									() => {
										this.setScrolling()
									},
								)
							},
						},
					],
				},
				{
					tag: ContextMenuWrapper,
					key: 'context-menu-wrapper',
					data: this.state.contextMenu,
					onClose: this.closeContextMenu,
				},
				{ key: 'pl1', cl: 'toPageLock', id: 'trionline_pagelock' },
				{ key: 'pl2', cl: 'toPageLock', id: 'trionline_pagelock_2' },
				{ key: 'pl3', cl: 'toPageLock', id: 'trionline_pagelock_3' },
				{ key: 'pl4', cl: 'toPageLock', id: 'trionline_pagelock_4' },
				{ key: 'pl5', cl: 'toPageLock', id: 'trionline_pagelock_5' },
			],
		}
	}

	setScrolling() {
		// Enables or disables page scrolling based on whether the sidebar/kebab is visible
		const clist = document.documentElement.classList
		const clstr = 'no-scroll'
		const currently_disabled = clist.contains(clstr)
		const should_be_disabled = this.state.sidebarOpen || this.state.kebabOpen
		if (should_be_disabled && !currently_disabled) {
			// clist.add(clstr)
			document.body.addEventListener('touchscroll', disablePageScroll)
			document.body.addEventListener('scroll', disablePageScroll)
		} else if (currently_disabled && !should_be_disabled) {
			// clist.remove(clstr)
			document.body.removeEventListener('touchscroll', disablePageScroll)
			document.body.removeEventListener('scroll', disablePageScroll)
		}
	}

	getResponsiveClassName() {
		const thresholds = [1275, 1025, 490]
		const w = this.state.width
		return Do(() => {
			if (w >= thresholds[0]) {
				return 'wide'
			} else if (w < thresholds[0] && w >= thresholds[1]) {
				return 'condensed'
			} else if (w < thresholds[1] && w >= thresholds[2]) {
				return 'tablet'
			} else if (w < thresholds[2]) {
				return 'mobile'
			}
			return 'wide'
		})
	}

	buildToolbar() {
		return {
			key: 'toolbar',
			id: 'hub_toolbar',
			cl: BuildClass({
				[this.getResponsiveClassName()]: true,
				noselect: true,
			}),
			children: [
				// Hamburger icon - opens sidebar menu
				{
					tag: J2rTooltip,
					key: 'hamburger',
					elTag: 'a',
					title: 'Toggle Sidebar',
					cl: BuildClass({
						hover: true,
						visible: this.state.hideSidebar,
					}),
					id: 'hub_hamburger',
					content: [
						{
							tag: 'img',
							key: 'img',
							alt: 'Menu',
							src: Do(() => {
								if (this.state.sidebarOpen) {
									return '/static/img/svg/hamburger-back.svg'
								}
								return '/static/img/svg/hamburger.svg'
							}),
						},
					],
					onClick: e => {
						e.preventDefault()
						this.setState(
							{
								sidebarOpen: !this.state.sidebarOpen,
							},
							() => {
								this.setScrolling()
							},
						)
					},
				},

				// TriOnline logo
				{
					tag: J2rTooltip,
					key: 'logo',
					elTag: 'a',
					title: 'TriOnline Home Page',
					cl: BuildClass({
						hover: true,
						collapsible: this.state.collapseToIcon,
					}),
					id: 'hub_logo',
					href: '/',
					onClick: appLinkEv,
					content: [
						{
							tag: 'img',
							key: 'img',
							src: Do(() => {
								if (
									window.innerWidth <= 768 &&
									this.state.collapseToIcon
								) {
									return '/static/img/svg/favicon.svg'
								} else if (this.state.theme === 'black') {
									return '/static/img/svg/trionline-white.svg'
								}
								return '/static/img/svg/trionline.svg'
							}),
							alt: 'TriOnline',
						},
					],
				},

				// Search box - responsive space between LHS logo and RHS icons
				{
					tag: 'span',
					key: 'search',
					id: 'hub_search',
					cl: 'buttons',
					children: [
						// Window manager toolbar (only if ready)
						ConditionalObject(
							this.state.windowManager != null &&
								this.content.current?.state?.hasInitModel,
							{
								tag: WindowManagerToolbar,
								key: 'wmanage-toolbar',
								ref: this.wmanagerToolbar,
								wmanager: this.content.current,
							},
						),

						// Normal toolbar
						ConditionalObject(this.state.toolbar != null, () => {
							const tb = j2r(this.state.toolbar())
							return React.cloneElement(tb, {
								className: BuildClass({
									'custom-toolbar': true,
									[(tb as any).cl]: true,
								}),
								key: 'custom-toolbar',
								ref: this.customToolbar,
							})
						}),

						// Search box (if enabled)
						ConditionalObject(this.state.showSearch, {
							tag: 'input',
							type: 'text',
							key: 'search',
							id: 'hub_search_q',
							value: this.state.searchText,
							placeholder: 'Click to search...',
							onChange: e => {
								this.setState({ searchText: e.value })
							},
						}),
					],
				},

				// Notification icon
				ConditionalObject(this.state.session.User != null, {
					tag: J2rTooltip,
					key: 'notifications',
					elTag: 'span',
					title: 'Notifications',
					id: 'hub_notification_count',
					cl: BuildClass({
						'toolbar-icon': true,
						hover: true,
						zero: this.state.notificationCount === 0,
					}),
					content: [
						this.state.notificationCount > 0
							? {
									tag: 'span',
									cl: 'count',
									key: 'count',
									text: this.state.notificationCount,
								}
							: {
									tag: 'img',
									cl: 'notification-icon',
									key: 'notification-icon',
									alt: 'Notifications',
									src: '/static/img/svg/small-notification-bell.svg',
								},
					],
					onClick: () => openNotificationFlyout(),
				}),

				// Kebab icon menu
				{
					tag: J2rTooltip,
					key: 'kebab-menu-icon',
					elTag: 'span',
					title: 'Global Menu',
					id: 'kebab-menu-icon',
					cl: BuildClass({
						'toolbar-icon': true,
						hover: true,
						zero: this.state.notificationCount === 0,
					}),
					content: [
						{
							tag: 'img',
							cl: 'kebab',
							key: 'kebab',
							alt: 'Menu',
							src: '/static/img/svg/kebab-main.svg',
						},
					],
					onClick: () => {
						this.setState(
							{
								kebabOpen: !this.state.kebabOpen,
							},
							() => {
								this.setScrolling()
							},
						)
					},
				},

				// Account link displaying user's name
				ConditionalObject(this.state.session.User != null, {
					tag: J2rTooltip,
					key: 'user',
					elTag: 'a',
					title: `Click to view account page for ${this.state.session.Name}`,
					cl: 'hover',
					id: 'hub_acct',
					href: '/acct/',
					onClick: appLinkEv,
					content: this.state.session.Name,
				}),

				// Kebab menu - stays hidden
				this.buildKebabMenu(),

				// Page lock - covers the content when the kebab menu is visible
				{
					key: 'plt',
					cl: BuildClass({
						toPageLockTransparent: true,
						kebab: true,
						active: this.state.kebabOpen,
					}),
					onClick: () => {
						this.setState(
							{
								kebabOpen: false,
								sidebarOpen: false,
							},
							() => {
								this.setScrolling()
							},
						)
					},
				},
			],
		}
	}

	buildKebabMenu() {
		const link = (txt: string, img?: string, link?: string, click?: () => void) => (
			<a
				key={`${txt}${link}`}
				href={link ?? undefined}
				onClick={e => {
					if (link == null) {
						e.preventDefault()
					}
					;(click ?? appLinkEv)(e)
				}}
				title={txt}
			>
				<img alt={`Icon for ${txt}`} src={`/static/img${img}`} />
				<span>{txt}</span>
			</a>
		)

		return (
			<div
				key="kebab-menu"
				className={BuildClass({
					'kebab-menu': true,
					shadow_large: true,
					visible: this.state.kebabOpen,
					'no-user': this.state.session.User == null,
				})}
			>
				{/* These require being logged in */}
				<CJSX cond={this.state.session.User != null}>
					{link(
						`${this.state.notificationCount} notification${
							this.state.notificationCount === 1 ? '' : 's'
						}`,
						'/svg/small-notification-bell.svg',
						null,
						() => {
							this.setState({ kebabOpen: false }, () => {
								this.setScrolling()
								timer(() => openNotificationFlyout())
							})
						},
					)}
					{link(
						this.getFacilityName(),
						'/svg/small-house-icon.svg',
						null,
						() => {
							this.setState({ kebabOpen: false }, () => {
								this.setScrolling()
								if (this.props.session.User != null) {
									timer(() => {
										switchFacilityNew()
									})
								}
							})
						},
					)}
					{link('My Account', '/svg/small-person-icon.svg', '/acct/')}
				</CJSX>

				{/* Sign in - only when not signed in */}
				<CJSX cond={this.state.session.User == null}>
					{link('Sign In', '/svg/small-person-icon.svg', '/')}
				</CJSX>

				{/* These always show */}
				{link('Refresh', '/svg/small-refresh-icon.svg', null, () => {
					location.reload()
				})}
				{link('Help', '/svg/small-help-icon.svg', '/help/')}

				{/* Logout only when logged in */}
				<CJSX cond={this.state.session.User != null}>
					{link('Sign Out', '/svg/small-door-icon.svg', '/login/logout/')}
				</CJSX>
			</div>
		)
	}

	buildSidebar() {
		return (
			<TriOnlineFrameSidebar
				key="sidebar"
				expandedSidebar={this.state.expandedSidebar}
				getResponsiveClassName={this.getResponsiveClassName}
				hideSidebar={this.state.hideSidebar}
				sidebar={this.state.sidebar}
				sidebarOpen={this.state.sidebarOpen}
				sidebarOverride={this.state.sidebarOverride}
				onToggleExpanded={v => {
					this.setState({ expandedSidebar: v })
				}}
			/>
		)
	}

	buildFooter() {
		return {
			id: 'hub_footer',
			cl: BuildClass({
				noselect: true,
				'no-footer': this.state.hideFooter,
				'hidden-sidebar':
					this.state.hideSidebar || this.state.session.User == null,
			}),
			key: 'footer',
			children: [
				{
					tag: 'span',
					key: 'footer-logos',
					id: 'hub_footer_logos',
					cl: 'nomobile',
					children: [
						{
							tag: J2rTooltip,
							key: 'tm-logo-outer',
							elTag: 'a',
							title: 'Click to go to Trimicro.com.au',
							href: 'http://trimicro.com.au',
							target: '_blank',
							rel: 'noopener',
							content: [
								j2rtxt('copyright', '©'),
								{
									tag: 'img',
									key: 'tm-logo',
									alt: 'TriMicro logo',
									src: '/static/img/trimicro-logo-footer.png',
								},
							],
						},
						{
							tag: J2rTooltip,
							key: 'sn-logo-outer',
							elTag: 'a',
							title: 'Click to go to SoftwareNorth.com.au',
							cl: 'less-needed',
							href: 'http://softwarenorth.com.au',
							target: '_blank',
							rel: 'noopener',
							content: ' by Software North',
						},
					],
				},
				{
					tag: J2rTooltip,
					key: 'hub_facility_selector',
					elTag: 'span',
					title: this.state.session.User != null ? 'Facility Switcher' : '',
					id: 'hub_facility_selector',
					content: _.compact([
						Do(() => {
							const title = this.getFacilityName()
							if (this.state.session.User == null) {
								return null
							}
							return {
								id: 'admin_choice',
								key: 'admin_choice',
								title,
								children: [
									j2rtxt('txt', title),
									{
										tag: 'img',
										key: 'arrow',
										alt: 'Arrow',
										src: '/static/img/svg/triangle-down.svg',
									},
								],
								onClick: () => {
									switchFacilityNew()
								},
							}
						}),
					]),
				},
				{
					tag: 'span',
					id: 'hub_footer_links',
					key: 'hub_footer_links',
					children: [
						{
							tag: 'a',
							key: 'Help',
							href: '/help/',
							text: 'Help',
							onClick: appLinkEv,
						},
						{
							tag: 'a',
							key: 'Privacy',
							cl: 'less-needed',
							href: '/privacy/',
							text: 'Privacy',
							onClick: appLinkEv,
						},
						ConditionalObject(this.state.session.User == null, {
							tag: 'a',
							key: 'terms',
							cl: 'less-needed',
							href: '/terms/',
							text: 'Terms',
							onClick: appLinkEv,
						}),
						ConditionalObject(this.state.session.User != null, {
							tag: 'a',
							key: 'Feedback',
							cl: 'less-needed',
							href: '/feedback/',
							text: 'Feedback',
							onClick: appLinkEv,
						}),

						Do(() => {
							if (this.state.session.User != null) {
								return {
									tag: 'a',
									key: 'Sign Out',
									href: '/login/logout/',
									id: 'href_sign_out',
									text: 'Sign Out',
									onClick: appLinkEv,
								}
							} else if (location.pathname !== '/') {
								return {
									tag: 'a',
									key: 'Sign In',
									href: '/',
									id: 'href_sign_out',
									text: 'Sign In',
									onClick: appLinkEv,
								}
							}
							return undefined
						}),
					],
				},
			],
		}
	}

	getFacilityName() {
		const [fac, srost] = this.state.switcher.current
		const facility = fsmData(this.state.switcher.facilities, {
			filter: x => x[0] === fac,
			map: x => x?.[1],
			takeFirst: true,
		})
		const subroster = fsmData(this.state.switcher.rosters, {
			filter: x => x[0] === fac && x[1] === srost,
			map: x => x?.[2],
			takeFirst: true,
		})
		return Do(() => {
			if (facility == null) {
				return 'Not signed in'
			} else if (subroster != null) {
				return `${fac}: ${facility} - ${subroster}`
			}
			return `${fac}: ${facility}`
		})
	}

	buildContent() {
		return {
			tag: 'main',
			id: 'hub_content',
			cl: BuildClass({
				padding: this.state.contentPadding,
				loading: !(this.state.legacyFrame != null || this.state.content != null),
				'no-footer': this.state.hideFooter,
			}),
			key: 'content',
			children: [
				Do(() => {
					// Legacy frame - use another component to manage that lifecycle
					if (this.state.legacyFrame != null) {
						return {
							tag: LegacyWrapperComponent,
							key: 'legacy-wrapper',
							ref: this.content,
							onCreate: this.state.legacyFrame.onCreate,
							onDestroy: this.state.legacyFrame.onDestroy,
						}

						// Window manager frame
					} else if (this.state.windowManager != null) {
						return {
							tag: WindowManagerUI,
							ref: this.content,
							key: 'content-inner-wmanager',
							parent: this,
							toolbar: this.wmanagerToolbar.current,
							mobile: this.getResponsiveClassName() === 'mobile',
							options: this.state.windowManager,
						}

						// Loading - no content available yet
					} else if (this.state.content == null) {
						return {
							tag: J2rLoadingSpinner,
							size: 'large',
							key: 'loading-content',
						}

						// Content is there and ready to show
					}
					return _.assign({}, this.state.content(), {
						key: 'content-inner',
						ref: this.content,
					})
				}),
			],
		}
	}

	toggleCappedWidth() {
		this.setState(s => ({ cappedWidth: !s.cappedWidth }))
	}

	toggleSideBar() {
		this.setState(s => ({ hideSidebar: !s.hideSidebar }))
	}

	updateSidebarExpansion() {
		_.forEach(this.state.sidebar, item => {
			const selected = _.filter(item.Children, s =>
				compareLinks(s.Link, location.pathname),
			)
			if (this.state.expandedSidebar !== item.Label && selected.length > 0) {
				this.setState(s => ({
					expandedSidebar: Do(() => {
						if (s.expandedSidebar === item.Label) {
							return null
						}
						return item.Label
					}),
				}))
			}
		})
	}

	changePage(pageState) {
		// Set the HTML class for some edge case background overscrolling stuff
		// Also set the HTML class for window manager
		if (pageState.transparentBackground) {
			document.querySelector('html').classList.add('transBG')
		}
		if (pageState.windowManager != null) {
			document.querySelector('html').classList.add('window-manager')
		}

		// Set the page title
		document.title = `${window.rootData.Title} | TriOnline`

		// Update the internal state to show the new page
		const delta = _.assign({}, fixPageState(pageState), { path: getUrlParts().url })
		this.setState(delta, () => {
			// Post-page-change tidy-ups

			// Update the theme class for dark mode
			this.refreshTheme()

			// Update the icon/app metadata
			refreshAppMetaData(this.state.appName)

			// Mark notifications for this URL as read
			navigator.serviceWorker?.getRegistration().then(async reg =>
				reg.getNotifications().then(notifications => {
					notifications.forEach(N => {
						if (location.pathname === N.tag) {
							N.close()
						}
					})
				}),
			)
		})
	}

	setContextMenu(obj: ContextMenuOptions) {
		this.setState({ contextMenu: obj })
	}

	changeTheme(theme) {
		this.setState({ theme }, () => {
			this.refreshTheme()
		})
	}

	refreshTheme() {
		if (this.state.theme === 'black') {
			document.body.classList.add('black')
		} else {
			document.body.classList.remove('black')
		}
	}

	closeContextMenu() {
		this.setState({ contextMenu: null })
	}

	NewWindow(fn, params, cb) {
		const fnBase = contentComponent()?.NewWindow
		if (fnBase == null) {
			timer(() => {
				throw new Error('Cannot open a new window with current page state')
			})
		}
		fnBase(fn, params, cb)
	}
}

export var contentComponent = () => window.rootUI?.content.current

export const openWindow = (fn, params?, cb?) => {
	if (window.rootUI == null) {
		timer(() => {
			throw new Error('Cannot open a new window without new frame')
		})
	}
	window.rootUI?.NewWindow(fn, params, cb)
}

// Component to wrap legacy frame pages
class LegacyWrapperComponent extends React.Component<{
	onCreate: Function
	onDestroy: Function
}> {
	wrapper: React.RefObject<HTMLDivElement>

	constructor(props) {
		super(props)
		this.wrapper = React.createRef()
	}

	override componentDidMount() {
		this.wrapper.current.innerText = ''
		;(this.props.onCreate ?? _.noop)(this.wrapper.current, window.rootData, this)
	}

	override componentWillUnmount() {
		;(this.props.onDestroy ?? _.noop)(this.wrapper.current, window.rootData, this)
		this.wrapper.current.innerText = ''
	}

	override render() {
		return j2r({
			cl: 'legacy-wrapper',
			id: 'legacy-wrapper',
			ref: this.wrapper,
		})
	}
}

export var appLinkEv = ev => {
	// Check if this needs to open in a new tab
	const new_tab = ev.button === 1 || ev.ctrlKey || ev.shiftKey

	// Get the link - if it's null, make sure the click does nothing
	const link = ev.currentTarget.href
	if (link == null) {
		ev.preventDefault()
		return
	}

	// Open the link - it might be a nav link, or it might be an old link - not sure yet
	openNavLinkMaybe(link, false, ev, new_tab)
}

export const mutateLinksForApplication = link => {
	const link2 = `${link}/`
	const handler1 = getHandlerForURL(link)
	const handler2 = getHandlerForURL(link2)
	if (handler1 != null) {
		return [true, link]
	} else if (handler2 != null) {
		return [true, link2]
	}
	return [false, link]
}

var compareLinks = (a, b) => {
	if (a == null || b == null) {
		return false
	}
	const parse = a => (a.slice(-1)[0] === '/' ? a.slice(0, -1) : a)
	return parse(a) === parse(b)
}

var getContentFromURL = (link = location.pathname) => {
	// If there's an error message in the data package, display an error object
	if (window.rootData.Error != null) {
		return {
			params: null,
			fn: () => ({
				cappedWidth: true,
				content: () => ({
					cl: 'error-frame',
					children: [
						{
							tag: 'h1',
							key: 'title',
							text: window.rootData.Title,
						},
						{
							cl: 'error_msg_2',
							key: 'err',
							text: window.rootData.Error,
						},
					],
				}),
			}),
		}
	}

	// Normalise the link and return the content handler
	// If no handler found, fallback to the stub page
	return (
		getHandlerForURL(link) ?? {
			params: null,
			fn: () => ({
				content: () => ({
					cl: 'error_msg_2 cntr',
					key: 'stub',
					text: 'Unknown URL for React Wrapper',
				}),
			}),
		}
	)
}

export var openNavLinkMaybe = (link, replacing?, ev?, new_tab?) => {
	// Convert it to a relative path if possible
	const prefix = `${location.protocol}//${location.host}`
	if (link.slice(0, prefix.length) === prefix) {
		link = link.slice(prefix.length)
	}

	// Check if it's an application page or an actual link
	// If it's an application link, cancel default event behaviour and redirect
	const [is_app, url] = mutateLinksForApplication(link)
	if (is_app) {
		ev?.preventDefault()
		if (new_tab) {
			window.open(url)
		} else {
			openNavLink(url, replacing)
		}
	} else {
		location.href = url
	}
}

export var openNavLink = (
	linkOrig: string,
	replacing: boolean = false,
	err_count: number = 0,
) => {
	// Ensure it's valid - maybe add the trailing slash
	const [is_app, link] = mutateLinksForApplication(linkOrig)

	// If it's not valid - open directly and log the error
	if (!is_app) {
		timer(() => {
			throw new Error(`Invalid link ${link}`)
		})
		timer(1000, () => {
			openNavLinkMaybe(link)
		})
		return
	}

	// If there have been more than 5 errors trying to open this, show error message
	if (err_count > 5) {
		window.rootUI.changePage({
			cappedWidth: true,
			content: () => ({
				cl: 'error-frame',
				children: [
					{
						tag: 'h1',
						key: 'title',
						text: 'Offline',
					},
					{
						cl: 'error_msg_2',
						key: 'err',
						text: 'Cannot connect to Trionline right now. Please check your internet connection.',
					},
				],
			}),
		})
		return
	}

	// Start loading spinner
	window.rootUI.setState(
		{
			// Null the content
			content: null,
			legacyFrame: null,
			windowManager: null,
			contextMenu: null,
			// Close all the drawers and bring back the white background
			sidebarOpen: false,
			kebabOpen: false,
			transparentBackground: false,
		},
		() => window.rootUI.setScrolling(),
	)

	// Remove custom classes that depend on the specific page (are re-added if needed)
	const htmlCl = document.querySelector('html').classList
	htmlCl.remove('transBG')
	htmlCl.remove('window-manager')

	// Update the history so the new URL can be shown immediately
	// It's still linked to the old data, though
	// TODO - locker content history state mix with page history state
	if (replacing) {
		pageStateReplace(_.cloneDeep(window.rootData), link)
	} else {
		pageStatePush(_.cloneDeep(window.rootData), link)
	}

	// Get the URL for the AJAX call
	let url: string
	{
		url = link.split('#')[0]
		url = url.includes('?') ? `${url}&apionly=1` : `${url}?apionly=1`
		url += `&latest=${latestRefresh.ts}`
	}

	// Request a new data package for thew new page
	trionlineAjax({
		url: url,
		any: e => {
			// Get the updated the data model
			const data = e.currentTarget.responseText

			// If a refresh is required, switch to a
			if (data == 'TOKEN_PAGE_REFRESH_NEEDED') {
				console.log('Application updated, refreshing')
				location.href = linkOrig
				return
			}

			// Parse the JSON, ignoring errors
			let dataObj = null
			try {
				dataObj = JSON.parse(data)
			} catch (error) {}

			// Check if there was some sort of failure
			// In this case, retry opening the link recursively with an increased error count
			if (data == null || e.currentTarget.status >= 500 || dataObj == null) {
				openNavLink(linkOrig, replacing, err_count + 1)
			}

			// Change the page with this data object
			changePageOuter(link, dataObj)
		},
	})
}

var changePageOuter = async (link, dataObj) => {
	// Build the full data structure
	window.rootData = _.assign(dataObj, { UrlParts: getUrlParts(link) })

	// Update the history state with the new data
	pageStateReplace(_.cloneDeep(window.rootData), link)
	window.rootUI.updateSidebarExpansion()

	// Remove all existing page styles - they can be re-added as needed
	Array.from(document.querySelectorAll('link.page-style')).forEach(x => {
		x.remove()
	})

	// Add the new script/style assets an wait for them all to load
	// Once loaded the new page will be shown and/rendered
	return Promise.all([
		addStylesToPage(window.rootData?.Assets?.Style ?? []),
		addScriptsToPage(window.rootData?.Assets?.Script ?? []),
	])
		.then(() => {
			let url
			const handler = getContentFromURL(link)
			if (handler == null) {
				console.info(
					'Forcing page refresh from invalid handler found from content URL',
				)
				location.reload()
			}
			if (window.rootData.Page?.mfaLogin) {
				url = `/mfa-elevation/?rd=${encodeURI(location.pathname)}`
				openNavLinkMaybe(url, true)
			} else if (window.rootData.Page?.pwdChange) {
				url = `/forced-password-change/?rd=${encodeURI(location.pathname)}`
				openNavLinkMaybe(url, true)
			} else {
				window.rootUI.changePage(handler.fn(handler.params))
			}
		})
		.catch(err => {
			console.error(err)
		})
}

export const addScriptsToPage = async (scripts: string[]) => {
	// Convert to full URLs
	const script_urls = _.map(scripts, x => {
		const prefix_char = x.indexOf('?') === -1 ? '?' : '&'
		x += `${prefix_char}_t=${window.rootData.Version}`
		if (x[0] === '/') {
			x = `${location.protocol}//${location.host}${x}`
		}
		return x
	})

	// Get the existing scripts so we don't double-import
	const getEls = q => Array.from(document.querySelectorAll(q))
	const existing_scripts = getEls('script').map(el => el.src)
	const script_urls_new = script_urls.filter(x => !existing_scripts.includes(x))

	// Add scripts from URLs to the `head` tag and returns the promise of them loading
	// Wrap all of the loading scripts into one promise to return back
	// Helper function to return a promise for loading one script
	return Promise.all(
		script_urls_new.map(
			async url =>
				new Promise<void>((resolve, reject) => {
					const newScript = document.createElement('script')
					newScript.onerror = reject
					newScript.onload = () => {
						resolve()
					}
					newScript.async = false
					newScript.defer = false
					newScript.type = 'application/javascript'
					document.head.appendChild(newScript)
					newScript.src = url
				}),
		),
	)
}

export const addStylesToPage = async (stylesheets: string[]) => {
	const stylesheet_urls = _.map(stylesheets, x => {
		const prefix_char = x.indexOf('?') === -1 ? '?' : '&'
		x += `${prefix_char}_t=${window.rootData.Version}`
		if (x[0] === '/') {
			x = `${location.protocol}//${location.host}${x}`
		}
		return x
	})
	return Promise.all(
		stylesheet_urls.map(
			async x =>
				new Promise<void>((resolve, reject) => {
					const newStyle = document.createElement('link')
					newStyle.onerror = reject
					newStyle.onload = () => {
						resolve()
					}
					newStyle.classList.add('page-style')
					newStyle.rel = 'stylesheet'
					newStyle.type = 'text/css'
					document.head.appendChild(newStyle)
					newStyle.href = x
				}),
		),
	)
}

const fixPageState = s => {
	// Ensures default parameter values
	const defaults = {
		contentPadding: true,
		cappedWidth: false,
		windowManager: null,
		hideSidebar: false,
		hideFooter: false,
		hideTopNav: false,
		collapseToIcon: false,
		toolbar: null,
		sidebarOverride: null,
		transparentBackground: false,
		legacyFrame: null,
		contextMenu: null,
		appName: 'main',
	}
	return _.assign(defaults, s)
}

window.onpopstate = ev => {
	// Skip if there's no root UI available to manipulate
	if (window.rootUI == null) {
		return
	}

	// Get the data from the history data model object based on the ID stored
	const model = historyModel.data[ev.state?.id]
	if (model == null) {
		openNavLinkMaybe(location.href)
		return
	}

	// Partial page navigation - within a single page's scope
	if (model?.contentDelta) {
		// console.log model.contentDelta?
		contentComponent().setState(model.contentDelta)
		return
	}

	// Go back
	if (model != null) {
		const { url } = getUrlParts(location.href)
		changePageOuter(url, model)
		return
	}

	// No model found - reload at the new URL for safety
	console.info('Forcing page refresh from invalid pop history')
	location.reload()
}

const disablePageScroll = e => {
	// This becomes the event handler for scrolling when the page scrolling is disabled
	e.preventDefault()
	e.returnValue = false
	return false
}

// History API wrappers that store data models in RAM instead of in the page state #
export const historyModel = {
	data: {},
	id: 0,
}

const pageStatePush = (data, url) => {
	const hd = historyModel
	hd.data[hd.id] = data
	history.pushState({ id: hd.id }, '', url)
	return hd.id++
}

const pageStateReplace = (data, url) => {
	const hd = historyModel
	hd.data[hd.id] = data
	history.replaceState({ id: hd.id }, '', url)
	return hd.id++
}

const refreshAppMetaData = app => {
	// Update the manifest
	{
		const el = document.querySelector('link[rel="manifest"]')
		const v = window._trionline_timestamp_version_value
		const manifest_url = `/manifest-${app}.json?_t=${v}`
		el.attributes['href'].value = manifest_url
	}

	// Update shortcut icons
	const qs = [
		'link[rel="icon"]',
		'link[rel="apple-touch-icon"]',
		'link[rel="shortcut icon"]',
		'meta[name="msapplication-TileImage"]',
	]
	const q = qs.join(', ')
	const nodes = document.querySelectorAll(q)
	_.range(nodes.length).forEach(i => {
		const el = nodes.item(i)
		const key = Do(() => {
			if (el.tagName.toLowerCase() === 'meta') {
				return 'content'
			}
			return 'href'
		})
		const path = Do(() => {
			if (app == null) {
				app = 'main'
			}
			const size = el.attributes['data-size']?.value
			if (size != null) {
				return `/static/app-${app}/m${size}.png`
			}
			return `/static/app-${app}/favicon.ico`
		})
		if (path !== el.attributes[key].value) {
			el.attributes[key].value = path
		}
	})
}

// IE error page
const showIEErrorPage = () => {
	document.body.innerText = ''
	document.body.appendChild(
		j2h({
			class: 'invalid-browser',
			children: [
				{
					children: [
						{
							tag: 'p',
							text: 'Internet Explorer is not supported. Please use another browser.',
						},
						{
							tag: 'p',
							text: `For security reasons, TriOnline strongly recommends that you stop using Internet Explorer for all web browsing.`,
						},
						{
							tag: 'ul',
							children: [
								{ tag: 'li', text: 'Mozilla Firefox' },
								{ tag: 'li', text: 'Microsoft Edge' },
								{ tag: 'li', text: 'Google Chrome' },
							],
						},
					],
					attr: {
						style: `padding: 10px; font: 24px 'Roboto', 'Arial', 'Sans-Serif';`,
					},
				},
			],
			attr: {
				style: `
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: white;`,
			},
		}),
	)
}

const showOutdatedBrowserPage = () => {
	document.body.innerText = ''
	document.body.appendChild(
		j2h({
			class: 'invalid-browser',
			children: [
				{
					children: [
						{
							tag: 'p',
							text: 'You are using an outdated browser that is not supported. Please use another browser.',
						},
						{
							tag: 'p',
							text: `For security reasons, TriOnline strongly recommends that you stop using this browser and consider the following:`,
						},
						{
							tag: 'ul',
							children: [
								{ tag: 'li', text: 'Mozilla Firefox' },
								{ tag: 'li', text: 'Microsoft Edge' },
								{ tag: 'li', text: 'Google Chrome' },
							],
						},
					],
					attr: {
						style: `\
							padding: 10px;
							font: 24px 'Roboto', 'Arial', 'Sans-Serif';
						`,
					},
				},
			],
			attr: {
				style: `
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: white;
				`,
			},
		}),
	)
}

// Sidebar component
const TriOnlineFrameSidebar = (props: {
	getResponsiveClassName: () => string
	hideSidebar: boolean
	sidebar: Maybe<RootSidebar>
	sidebarOverride: () => Maybe<JSX.Element>
	sidebarOpen: boolean
	expandedSidebar: string
	onToggleExpanded: (val: string) => void
}) => (
	<div
		id="hub_sidebar"
		className={BuildClass({
			[props.getResponsiveClassName()]: true,
			noselect: true,
			hiddenDefault: props.hideSidebar,
			employee: (window.rootData.Session?.Authority ?? 0) === 0,
			open: props.sidebarOpen,
		})}
	>
		{Do(() => {
			// Show loading spinner if no sidebar data is here yet
			if (props.sidebar == null) {
				return <J2rLoadingSpinner size="large" />
			}

			// If there's a sidebar overload, use that
			if (props.sidebarOverride) {
				const override = props.sidebarOverride()
				if (override != null) {
					return override
				}
			}

			// Build the sidebar from the items
			return (
				<React.Fragment>
					{_.map(props.sidebar, (item, index) => [
						<TriOnlineSidebarItem
							key={index}
							item={item}
							expandedSidebar={props.expandedSidebar}
							onToggleExpanded={props.onToggleExpanded}
						/>,
					])}
				</React.Fragment>
			)
		})}
	</div>
)

const TriOnlineSidebarItem = (props: {
	item: {
		Label: string
		Link: string
		Children: {
			Label: string
			Link: string
		}[]
	}
	expandedSidebar: string
	onToggleExpanded: (val: string) => void
}) => (
	<React.Fragment>
		<a
			key={props.item.Label}
			className={BuildClass({
				selected: compareLinks(props.item.Link, location.pathname),
				open: props.expandedSidebar === props.item.Label,
			})}
			href={props.item.Link ?? null}
			title={props.item.Label}
			onClick={
				props.item.Link != null
					? appLinkEv
					: () => {
							props.onToggleExpanded(
								Do(() => {
									if (props.expandedSidebar === props.item.Label) {
										return null
									}
									return props.item.Label
								}),
							)
						}
			}
		>
			<img
				key="arrow"
				alt={props.item.Children != null ? 'Expanded' : 'Not expanded'}
				src="/static/img/svg/triangle-down.svg"
				style={{
					visibility: Do(() => {
						if (props.item.Children != null) {
							return 'visible'
						}
						return 'hidden'
					}),
				}}
			/>
			<span key="lbl" title={props.item.Label}>
				{props.item.Label}
			</span>
		</a>
		{ConditionalObject(props.expandedSidebar === props.item.Label, () => (
			<React.Fragment>
				{_.map(props.item.Children, sub => (
					<a
						key={sub.Label}
						className={BuildClass({
							sub: true,
							selected: compareLinks(sub.Link, location.pathname),
						})}
						href={sub.Link ?? null}
						onClick={appLinkEv}
						title={sub.Label}
						style={{ display: 'block' }}
					>
						{sub.Label}
					</a>
				))}
			</React.Fragment>
		))}
	</React.Fragment>
)
