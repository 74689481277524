import type {
	ExtractIO,
	FuncIDs,
	IRISResponse,
	IRISResponseNo,
	IRISResponseYes,
	ProgIDs,
} from '../../backend/shared-types'
import { IRIS } from './component-iris'

/**
 * The required parameters from the client for an IRIS request
 * Input and output data types are based on a lookup from the progID and funcID
 */
export type IRISClientRequest<P extends ProgIDs, F extends FuncIDs<P>> = {
	data: { funcID: [P, F] } & ExtractIO<P, F>[0]
	yes: (data: IRISResponseYes<ExtractIO<P, F>[1]>) => void
	no: (data: IRISResponseNo) => void
	any?: (data: IRISResponse<ExtractIO<P, F>[1]>) => void
	// TODO - implement progress handler
	// prog?: ...
}

export const svrtsRequest = <P extends ProgIDs, F extends FuncIDs<P>>(
	obj: IRISClientRequest<P, F>,
) => {
	IRIS.Send({
		data: {
			...obj.data,
			progID: obj.data.funcID[0],
			funcID: obj.data.funcID[1],
		},
		yes: obj.yes,
		no: obj.no,
		any: obj.any,
		// prog: obj.prog, // TODO - implement progress events
	})
}
