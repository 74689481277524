import { AddressCard, ContactCard, zod } from '../../../../universal'
import { React } from '../../../lib'
import { AddressWidget, ContactWidget } from '../../component-address-contact'
import { FormField, FormFieldJSX } from './types'

const addressSchema = zod.object({
	ID: zod.number(),
	Address: zod.string().nullable(),
	Locale: zod.string().nullable(),
	State: zod.string().nullable(),
	PostCode: zod.number().nullable(),
	Complete: zod.boolean(),
	Note: zod.string().nullable(),
})

const base_desc_address =
	'Represent as an object with the following keys: `line1`, `locality`, `state`, `postcode`. Addresses are Australian and states use 3-character codes.'
const base_desc_contact =
	'Represent as an object with the following keys: `fax`, `phone`, `mobile`, `email`, `website`.'

/** Address card */
export const FormTypeAddress = (
	settings: FormField<AddressCard> & { isPostal: boolean },
): FormFieldJSX<Partial<AddressCard>> =>
	({
		...settings,
		valueDefaults: {
			def: () => ({
				ID: null,
				Address: null,
				Locale: null,
				State: null,
				PostCode: null,
				Complete: false,
				Note: null,
			}),
			// TODO - ensure that either it's all there or none of it is???
			validators: [],
		},
		height: 36,
		typeMap: {
			schemaRaw: addressSchema,
			schemaPublic: addressSchema,
			toPublic: x => x,
			toInternal: x => x,
		},
		llmInfo: {
			stringifiedType: 'address',
			description: `${base_desc_address} ${settings.doc ?? settings.lbl}`,
			reversal: (x, v) => ({
				ID: null,
				Address: x['line1'] ?? v.Address ?? '',
				Complete: v.Complete ?? false,
				Locale: x['locality'] ?? v.Locale ?? '',
				State: x['state'] ?? v.State ?? '',
				PostCode: x['postcode'] ?? v.PostCode ?? '',
			}),
		},
		jsx: props => (
			<AddressWidget
				value={props.value}
				onUpdate={props.onUpdate}
				cl={props.className}
				title={props.title}
				isPostal={settings.isPostal}
			/>
		),
	}) as FormFieldJSX<Partial<AddressCard>, Partial<AddressCard>>

const contactSchema = zod.object({
	ID: zod.number(),
	Email: zod.string(),
	Phone: zod.string(),
	Mobile: zod.string(),
	Fax: zod.string(),
	Website: zod.string(),
})

/** Contact card */
export const FormTypeContact = (
	settings: FormField<ContactCard>,
): FormFieldJSX<Partial<ContactCard>> =>
	({
		...settings,
		valueDefaults: {
			def: () => ({
				ID: null,
				Email: '',
				Phone: '',
				Mobile: '',
				Fax: '',
				Website: '',
			}),
			// TODO - ensure that either it's all there or none of it is???
			validators: [],
		},
		height: 36,
		typeMap: {
			schemaRaw: contactSchema,
			schemaPublic: contactSchema,
			toPublic: x => x,
			toInternal: x => x,
		},
		llmInfo: {
			stringifiedType: 'contact-card',
			description: `${base_desc_contact} ${settings.doc ?? settings.lbl}`,
			reversal: (x, v) => ({
				ID: null,
				Email: x['email'] ?? v.Email,
				Phone: x['phone'] ?? v.Phone,
				Mobile: x['mobile'] ?? v.Mobile,
				Fax: x['fax'] ?? v.Fax,
				Website: x['website'] ?? v.Website,
			}),
		},
		jsx: props => (
			<ContactWidget
				value={props.value}
				onUpdate={props.onUpdate}
				cl={props.className}
				title={props.title}
				onFocus={props.onFocus}
				onBlur={props.onBlur}
			/>
		),
	}) as FormFieldJSX<Partial<ContactCard>, Partial<ContactCard>>
