import { BuildClass, timer } from '../../../universal'
import { React } from '../../lib'
import { Modal, ModalAnchored } from './modal'
import { stubDiv } from './stubs'

export const HelpIcon = (props: {
	title: string
	className?: string
}): React.JSX.Element => (
	<HelpTooltip title={props.title} timeout={200}>
		<span
			tabIndex={-1}
			className={BuildClass({
				'ui5-help-icon': true,
				[props.className ?? '']: true,
			})}
		>
			<img alt="Help" src="/static/img/svg/small-help-icon.svg" />
		</span>
	</HelpTooltip>
)

export const HelpTooltip = (props: {
	title: string
	children: React.JSX.Element
	timeout?: number
}): React.JSX.Element => {
	// Refs
	const element = React.useRef<HTMLElement>(stubDiv)
	const refHoverTimeout = React.useRef<number>()
	const refDimensions = React.useRef({ width: 0, height: 0 })
	const refTestBox = React.useRef<HTMLDivElement>(stubDiv)

	// Track whether visible
	const [visible, setVisible] = React.useState(false)

	// Track the dimensions of the tooltip box based on the text
	// It renders off-screen and we read the box size
	React.useEffect(() => {
		const el = refTestBox.current
		if (!el) {
			return
		}
		const box = el.getBoundingClientRect()
		refDimensions.current = { width: box.width, height: box.height }
	}, [refTestBox.current, props.title])

	// Add the mouseover event
	const evMouseOver = React.useCallback(() => {
		refHoverTimeout.current = timer(props.timeout ?? 500, () => {
			setVisible(true)
		})
	}, [refHoverTimeout.current, props.timeout, setVisible])
	React.useEffect(() => {
		element.current?.addEventListener('mouseover', evMouseOver)
		return () => {
			element.current?.removeEventListener('mouseover', evMouseOver)
		}
	}, [element.current, evMouseOver])

	// Add the mouseout event
	const evMouseOut = React.useCallback(() => {
		clearTimeout(refHoverTimeout.current)
		setVisible(false)
	}, [refHoverTimeout.current, setVisible])
	React.useEffect(() => {
		element.current?.addEventListener('mouseout', evMouseOut)
		return () => {
			element.current?.removeEventListener('mouseout', evMouseOut)
		}
	}, [element.current, evMouseOut])

	// Render
	return (
		<>
			{React.cloneElement(props.children, { ref: element })}
			<ModalAnchored
				mountElement={element.current}
				hidden={!visible}
				width={refDimensions.current.width}
				maxHeight={refDimensions.current.height}
				className="ui5-help-tooltip"
				alignX="center"
				flipByDefault={true}
			>
				<div className="inner">{props.title}</div>
			</ModalAnchored>
			<Modal>
				<div className="ui5-help-tooltip" ref={refTestBox}>
					<div className="inner">{props.title}</div>
				</div>
			</Modal>
		</>
	)
}
