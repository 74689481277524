import { Maybe } from '../../../../universal'
import { React, _ } from '../../../lib'
import { Alerts } from '../../component-main'
import { svrtsRequest } from '../../svrts-interface'
import { HelpTooltip } from '../help'
import { FormManager } from './manager'
import { FormPropsFields, InferValueFromFieldsRaw, LLMInfo } from './types'

type fieldTypeInfo = {
	type: string
	description: string
	enum: Maybe<(string | number)[]>
	enumsAreComplete: Maybe<boolean>
}

export const runLLMFormFill = <F extends FormPropsFields>(input: {
	currentState: InferValueFromFieldsRaw<F>
	manager: FormManager<F>
	description: string
	request: string
	yes: (data: Record<string, unknown>) => void
}): void => {
	if (input.manager.disableFormFill) {
		console.log('Form fill is disabled')
		return
	}
	const fields = _.fromPairs(
		_.map(input.manager.fields, (field, key) => [
			key,
			{
				originalValue: input.manager.defaultState[key],
				value: input.currentState[key],
				type: getFieldType(field.llmInfo),
			},
		]),
	)
	svrtsRequest({
		data: {
			funcID: [0, 50],
			description: input.description,
			request: input.request,
			fields: fields,
		},
		no: data => {
			Alerts.Alert({ msg: data.message })
		},
		yes: data => {
			input.yes(data.values)
		},
	})
}

const getFieldType = <T, R = T>(llmInfo: Maybe<LLMInfo<T, R>>): Maybe<fieldTypeInfo> => {
	if (!llmInfo) {
		return null
	}
	return {
		type: llmInfo.stringifiedType,
		description: llmInfo.description,
		enum: undefined,
		enumsAreComplete: undefined,
		...(llmInfo.enums && {
			enum: llmInfo.enums,
			enumsAreComplete: llmInfo.enumsAreComplete,
		}),
	}
}

export const FormFillButton = <F extends FormPropsFields>(props: {
	manager: FormManager<F>
	currentState: InferValueFromFieldsRaw<F>
	setState: (newState: InferValueFromFieldsRaw<F>) => void
	/**
	 * Define the containing element so we can draw a box around it while listening
	 * This ensures that the user knows the scope of the current form
	 */
	containingElement: HTMLElement
	/** Passed to Whisper to slightly improve transcription of special non-phonetic words */
	voicePrompt?: Maybe<string>
	/** Passed to the LLM for context */
	formPrompt: Maybe<string>
}): React.JSX.Element =>
	props.manager.disableFormFill ? (
		<></>
	) : (
		<HelpTooltip title="Click to edit this form using your voice in natural language. Note you can use the right Ctrl key (after clicking somewhere in the form) to do this quickly with push-to-talk">
			<button
				type="button"
				className="opacity-50 hover:opacity-100"
				onClick={() => {
					props.manager.startTranscriptionFormFill({
						currentState: props.currentState,
						editBeforeSending: false,
						setState: props.setState,
						formPrompt: props.formPrompt,
						voicePrompt: props.voicePrompt,
						containingElement: props.containingElement,
					})
				}}
			>
				<img src="/static/img/i8/sparkles.svg" className="w-4 h-4" />
			</button>
		</HelpTooltip>
	)
