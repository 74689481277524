import { BuildClass, Do, Maybe, fsmData } from '../../../universal'
import { React, _ } from '../../lib'
import {
	PendingDelta,
	getInternalValue,
	getValuesAfterClick,
	resolvePendingDeltas,
} from './list-util'
import {
	ConditionalObject,
	RSInstance,
	RSInstanceD,
	useMemoCompare,
	useRSInstance,
	useStateSync,
} from './meta-types'
import { stubDiv, stubInput } from './stubs'

// TODO - add `contextMenuHandler` for the rows

/** Pixel buffer above/below visible to lazy render */
const SCROLL_BUFFER = 100
/** Gap between selected and viewport edge when scroll jacking */
const SCROLL_JACK_GAP = 10
/** Number of rows to move with page up/down */
const PAGE_JUMP = 12
/** Maximum possible rows */
const HOME_END_JUMP = 10e7

/**
 * A row in a `List` component
 * @param value the ID - number or string (impacts the generic `T`)
 * @param content function that returns JSX for the row DOM
 * @param className adds DOM string to row class
 * @param height override lazy rendering height
 */
export type ListRow<T extends string | number> = {
	value: T
	content: () => React.JSX.Element
	selectable?: boolean
	key?: T | string
	className?: string
	height?: number
}

/** Props for a `List` component */
type ListProps<T extends string | number, S extends boolean> = {
	value: Maybe<T> | T[]
	onUpdate: (value: S extends true ? T[] : T) => void
	height: number
	rows: ListRow<T>[]
	multiple: S
	multipleDefault?: boolean
	disableFocusControl?: boolean
	disableClickHandlers?: boolean
	className?: string
	readOnly?: boolean
	style?: React.CSSProperties
	vpHeight?: number // Override checking DOM
	onKeyDown?: (
		ev: React.KeyboardEvent<HTMLInputElement>,
		defaultEvent: (ev: React.KeyboardEvent<HTMLInputElement>) => void,
	) => void
	disableLazyRendering?: boolean
}

/** Maps a row's value to some calculated heights and positions */
type ChildPosHeights<T extends string | number> = Map<
	T,
	{
		top: number
		height: number
		bot: number
		item: ListRow<T>
	}
>

/** The internal state of the combobox component*/
type ListState<T extends string | number> = {
	// Value
	value: T[]
	scrollOffset: number
	vpHeight: number
	mouseDown: boolean
}
type ListRefs<T extends string | number> = {
	hasMounted: React.MutableRefObject<boolean>
	hiddenInput: React.MutableRefObject<HTMLInputElement>
	wrappingElement: React.MutableRefObject<HTMLDivElement>
	pendingDeltas: React.MutableRefObject<PendingDelta[]>
	childPosHeights: React.MutableRefObject<ChildPosHeights<T>>
	dispatchRef: React.MutableRefObject<(value: Payload<T>) => void>
	handledEvents: React.MutableRefObject<Set<number>>
}

type ReducerStateD<T extends string | number, S extends boolean> = RSInstanceD<
	ListProps<T, S>,
	ListState<T>,
	ListRefs<T>,
	Payload<T>
>
type ReducerState<T extends string | number, S extends boolean> = RSInstance<
	ListProps<T, S>,
	ListState<T>,
	ListRefs<T>
>

enum Action {
	NoOp,
	UpdateSelectedValues,
	OnKeyPress,
	OnMouseDown,
	OnClick,
	OnScroll,
	UpdateViewportHeight,
}

type Payload<T extends string | number> =
	| [Action.NoOp]
	| [Action.UpdateSelectedValues, { value: T[] }]
	| [Action.OnKeyPress, { ts: number; evWhich: number; evShiftKey: boolean }]
	| [Action.OnMouseDown, { ev: React.MouseEvent<HTMLDivElement> }]
	| [Action.OnClick, { ev: React.MouseEvent<HTMLDivElement> }]
	| [Action.OnScroll, { ev: React.UIEvent<HTMLDivElement> }]
	| [Action.UpdateViewportHeight, { height: number }]

const getPartialStateFromAction = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	p: Payload<T>,
): Partial<ListState<T>> => {
	switch (p[0]) {
		case Action.NoOp:
			return {}
		case Action.UpdateSelectedValues:
			return setSelected(rs, p[1].value)
		case Action.OnKeyPress:
			return onKeyDownList(rs, p[1].ts, p[1].evWhich, p[1].evShiftKey)
		case Action.OnMouseDown:
			return onMouseDownList(rs, p[1].ev)
		case Action.OnClick:
			return onMouseClickList(rs, p[1].ev)
		case Action.OnScroll:
			const div = rs.refs.wrappingElement.current
			return { scrollOffset: div.scrollTop }
		case Action.UpdateViewportHeight:
			return { vpHeight: p[1].height }
	}
}

export type ListInstance<T extends string | number, S extends boolean> = {
	focus: () => void
	blur: () => void
	selectAll: () => void
	selectNone: () => void
	setSelected: (selected: T[]) => void
	moveSelection: (delta: number, shiftKey: boolean) => void
	updateScrollPosition: (newSelected: T[]) => void
	refreshScrollJack: () => void
	rs: Maybe<ReducerStateD<T, S>>
	renderedRows: ListRow<T>[]
	getKeyDownEventDelta: (ts: number, evWhich: number, evShiftKey: boolean) => T[]
	forwardEventScroll: (ev: React.UIEvent<HTMLDivElement>) => void
	forwardEventMouseDown: (ev: React.MouseEvent<HTMLDivElement>) => void
	forwardEventMouseClick: (ev: React.MouseEvent<HTMLDivElement>) => void
	forwardEventKeyDown: (ts: number, evWhich: number, evShiftKey: boolean) => void
	noop: () => void
	getElement: () => HTMLDivElement
	getRowValues: () => T[]
}

/** Inner instance of `List` component, without ref forwarding */
const ListComponent = <T extends string | number, S extends boolean>(
	props: ListProps<T, S>,
	ref: React.ForwardedRef<ListInstance<T, S>>,
) => {
	// Reducer
	const rs = useRSInstance<ListProps<T, S>, ListState<T>, ListRefs<T>, Payload<T>>({
		props: props,
		defaultState: React.useCallback(
			props => ({
				value: getInternalValue(props.value),
				scrollOffset: 0,
				vpHeight: props.vpHeight,
				mouseDown: false,
			}),
			[],
		),
		// Refs
		refs: {
			hasMounted: React.useRef<boolean>(false),
			hiddenInput: React.useRef<HTMLInputElement>(stubInput),
			wrappingElement: React.useRef<HTMLDivElement>(stubDiv),
			pendingDeltas: React.useRef<PendingDelta[]>([]),
			childPosHeights: React.useRef<ChildPosHeights<T>>(new Map()),
			handledEvents: React.useRef<Set<number>>(new Set()),
			// Dispatch reference - updated each time hook is called
			// This is a bit of a hack to make it available on the `rs` object
			dispatchRef: React.useRef<(value: Payload<T>) => void>(null),
		},
		actionToDelta: getPartialStateFromAction,
	})
	rs.refs.dispatchRef.current = rs.dispatch

	// Sync the value state and props
	useStateSync({
		stateVal: rs.state.value,
		propVal: props.value,
		setState: propVal => {
			rs.dispatch([
				Action.UpdateSelectedValues,
				{ value: getInternalValue(propVal) },
			])
		},
		setProp: stateVal => {
			const val = props.multiple ? stateVal : _.first(stateVal)
			props.onUpdate(val as S extends true ? T[] : T)
		},
		compareFn: (propVal, stateVal) => {
			const sVal = props.multiple ? stateVal : _.first(stateVal)
			return _.isEqual(propVal, sVal)
		},
	})

	// Calculate the top and bottom pixel position of every row
	// Only needs to update when the input row props change
	rs.refs.childPosHeights.current = useMemoCompare(
		() => getChildPosHeights(props.rows ?? [], props.height),
		{ height: props.height, children: props.rows },
	)

	// Create a resize observer for the wrapped input each time the input element changes
	React.useEffect(() => {
		if (props.vpHeight == null) {
			const observer = new ResizeObserver(() => {
				if (rs.refs.hasMounted.current) {
					rs.dispatch([
						Action.UpdateViewportHeight,
						{ height: rs.refs.wrappingElement.current?.clientHeight ?? 0 },
					])
				}
			})
			observer.observe(rs.refs.wrappingElement.current)
			return () => {
				observer.disconnect()
			}
		}
		return _.noop
	}, [rs.refs.wrappingElement.current, rs.refs.hasMounted.current, props.vpHeight])

	// Track mount/unmount state for async handlers
	React.useEffect(() => {
		rs.refs.hasMounted.current = true
		rs.dispatch([Action.NoOp])
		return () => {
			rs.refs.hasMounted.current = false
		}
	}, [])

	// Cache the event dispatch functions
	const evScroll = React.useCallback((ev: React.UIEvent<HTMLDivElement>) => {
		rs.dispatch([Action.OnScroll, { ev }])
	}, [])
	const evMouseDown = React.useCallback((ev: React.MouseEvent<HTMLDivElement>) => {
		rs.dispatch([Action.OnMouseDown, { ev }])
	}, [])
	const evMouseClick = React.useCallback((ev: React.MouseEvent<HTMLDivElement>) => {
		rs.dispatch([Action.OnClick, { ev }])
	}, [])
	const evKeyDown = React.useCallback((ev: React.KeyboardEvent<HTMLInputElement>) => {
		ev.stopPropagation()
		ev.preventDefault()
		rs.dispatch([
			Action.OnKeyPress,
			{ ts: ev.timeStamp, evWhich: ev.which, evShiftKey: ev.shiftKey },
		])
	}, [])
	const evKeyDownWrapped = React.useCallback(
		(ev: React.KeyboardEvent<HTMLInputElement>) => {
			if (props.onKeyDown != null) {
				props.onKeyDown(ev, evKeyDown)
			} else {
				evKeyDown(ev)
			}
		},
		[props.onKeyDown],
	)

	// Get the rendered rows and size of the above/below space for lazy rendering
	// This can be cached based on a few factors, but likely will re-run each run
	const { spacerTop, rows, spacerBottom } = useMemoCompare(
		() =>
			getRenderedRowsAndSpacerSize(
				rs.props.rows,
				rs.state.scrollOffset,
				rs.state.vpHeight,
				rs.refs.childPosHeights.current,
				rs.props.disableLazyRendering ?? false,
			),
		[
			rs.props.rows,
			rs.state.scrollOffset,
			rs.state.vpHeight,
			rs.refs.childPosHeights.current,
		],
	)

	// Define external methods and link references
	React.useImperativeHandle(ref, () => ({
		focus: () => {
			rs.refs.hiddenInput.current?.focus()
		},
		blur: () => {
			rs.refs.hiddenInput.current?.blur()
		},
		selectAll: () => {
			rs.dispatch([
				Action.UpdateSelectedValues,
				{ value: getSelectableValues(rs.props.rows) },
			])
		},
		selectNone: () => {
			rs.dispatch([Action.UpdateSelectedValues, { value: [] }])
		},
		setSelected: (selected: T[]) => {
			rs.dispatch([Action.UpdateSelectedValues, { value: selected }])
		},
		moveSelection: (delta: number, shiftKey: boolean = false) => {
			moveSelectionDelta(rs, delta, shiftKey)
		},
		updateScrollPosition: (newSelected: T[]) => {
			updateScrollPosition(rs, newSelected)
		},
		refreshScrollJack: () => {
			updateScrollPosition(rs, rs.state.value)
		},
		rs: rs,
		renderedRows: rows,
		// TODO - fix the return type here - needs to batch
		getKeyDownEventDelta: (ts: number, evWhich: number, evShiftKey: boolean) =>
			onKeyDownList(rs, ts, evWhich, evShiftKey).value,
		forwardEventScroll: evScroll,
		forwardEventMouseDown: evMouseDown,
		forwardEventMouseClick: evMouseClick,
		forwardEventKeyDown: (ts: number, evWhich: number, evShiftKey: boolean) => {
			rs.dispatch([Action.OnKeyPress, { ts, evWhich, evShiftKey }])
		},
		noop: () => {
			rs.dispatch([Action.NoOp])
		},
		getElement: () => rs.refs.wrappingElement.current,
		getRowValues: () => rs.props.rows.map(x => x.value),
	}))

	// Render
	return (
		<div
			ref={rs.refs.wrappingElement}
			className={BuildClass({
				'ui5 ui5-list': true,
				[props.className]: true,
			})}
			onScroll={evScroll}
			style={props.style}
		>
			<div className="hidden-input">
				{ConditionalObject(!(props.disableFocusControl ?? false), () => (
					<input
						readOnly={true}
						ref={rs.refs.hiddenInput}
						onKeyDown={evKeyDownWrapped}
					/>
				))}
			</div>
			<div className="spacer-top" style={{ height: spacerTop }} />
			{rows.map(x => (
				<ListRow
					key={x.key ?? x.value}
					value={x.value}
					selected={rs.state.value.includes(x.value)}
					selectable={x.selectable ?? true}
					className={x.className}
					content={x.content}
					evMouseDown={evMouseDown}
					evMouseClick={evMouseClick}
				/>
			))}
			<div className="spacer-bottom" style={{ height: spacerBottom }} />
		</div>
	)
}

/**
 * Row rendering component. Memo-ised to allow expensive calls in `content`
 * @param value ID - string or number
 * @param selected Whether it's currently selected in the parent list
 * @param selectable Whether it's able to be selected
 * @param className DOM string
 * @param content Function to return JSX for DOM
 * @param evMouseDown Function to run when row is clicked
 * @param evMouseClick Function to run when row click is released
 */
const ListRow = <T extends string | number>(props: {
	value: T
	selected: boolean
	selectable: boolean
	className: string
	content: () => React.JSX.Element
	evMouseDown: (ev: React.MouseEvent<HTMLDivElement>) => void
	evMouseClick: (ev: React.MouseEvent<HTMLDivElement>) => void
}) => {
	const content = props.content()
	return (
		<div
			className={BuildClass({
				'ui5-row': true,
				[props.className]: true,
				selected: props.selected,
				'non-selectable': !props.selectable,
			})}
			row-value={props.value}
			onMouseDown={props.evMouseDown}
			onMouseUp={props.evMouseClick}
		>
			{content}
		</div>
	)
}

/**
 * Calculate the top and bottom pixel position of every row
 * Only needs to update when the input row props change
 */
const getChildPosHeights = <T extends string | number>(
	children: ListRow<T>[],
	overrideHeight: number,
) => {
	let cHeight = 0
	const posHeight: ChildPosHeights<T> = new Map()
	_.forEach(children, item => {
		const top = cHeight
		const height = item.height ?? overrideHeight
		const bot = top + height
		cHeight += height
		posHeight.set(item.value, { top, height, bot, item })
	})
	return posHeight
}

/** Helper function to scroll-jack to show the last-selected value in scroll */
const updateScrollPosition = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	newSelected: T[],
) => {
	const row = rs.refs.childPosHeights.current.get(newSelected[newSelected.length - 1])
	if (!row) {
		return // Nothing is selected - nowhere to scroll
	}
	let newScrollPos = null
	if (row.top < rs.state.scrollOffset) {
		newScrollPos = row.top - SCROLL_JACK_GAP
	} else if (row.bot > rs.state.scrollOffset + rs.state.vpHeight) {
		newScrollPos = row.bot + SCROLL_JACK_GAP - rs.state.vpHeight
	}
	if (newScrollPos) {
		rs.refs.wrappingElement.current.scrollTo({
			top: newScrollPos,
		})
	}
}

/**
 * Updates the selection of a list component
 * Out here to ensure no closure caching bugs - lots of input params
 */
const setSelected = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	value: T[],
): Partial<ListState<T>> => {
	// If read only, it's always null
	if (rs.props.readOnly) {
		return {}
	}

	// Update scroll position and return the state update
	updateScrollPosition(rs, value)
	return { value }
}

/** Click event inner logic for list component */
const onMouseDownList = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	ev: React.MouseEvent<HTMLDivElement>,
): Partial<ListState<T>> => {
	// Exit early if clicks are disabled
	if (rs.props.disableClickHandlers || rs.props.readOnly) {
		return {}
	}

	// Focus the hidden input for key navigation
	rs.refs.hiddenInput.current?.focus({ preventScroll: true })

	// Exit early if not the left mouse button
	if (ev.button != 0) {
		return {}
	}

	// Halt event propagation
	ev.preventDefault()
	ev.stopPropagation()

	// Get value clicked
	const val = Do(() => {
		const div = (ev.target as Element).closest('.ui5-row') as HTMLDivElement
		const valStr = div.attributes['row-value'].value as string
		if (_.isNumber(rs.props.rows[0].value)) {
			return +valStr as T
		}
		return valStr as T
	})

	// Exit early if this value is not selectable
	if (!getSelectableValues(rs.props.rows).includes(val)) {
		return {}
	}

	// Override the ctrl/shift key behaviour as required
	// 1: none, 2: ctrl, 3: shift
	let modifier: 1 | 2 | 3
	if (!rs.props.multiple) {
		modifier = 1
	} else if (ev.shiftKey) {
		modifier = 3
	} else if (ev.ctrlKey || rs.props.multipleDefault) {
		modifier = 2
	} else {
		modifier = 1
	}

	// Get the new selected value from utility function
	return {
		value: getValuesAfterClick(
			getSelectableValues(rs.props.rows),
			rs.state.value,
			val,
			modifier,
		),
	}
}

const onMouseClickList = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	ev: React.MouseEvent<HTMLDivElement>,
): Partial<ListState<T>> => {
	if (rs.props.disableClickHandlers || rs.props.readOnly) {
		return {} // Exit early if clicks are disabled
	}
	rs.refs.hiddenInput.current?.focus({ preventScroll: true })
	ev.preventDefault()
	ev.stopPropagation()
	return {}
}

/** Keydown event inner logic for list component */
const onKeyDownList = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	ts: number,
	evWhich: number,
	evShiftKey: boolean,
): Partial<ListState<T>> => {
	// Exit if this event has already been handled
	if (rs.refs.handledEvents.current.has(ts)) {
		return {}
	}
	rs.refs.handledEvents.current.add(ts)

	// Handle the event
	const move = (d: number) => {
		moveSelectionDelta(rs, d, evShiftKey)
	}
	switch (evWhich) {
		case 38:
			move(-1)
			break
		case 40:
			move(+1)
			break
		case 33:
			move(-PAGE_JUMP)
			break
		case 34:
			move(+PAGE_JUMP)
			break
		case 36:
			move(-HOME_END_JUMP)
			break
		case 35:
			move(+HOME_END_JUMP)
			break
	}
	return {}
}

/** Adds a move delta to the pending deltas object */
const moveSelectionDelta = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
	delta: number,
	shiftKey: boolean,
) => {
	rs.refs.pendingDeltas.current.push({
		delta: delta,
		shift: shiftKey,
	})
	requestAnimationFrame(() => {
		resolveDeltas(rs)
	})
}

/**
 * Resolves sequential move and select keyboard event actions
 * Batches and runs them together all at once, then clears the queue
 */
const resolveDeltas = <T extends string | number, S extends boolean>(
	rs: ReducerState<T, S>,
) => {
	// Exit early if not mounted
	if (!rs.refs.hasMounted.current) {
		return
	}

	// Get the newly-selected itemsafter resolving the pending deltas
	const selected_items = resolvePendingDeltas(
		rs.refs.pendingDeltas.current,
		rs.state.value,
		getSelectableValues(rs.props.rows),
		rs.props.multiple,
	)

	// Update the value and reset the pending deltas
	if (selected_items) {
		rs.refs.dispatchRef.current([
			Action.UpdateSelectedValues,
			{ value: selected_items },
		])
	}
	rs.refs.pendingDeltas.current = []
}

/**
 * Get the rendered rows and size of the above/below space for lazy rendering
 *This can be cached based on a few factors, but likely will re-run each run
 */
const getRenderedRowsAndSpacerSize = <T extends string | number>(
	children: ListRow<T>[],
	scrollOffset: number,
	vpHeight: number,
	childPosHeights: ChildPosHeights<T>,
	disableLazyRendering: boolean,
): {
	spacerTop: number
	spacerBottom: number
	rows: ListRow<T>[]
} => {
	// If we're disabling the lazy rendering, pass back
	if (disableLazyRendering) {
		return {
			spacerTop: 0,
			rows: children,
			spacerBottom: 0,
		}
	}

	// Selected rows
	const rows: ListRow<T>[] = []

	// Track the starting index
	let rowIndexStart = -1

	// Cut-off points
	const cutoff_top = scrollOffset - SCROLL_BUFFER
	const cutoff_bot = scrollOffset + vpHeight + SCROLL_BUFFER

	// Locals to track
	let spacerTop = 0
	let spacerBottom = 0
	let doingSpacerTop = true
	let doingSpacerBottom = false

	// If no posHeight object available, just do nothing
	if (!childPosHeights || !vpHeight) {
		return {
			spacerTop: _.sum(children.map(x => x.height)),
			rows: [],
			spacerBottom: 0,
		}
	}

	// Loop over each row to see where it fits
	_.forEach(children, (row, index) => {
		// Current row pos/height
		const ph = childPosHeights.get(row.value)

		// Check if breaking from top spacer to rows
		if (doingSpacerTop && ph.bot > cutoff_top) {
			doingSpacerTop = false
		}

		// Check if breaking from rows to bottom spacer
		if (!doingSpacerTop && !doingSpacerBottom && ph.top > cutoff_bot) {
			doingSpacerBottom = true
		}

		// Add to relevant section
		if (doingSpacerTop) {
			spacerTop += ph.height
		} else if (doingSpacerBottom) {
			spacerBottom += ph.height
		} else {
			rows.push(row)
			if (rowIndexStart == -1) {
				rowIndexStart = index
			}
		}
	})

	// If the number of rows hidden is odd, show one extra row above the viewport
	// This ensures any odd/even highlights will remain consistent and odd/even rows
	// always stay odd or even regardless of scrolling
	if (rowIndexStart >= 1 && rowIndexStart % 2 == 1) {
		const insertRow = children[rowIndexStart - 1]
		const ph = childPosHeights.get(insertRow.value)
		spacerTop -= ph.height
		rows.unshift(insertRow)
	}

	// Return the results
	return { spacerTop, rows, spacerBottom }
}

const getSelectableValues = <T extends string | number>(rows: ListRow<T>[]) =>
	fsmData(rows, {
		filter: x => x.selectable ?? true,
		map: x => x.value,
	})

/**
 * Generic list component
 * @param value The currently-selected value(s) in the list
 * @param onUpdate Update function when the selected value(s) change
 * @param height The fallback height of each row (can be overridden per row)
 * @param children The list of child rows, consisting of a value, content function, and height/class
 * @param multiple Whether multiple children can be selected
 * @param multipleDefault Clicking a row uses `ctrl` modifier behaviour by default (default: false)
 * @param disableFocusControl Disables the hidden input for focus/input control (default: false)
 * @param disableClickHandlers Disables putting click events on the rows (default: false)
 * @param className Extra DOM class name string (default: '')
 * @param readOnly Selected value is immutable (default: false)
 * @param style Adds inline CSS
 * @param vpHeight Override set viewport height instead of inspecting DOM (default: null)
 */
export const List = React.forwardRef(ListComponent)
